import React from 'react';

const PlusIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
      <path
        d="M9.75 15.4385H0V10.5635H9.75V0.813477H14.675V10.5635H24.425V15.4385H14.675V25.1885H9.75V15.4385Z"
        fill="#097D75"
      />
    </svg>
  );
};

export default PlusIcon;
