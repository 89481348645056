import * as actionTypes from './actionTypes';

let initialState = {
  passportData: {},
  getCompanyIncorporation: {},
  companyIncorporation: {},
  switchCompanyResponse: {},
  deleteStakholderData: {}
};

const companyIncorporationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPANY_INCORPORATION_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_COMPANY_INCORPORATION_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_COMPANY_INCORPORATION_REQUEST_SUCCESS:
      return {
        ...state,
        getCompanyIncorporation: { ...action.getCompanyIncorporationData },
        loading: false,
        error: false
      };
    case actionTypes.COMPANY_INCORPORATION_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.COMPANY_INCORPORATION_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.COMPANY_INCORPORATION_REQUEST_SUCCESS:
      return {
        ...state,
        companyIncorporation: { ...action.companyIncorporationData },
        loading: false,
        error: false
      };
    case actionTypes.UPLOAD_PASSPORT_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.UPLOAD_PASSPORT_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.UPLOAD_PASSPORT_REQUEST_SUCCESS:
      return {
        ...state,
        passportData: { ...action.uploadedPassportData },
        loading: false,
        error: false
      };
    case actionTypes.SWITCH_COMPANY_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.SWITCH_COMPANY_REQUEST_SUCCESS:
      return {
        ...state,
        switchCompanyResponse: action.switchCompanyPayload,
        loading: false,
        error: false
      };
    case actionTypes.SWITCH_COMPANY_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.DELETE_STAKEHOLDER_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.DELETE_STAKEHOLDER_REQUEST_SUCCESS:
      return {
        ...state,
        deleteStakholderData: action.deleteStakholderData,
        loading: false,
        error: false
      };
    case actionTypes.DELETE_STAKEHOLDER_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
};

export default companyIncorporationReducer;
