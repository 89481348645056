import React, { useState } from 'react';
import XSText from '../../ui/typography/XSText';
import WarningIcon from '../../../images/icons/information-cirlce-streamline.svg';
import DynamicModal from '../../ui/modal/DynamicModal';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
const categories = [
  'Firearms and Ammunition Sales',
  'Adult Entertainment and Adult Content Production',
  'Pharmaceuticals and Medicine Manufacturing',
  'Cryptocurrency Trading and Exchanges',
  'Gambling and Online Betting',
  'Tobacco and Vape Product Manufacturing',
  'Cannabis Cultivation and Distribution',
  'Biotechnology Research (depending on specific activities)',
  'Nuclear Energy Production and Materials Handling',
  'Arms and Defense Contracting',
  'Chemical Manufacturing (for hazardous materials)',
  'Environmental Hazardous Waste Disposal',
  'Private Investigation and Surveillance Services',
  'Organ and Human Tissue Trade',
  'Oil and Gas Exploration (due to environmental and safety concerns)',
  'Medical Research Involving Human Cloning'
];
export default function IndustryWarningBox() {
  const [openHighRiskModal, setOpenHighRiskModal] = useState(false);
  return (
    <div className="bg-yellow-300 rounded-lg px-6 py-4 mt-8 flex items-start gap-4">
      <img src={WarningIcon} className="w-6" alt="warning-icon" />
      <XSText
        title={
          <span>
            There are a few businesses for which we don’t incorporate a company (for instance, gambling, firearms,
            etc…).
            <br />
            See the{' '}
            <span onClick={() => setOpenHighRiskModal(true)} className="text-bold cursor-pointer">
              full list here.
            </span>
          </span>
        }
      />
      <DynamicModal
        openDynamicModal={openHighRiskModal}
        closeDynamicModal={() => setOpenHighRiskModal(false)}
        minWidth="576px"
      >
        <MDSubtitleText className="-mt-6 mb-2" fontWeight="text-bold" title="List of high-risk activities" />
        {categories.map((item, index) => (
          <ol key={item}>
            <li className="py-1">
              <XSText title={`${index + 1}. ${item}`} />
            </li>
          </ol>
        ))}
      </DynamicModal>
    </div>
  );
}
