import React from 'react';
import BaseText from '../ui/typography/BaseText';
import CheckInCircle from '../../images/icons/customSvg/checkByStreamLineHq';
import { MUI_COLORS } from '../../constants/enum';

export default function Points({ title, description }) {
  return (
    <div className="flex flex-row gap-4 items-center">
      <div className="w-8 h-8 bg-green-200 rounded-full flex justify-center items-center">
        <CheckInCircle fill={MUI_COLORS.GREEN} />
      </div>
      <div className="flex flex-col">
        <BaseText title={title} fontWeight="text-bold" lineHeight="leading-[24.229px]" />
        <BaseText title={description} lineHeight="leading-[24.229px]" />
      </div>
    </div>
  );
}
