import React, { useState, useEffect } from 'react';
import {
  HONG_KONG_COMPANY_CREATION_FEES_DROPDOWN,
  HONG_HONG_COMPANY_CREATION_ORIGINAL_PRICING
} from '../../constants/enum';
import DropDown from '../common/DropDown';
import XSText from '../ui/typography/XSText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { ENDPOINTS } from '../../service/end-points';
import PropTypes from 'prop-types';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { PATH_PAGE } from '../../routes/paths';

const Prices = ({ buttonRequired, DiscountImage }) => {
  const { width } = useWindowDimensions();
  const currencyOfCompanyCreation = HONG_KONG_COMPANY_CREATION_FEES_DROPDOWN;
  const [currencyOptionsToShow, setCurrencyOptionsToShow] = useState([]);
  const [isPricingDropDownOpen, setIsPricingDropDownOpen] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(currencyOfCompanyCreation?.[0]);
  function removeSelectedCurrencyFromOptions() {
    const otherTwoCurrencies = currencyOfCompanyCreation.slice(1, 3);
    setCurrencyOptionsToShow(otherTwoCurrencies);
  }
  const checkDropdown = () => {
    setIsPricingDropDownOpen(!isPricingDropDownOpen);
  };

  const selectOption = (optionValues) => {
    setSelectedPrice(optionValues);
    replaceCurrency(optionValues);
  };

  const replaceCurrency = (optionValues) => {
    const selectedCurrency = currencyOptionsToShow.indexOf(optionValues);
    currencyOptionsToShow.splice(selectedCurrency, 1);
    setCurrencyOptionsToShow([...currencyOptionsToShow, selectedPrice]);
  };

  function equiv() {
    return currencyOptionsToShow?.[0]?.price
      ? currencyOptionsToShow?.[0]?.title + currencyOptionsToShow?.[0]?.price
      : currencyOfCompanyCreation[1]?.title + currencyOfCompanyCreation[1]?.price;
  }

  useEffect(() => {
    removeSelectedCurrencyFromOptions();
  }, []);

  return (
    <>
      <div className="flex flex-row items-center md:gap-6 gap-2">
        {process.env.COMPANY_CREATION_DISCOUNT && (
          <img src={DiscountImage} alt="discount image" width={width < 767 ? 95 : 135} height="97" loading="lazy" />
        )}
        <div className="flex flex-col items-center">
          <div className="diagonal-border relative z-10">
            <div className="flex justify-center z-1">
              <p className="text-bold text-gray-450 tracking-tighter">
                {
                  HONG_HONG_COMPANY_CREATION_ORIGINAL_PRICING?.find(
                    (pricing) => pricing?.title === selectedPrice?.title
                  )?.title
                }
              </p>
              <p className="md:text-h3 text-[23px] text-bold text-gray-450 leading-8 tracking-tighter">
                {
                  HONG_HONG_COMPANY_CREATION_ORIGINAL_PRICING?.find(
                    (pricing) => pricing?.title === selectedPrice?.title
                  )?.price
                }
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start">
            <div className="flex gap-2">
              <div className="md:w-[224px] w-[160px] flex flex-row">
                <p className="md:text-[29.86px] text-[28px] leading-[42.58px] tracking-[-1.52px] price-font text-green-500">
                  {selectedPrice?.title}
                </p>
                <p className="md:text-[66.36px] text-[45px] md:leading-[73px] leading-[45px] tracking-[-1.52px] price-font text-green-500">
                  {selectedPrice?.price}
                </p>
              </div>
              <div
                className="arrow-down flex self-center cursor-pointer"
                onMouseEnter={checkDropdown}
                onMouseLeave={checkDropdown}
              >
                {isPricingDropDownOpen && <DropDown options={currencyOptionsToShow} selectOption={selectOption} />}
              </div>
            </div>
            <XSText title={`(equiv. of ${equiv()})`} className="ml-3" fontWeight="text-medium" />
          </div>
        </div>
      </div>
      {buttonRequired && (
        <PrimaryButton
          caption="Get Started"
          urlLink={ENDPOINTS?.HONG_KONG_COMPANY_REGISTRATION}
          className="md:w-96 w-full md:mt-0 mt-4"
          bgColor="bg-green-500"
          isBorderRequired={true}
          path={PATH_PAGE.hkIncorporation}
        />
      )}
    </>
  );
};

export default Prices;
Prices.propTypes = {
  buttonRequired: PropTypes.bool
};

Prices.defaultProps = {
  buttonRequired: true
};
