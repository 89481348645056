import React from 'react';
import DynamicModal from '../ui/modal/DynamicModal';
import PropTypes from 'prop-types';
import H3HeaderText from '../ui/typography/H3HeaderText';
import BaseText from '../ui/typography/BaseText';
import XSText from '../ui/typography/XSText';
import Line from './Line';
import { StaticImage } from 'gatsby-plugin-image';

const refundPolicy = [
  {
    title: '',
    description:
      "We are committed to the quality of our services and your satisfaction. If you're not happy with our services for any reason, you can get a refund."
  },
  {
    title: 'How to Get a Refund?',
    description: 'Cancel your order and ask for a refund within 30 days of your payment to Statrys.'
  },
  {
    title: '',
    description:
      "To request a refund, simply email us at incorporation@statrys.com. We'll process your refund quickly, usually within five working days."
  },
  {
    title: '',
    description: 'Refunds are made using the same payment method you used originally.'
  },
  {
    title: "What's Included in the Refund?",
    description:
      'The refund covers the amount you paid to Statrys. It does not include government or third-party processing fees.'
  }
];

function MoneyBackGuarantee({ openMoneyBackModal, closeMoneyBackModal }) {
  return (
    <div>
      <DynamicModal
        openDynamicModal={openMoneyBackModal}
        closeDynamicModal={closeMoneyBackModal}
        minWidth="576px"
        title={
          <H3HeaderText
            title="30 Days Money Back Guarantee"
            className="mt-2"
            letterSpacing="-1px"
            textColor="text-gray-500"
            fontSize="text-xl"
            fontWeight="text-bold"
          />
        }
      >
        <div className="flex justify-center items-center">
          <StaticImage
            src="../../images/graphics/registerCompany/registration-logo.png"
            alt="Money back logo"
            loading="lazy"
            className="w-36"
          />
        </div>
        {refundPolicy.map((policy) => (
          <div className="mt-4 mb-2 whitespace-pre-line" key={policy.title}>
            <BaseText textColor="text-gray-500" fontSize="text-lg" fontWeight="text-bold" title={policy.title} />
            <XSText className="mt-2" textColor="text-gray-500" title={policy.description} />
          </div>
        ))}
        <Line color="bg-gray-400 mt-5 mb-6" />
        <XSText
          title={
            <div
              dangerouslySetInnerHTML={{
                __html: `<span class='text-bold'>Your 100% satisfaction is our goal.</span> If you have questions or need help, our customer experience team is here for you.`
              }}
            ></div>
          }
        />
      </DynamicModal>
    </div>
  );
}
export default MoneyBackGuarantee;

MoneyBackGuarantee.propTypes = {
  openMoneyBackModal: PropTypes.bool,
  closeMoneyBackModal: PropTypes.any
};
