import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import { COMPANY_SETUP_SIDEBAR_SUB_STEP, MOBILE_DEVICE, MUI_COLORS } from '../../../constants/enum';
import MSText from '../../ui/typography/MSText';
import LeftArrow from '../../../images/icons/arrow-left.svg';
import LGSubtitleText from '../../ui/typography/LGSubtitleText';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const companySteps = [
  {
    id: COMPANY_SETUP_SIDEBAR_SUB_STEP?.FIRST,
    name: 'Company Name'
  },
  {
    id: COMPANY_SETUP_SIDEBAR_SUB_STEP?.SECOND,
    name: 'Industry'
  },
  {
    id: COMPANY_SETUP_SIDEBAR_SUB_STEP?.THIRD,
    name: 'Business description'
  }
];

const ownersSteps = [
  {
    id: COMPANY_SETUP_SIDEBAR_SUB_STEP?.FIRST,
    name: 'Yourself'
  },
  {
    id: COMPANY_SETUP_SIDEBAR_SUB_STEP?.SECOND,
    name: 'Others'
  },
  {
    id: COMPANY_SETUP_SIDEBAR_SUB_STEP?.THIRD,
    name: 'Capital of the company'
  }
];

export default function VerticalLinearStepper({ activeStepNumber, subStep }) {
  const { width } = useWindowDimensions();
  const orientations = {
    vertical: 'vertical',
    horizontal: 'horizontal'
  };
  const orientation = width < MOBILE_DEVICE?.MOBILE_WIDTH ? orientations?.horizontal : orientations?.vertical;
  const [activeStep, setActiveStep] = useState(activeStepNumber ? activeStepNumber : 0);
  const steps = [
    {
      label: 'Select your account',
      description: ``
    },
    {
      label: 'Company information',
      description: (
        <div className="flex flex-col gap-2">
          {companySteps?.map((steps, index) => {
            return (
              <div className="flex flex-row" key={index}>
                {steps?.id === subStep ? <img src={LeftArrow} alt="left arrow" /> : null}{' '}
                <MSText
                  fontWeight={steps?.id === subStep && 'text-bold'}
                  className={steps?.id !== subStep ? 'ml-8' : 'ml-4'}
                  title={steps.name}
                />
              </div>
            );
          })}
        </div>
      )
    },
    {
      label: 'Owners and directors',
      description: (
        <div className="flex flex-col gap-2">
          {ownersSteps?.map((steps, index) => {
            return (
              <div className="flex flex-row" key={index}>
                {steps?.id === subStep ? <img src={LeftArrow} alt="left arrow" /> : null}{' '}
                <MSText
                  fontWeight={steps?.id === subStep && 'text-bold'}
                  className={steps?.id !== subStep ? 'ml-8' : 'ml-4'}
                  title={steps.name}
                />
              </div>
            );
          })}
        </div>
      )
    },
    {
      label: 'Confirm and sign',
      description: ``
    }
  ];

  useEffect(() => {
    setActiveStep(activeStepNumber);
  }, [activeStepNumber]);

  return (
    <div className="flex flex-col md:gap-8 gap-2 md:p-8 p-4 md:border-r border-b md:border-gray-400 border-customBg-mainBg md:w-72 w-full">
      <LGSubtitleText
        className="md:whitespace-pre-line whitespace-normal"
        textWeight="text-medium"
        title={`Company \nIncorporation`}
      />
      <Box
        sx={{
          maxWidth: '400px'
        }}
      >
        <Stepper
          activeStep={activeStep}
          orientation={orientation}
          sx={{
            '& .MuiSvgIcon-root': {
              marginTop: '3px',
              marginLeft: '12%',
              width: '26px',
              height: '26px',
              color: MUI_COLORS?.LIGHT_GREEN,
              '&.Mui-active': {
                color: `${MUI_COLORS?.GREEN} !important`
              }
            },

            '& .MuiStepContent-root': {
              borderLeftWidth: '3px',
              borderColor: MUI_COLORS?.LIGHT_GREEN,
              marginLeft: orientation === orientations?.horizontal ? '0px' : '14px'
            },
            '& .MuiStepLabel-iconContainer': {
              height: '32px',
              background: MUI_COLORS?.LIGHT_GREEN,
              borderRadius: '50%',
              width: '32px'
            },
            '& .MuiStepConnector-root': {
              marginLeft: orientation === orientations?.horizontal ? '0px' : '14px',
              marginRight: '8px'
            },
            '& .MuiStepLabel-labelContainer': {
              marginLeft: '7px'
            },
            '& .MuiStepLabel-label': {
              color: MUI_COLORS?.DARK_CHARCOAL,
              fontFamily: 'HKGrotesk-Regular',
              fontSize: '14px',
              display: orientation === orientations?.horizontal ? 'none' : 'block',
              '&.Mui-active': {
                fontWeight: 700,
                display: 'block',
                lineHeight: '100%'
              }
            },
            '& .MuiStepLabel-active': {
              fontFamily: 'HKGrotesk-Bold'
            },
            '& .MuiStepConnector-line': {
              borderLeftWidth: orientation === orientations?.horizontal ? '15px' : '3px',
              minHeight: orientation === orientations?.horizontal ? '3px' : '17px',
              borderColor: MUI_COLORS?.LIGHT_GREEN,
              marginLeft: '4px'
            },
            '& .MuiStep-root': {
              paddingLeft: '4px',
              paddingRight: '0px'
            }
          }}
        >
          {steps.map((step, index) => (
            <Step
              key={step.label}
              sx={{
                cursor: 'pointer',
                '.MuiStepIcon-text': {
                  fill: index === activeStep ? MUI_COLORS.WHITE : `${MUI_COLORS?.GREEN} !important`
                },
                '& .Mui-completed': {
                  color: `${MUI_COLORS?.GREEN} !important`
                }
              }}
            >
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <Typography className="md:block hidden">{step.description}</Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
}
