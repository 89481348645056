import React from 'react';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import Points from './Points';
import SpecialPoint from './SpecialPoint';
import Prices from './Prices';
import TrustPilotReviewLine from '../common/TrustPilotReviewLine';
import HeaderRightSide from './HeaderRightSide';
import DiscountImage from '../../images/graphics/registerCompany/specialDiscount.webp';

const Header = () => {
  return (
    <div className="flex md:flex-row flex-col gap-4 w-full">
      <div className="md:w-1/2 w-full flex flex-col gap-6">
        <div>
          <MDSubtitleText title="Hong Kong  Incorporation" lineHeight="leading-[32px]" fontWeight="text-normal" />
          <MDSubtitleText title="One Package, One Price, All Included" fontWeight="text-bold" />
        </div>
        <div>
          <Points title="Easy & Fast Application" description="100% Online. 5 min sign-up" />
          <SpecialPoint />
          <Points
            title="True and Effective Support"
            description={
              <div
                dangerouslySetInnerHTML={{
                  __html: `
                      Response within 1 <span class="text-bold">Hour </span>
                  `
                }}
              ></div>
            }
          />
        </div>
        <Prices DiscountImage={DiscountImage} />
        <TrustPilotReviewLine />
      </div>
      <div className="md:w-1/2 w-full">
        <HeaderRightSide />
      </div>
    </div>
  );
};

export default Header;
