import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';
import axios from 'axios';

export const loginRequestStart = () => ({ type: actionTypes.LOGIN_REQUEST_START });
export const loginRequestFail = (error) => ({ type: actionTypes.LOGIN_REQUEST_FAILURE, error: error });
export const loginRequestSuccess = (loginPayload) => ({
  type: actionTypes.LOGIN_REQUEST_SUCCESS,
  loginPayload: loginPayload
});
export const login = (loginPayload) => {
  return async (dispatch) => {
    try {
      dispatch(loginRequestStart());
      let loginResponse = await axios.post(`${process.env.BASE_API_URL}${ENDPOINTS.COMPANY_LOGIN}`, loginPayload);
      dispatch(loginRequestSuccess(loginResponse?.data));
      return loginResponse;
    } catch (error) {
      dispatch(loginRequestFail(error.response));
      return error?.response;
    }
  };
};

export const resetPasswordRequestStart = () => ({ type: actionTypes.RESET_PASSWORD_REQUEST_START });
export const resetPasswordRequestFail = (error) => ({ type: actionTypes.RESET_PASSWORD_REQUEST_FAILURE, error: error });
export const resetPasswordRequestSuccess = (resetPasswordPayload) => ({
  type: actionTypes.RESET_PASSWORD_REQUEST_SUCCESS,
  resetPasswordPayload: resetPasswordPayload
});
export const resetPassword = (resetPasswordPayload) => {
  return async (dispatch) => {
    try {
      dispatch(resetPasswordRequestStart());
      let resetPasswordResponse = await Api.put(ENDPOINTS.RESET_PASSWORD, resetPasswordPayload);
      dispatch(resetPasswordRequestSuccess(resetPasswordResponse?.data));
      return resetPasswordResponse;
    } catch (error) {
      dispatch(resetPasswordRequestFail(error.response));
      return error?.response;
    }
  };
};
