/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import React from 'react';
import '../styles/layout.scss';
import NavigationBar from './sideBar/NavigationBar';
const Layout = ({ children, noSideBar }) => {
  return (
    <>
      <NavigationBar children={children} noSideBar={noSideBar} />
    </>
  );
};

export default Layout;
