import React from 'react';
import IconDbs from '../../images/icons/dbs.svg';
import XMSText from '../ui/typography/XMSText';
import '../../styles/components/dbs.scss';
import PropTypes from 'prop-types';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';

export default function Dbs({ image, noNeedBottomImage, awardTitle, customWidth, className }) {
  const { width } = useWindowDimensions();
  return (
    <div className={`${className} flex flex-col md:gap-1 gap-0 justify-center items-center md:self-center self-start`}>
      <GatsbyImageWrapper
        image={{
          url: image,
          height: 56,
          width: { customWidth }
        }}
        alt="IconAssetAsian"
        loading="lazy"
        width={customWidth}
        height="56"
      />
      <div className={`flex flex-col px-auto md:px-3`}>
        <XMSText fontWeight="text-bold" textAlign="text-center" className="company-name" title="Statrys Limited" />
        <XMSText className="whitespace-pre-line company-name " textAlign="text-center" title={awardTitle} />
      </div>
      {!noNeedBottomImage && (
        <GatsbyImageWrapper
          image={{
            url: IconDbs,
            height: 41,
            width: width < 767 ? 62 : 76
          }}
          alt="IconDbs"
          loading="lazy"
          width={width < 767 ? 62 : 76}
          height="41"
        />
      )}
    </div>
  );
}
Dbs.propTypes = {
  image: PropTypes.any,
  noNeedBottomImage: PropTypes.bool,
  awardTitle: PropTypes.string,
  width: PropTypes.string
};
Dbs.defaultPropTypes = {
  noNeedBottomImage: false,
  width: '56'
};
