import React, { useState } from 'react';
import FloatingCta from '../common/FloatingCta';
import FloatingCtaImg from '../../images/graphics/registerCompany/company-registration-sucess-img.png';
import H2HeaderText from '../ui/typography/H2HeaderText';
import { ENDPOINTS } from '../../service/end-points';
import NumberedAccordian from '../common/NumberedAccordian';
import BaseText from '../ui/typography/BaseText';
import TriangleDown from '../../images/icons/blackArrowDown.svg';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';
import { PATH_PAGE } from '../../routes/paths';

const info = 'Subject to requirements as per applicable regulations';
const whatsIncludedData = [
  {
    title:
      'Is there any difference between Hong Kong company formation, company registration, and company incorporation?',
    description: (
      <BaseText title="Hong Kong company formation, registration, and incorporation are basically the same thing, a set of processes to get a license to operate a business in Hong Kong legally." />
    )
  },
  {
    title: 'How does the company registration process work?',
    description: (
      <BaseText
        title={
          <div
            dangerouslySetInnerHTML={{
              __html: `Company Formation in Hong Kong is straightforward. After providing the necessary incorporation documents for the new company: company name, owners' and directors' passport copy, Statrys as your company secretary, will take care of the rest.<br/>
              Review of the <a href="https://statrys.com/guides/hong-kong/company-formation/articles-of-association" class='text-coral-500 text-bold'> Articles of Association </a> and prepare the application to the Business registration office.<br/>
              With all the documents ready, we will proceed to register your business as a new company in Hong Kong under the company name you provided. The Hong Kong Registry will check if the name is available and if all the documentation is correct and up to date.<br/>
              With the good news from Hong Kong Company registry, we will help you start the process of <a href="https://statrys.com/blog/opening-a-business-bank-account-in-hong-kong" class='text-coral-500 text-bold'> opening your business account.</a>`
            }}
          ></div>
        }
      />
    )
  },
  {
    title: 'How much does it cost to register a company in Hong Kong?',
    description: (
      <BaseText title="Company registration is relatively inexpensive in Hong Kong. Our package includes all the essential steps and documents to get your business started for only HK$7,740 (roughly USD$990), including the Government Fees." />
    )
  },
  {
    title: 'What do you get when you register your company in Hong Kong with Statrys?',
    description: (
      <BaseText
        className="md:whitespace-pre-line"
        title={`When you incorporate or register your Hong Kong company with us, you get the following:`}
      />
    )
  },
  {
    title: 'Pre-incorporation services',
    description: (
      <div>
        <ul className="faq-ul">
          <li>Verification of availability of the company name in Hong Kong</li>
          <li>Preparation of documents for incorporation</li>
          <li>Company incorporation in Hong Kong</li>
          <li>Filling out the incorporation application with the Companies Registry in Hong Kong</li>
          <li>Certificate of Incorporation</li>
          <li>Business Registration Certificate</li>
          <li>
            Company kit:{' '}
            <a
              href="https://statrys.com/guides/hong-kong/company-formation/company-chop-vs-common-seal"
              class="text-coral-500 text-bold"
            >
              Company chops{' '}
            </a>
            (corporate seals) and Set of Articles of Association
          </li>
        </ul>
      </div>
    )
  },
  {
    title: 'Post-incorporation services',
    description: (
      <div>
        <ul className="faq-ul">
          <li>Company secretary services for one yea</li>
          <li>A registered address for one year</li>
          <li>
            <a
              href="https://statrys.com/guides/hong-kong/company-formation/significant-controllers-register-hong-kong"
              class="text-coral-500 text-bold"
            >
              Significant controller register
            </a>
          </li>
          <li>Designated representative</li>
          <li>Government fees (included in the price)</li>
          <li>Incorporation fee</li>
          <li>Business registration fee and levy.</li>
        </ul>
      </div>
    )
  },
  {
    title: 'Can a foreigner register a company in Hong Kong?',
    description: (
      <div>
        <BaseText
          className="md:whitespace-pre-line"
          title={
            <div
              dangerouslySetInnerHTML={{
                __html: `Yes. <span class="text-bold">The law allows 100% foreign-owned companies in Hong Kong;</span> this means you can register a company in Hong Kong as a foreigner.`
              }}
            ></div>
          }
        />
      </div>
    )
  },
  {
    title: 'How much is the business registration fee in Hong Kong?',
    description: (
      <div>
        <BaseText
          className="md:whitespace-pre-line"
          title={`A business registration fee in Hong Kong is a compulsory one-time payment made to the government entity known as the Companies Registry. The fees amount to approximately HKD 1,500 for the electronic delivery of the application and around HKD 1,700 for hard copy delivery.`}
        />
      </div>
    )
  },
  {
    title: 'How long does business registration take in Hong Kong?',
    description: (
      <div>
        <BaseText
          className="md:whitespace-pre-line"
          title={`The license from the Hong Kong Companies Registry usually takes around 4 to 5 days.`}
        />
      </div>
    )
  },
  {
    title: 'What do you need to register a Hong Kong Company?',
    description: (
      <div>
        <BaseText
          className="md:whitespace-pre-line"
          title={
            <div
              dangerouslySetInnerHTML={{
                __html: `To set up your new Hong Kong company, and before going to the Hong Kong Companies Registry, you must prepare a few items, including some of the future company documents that will come in handy along the way.<br/>
                <span class='text-bold'>1. A company name</span><br/>
                Before starting a business registration process, <a href="https://statrys.com/guides/hong-kong/legal-entities" class="text-bold text-coral-500">every business entity</a> must have a name. So first, <a href="https://www.google.com/url?q=https://www.notion.so/statrys-limited/Most-Popular-Company-Names-in-Hong-Kong-2024-e13d729be86d4758bf3160b27f5728be?pvs%3D4&sa=D&source=docs&ust=1714981474896000&usg=AOvVaw0MWqjmzlcF1p2XHNFbmFMe" class="text-bold text-coral-500">select the company name</a> you wish to register as your new Hong Kong Company. To save time during the incorporation process, it is always a good idea to have a backup name in case the one you want is already registered. You can check if your business name is available by doing a company name search on the <a href="https://www.e-services.cr.gov.hk/ICRIS3EP/system/home.do?systemclock=1721200498174" class="text-bold text-coral-500">Hong Kong Company Registry’s website.</a><br/>
                 <span class='text-bold'>2. Appoint a company secretary</span><br/>
                 A <a href="https://statrys.com/guides/hong-kong/company-formation/what-is-a-company-secretary" class="text-bold text-coral-500">company secretary</a> is an incorporation agent trained and licensed to help you carry on the registration with the Business Registration Office (the government office in charge of registering companies in Hong Kong). They offer professional<a href="https://statrys.com/guides/hong-kong/company-formation" class="text-bold text-coral-500"> company formation</a> services and ensure the company complies with Hong Kong law.<br/>
                 Though it is not a requirement for Hong Kong citizens, it is for foreign-owned companies to have a company secretary appointed. This is done with a binding document called NNC1.<br/>
                 A Company secretary’s tasks include: keeping track of the company records and legal documents, doing accounting and tax filings, having the company audited accounts by Certified Public Accountants, and ensuring that the company has a valid business registration certificate and that, in general, follows ongoing compliance with the local legislation and with the Inland Revenue Department.<br/>
                 When you register your company with Statrys, we will be your company secretary for the company registration process. Afterward, and for the other services mentioned above, we can recommend <a href="https://statrys.com/reviews/company-secretary-hong-kong" class="text-bold text-coral-500">other verified companies offering company secretary services</a>.<br/>
                <span class='text-bold'>3. Choose the legal vehicle of your Company</span><br/>
                Before registering your company in Hong Kong, you need to decide on the structure of your business. Limited liability company,<a href="https://statrys.com/guides/hong-kong/legal-entities/how-to-register-a-sole-proprietorship" class="text-bold text-coral-500"> sole proprietorship,</a> different partnerships, or other business structures. Limited liability companies are easier to manage and cover most business owners' needs. No matter what legal vehicle, the requirements are at least one shareholder and one director.<br/>
                <span class='text-bold'>4. A local Hong Kong address</span><br/>
                One of the requirements for Hong Kong companies is that every registered business must have a company's registered address. The Company Secretary usually provides this service. When you choose to have your company registered with Statrys, we include a business address in our package that will be used during the process. This service has to be renewed every year.<br/>
                <span class='text-bold'>5. IDs or passport copies of the company owners and directors</span><br/>
                A Hong Kong company must have at least one shareholder. This can be you or anybody you appoint as an owner. In any case, prepare a copy of the passport of each one of the corporate shareholders. A Hong Kong company can have a foreigner as an individual director or corporate shareholder without restrictions.<br/>
                You will also need a<a href="https://statrys.com/blog/proof-of-address-hong-kong" class="text-bold text-coral-500"> residential address</a> and proof of foreign business professionals. Overseas residential address proof is perfectly valid.<br/>
                If you prefer, you can also have a designated representative to act as an individual director of the company while you oversee business operations as an owner. This is helpful, especially for Hong Kong companies, when managing requests from the Hong Kong authorities, approving audited accounts, and performing other business activities that require a person.<br/>
                <span class='text-bold'>What is a company director?</span><br/>
                <a href="https://statrys.com/guides/hong-kong/company-formation/company-directors" class="text-bold text-coral-500">Company directors</a> are company officers responsible for running the company on behalf of its shareholders. They have a duty to act with good faith and in the best interests of the company's shareholders. The law requires directors to exercise their powers responsibly, honestly and in the best interests of the company. Where a director is not fulfilling this duty, they may be disqualified as a director.<br/>
                A company can appoint a director through an ordinary resolution or the decision of existing directors, who must inform the Company Registry within 15 days of the appointment being confirmed.<br/>
                <span class='text-bold'>What is the Inland Revenue Department?</span><br/>
                The Inland Revenue DepartmentInland Revenue Department <a href="https://www.ird.gov.hk/eng/welcome.htm" class="text-bold text-coral-500">Inland Revenue Department (IRD)</a> is the government department responsible for taxes and duties in Hong Kong.<br/>
                Simply put, you must file your company tax return every year with the IRD, which will then determine how much tax your company has to pay.<br/>
                <span class='text-bold'>What is a Hong Kong business registration certificate?</span><br/>
                A <a href="https://statrys.com/guides/hong-kong/company-formation/business-registration-certificate" class="text-bold text-coral-500"> business registration certificate </a> is a document issued by the Hong Kong Business Registration Office conveying the details of a company. If you own a business in Hong Kong, you are required to apply for a Business Certificate within 30 days of commencing operations. If the nature or details of the company change, you will need to update the Business Registration Office within 30 days.<br/>
                The Business registration certificate expresses the following:
                <ul className="faq-ul">
          <li>Business name</li>
          <li>Nature of the business</li>
          <li>Registered Business address</li>
          <li>The type of business</li>
          <li>The date the business started</li>
          <li>The Expiry date</li>
          <li>A Certificate number</li>
        </ul>`
              }}
            ></div>
          }
        />
      </div>
    )
  },
  {
    title: 'What is a Hong Kong certificate of incorporation?',
    description: (
      <div>
        <BaseText
          className="md:whitespace-pre-line"
          title={
            <div
              dangerouslySetInnerHTML={{
                __html: `<a href="https://statrys.com/guides/hong-kong/company-formation/certificate-of-incorporation" class="text-bold text-coral-500"> The certificate of incorporation</a> is a document issued by the Companies Registry of Hong Kong SAR. This document certifies the incorporation of any Hong Kong-based limited liability company written both in Traditional Chinese and English languages.<br/>`
              }}
            ></div>
          }
        />
        <GatsbyImageWrapper
          image={{
            url: 'https://images.prismic.io/statrys/ccf67720-76ef-4946-8927-7f36b94ace25_certificate+of+incorporation.png',
            height: 1182,
            width: 832
          }}
          alt="incorporation certificate"
          loading="lazy"
          className="mt-2"
        />
      </div>
    )
  },
  {
    title: 'What is Statrys Company Secretary license number?',
    description: (
      <div>
        <BaseText
          className="md:whitespace-pre-line"
          title={
            <div
              dangerouslySetInnerHTML={{
                __html: `Statrys Corporate Services Ltd Trust or Company Service Provider License number is <a class="text-bold text-coral-500" href="https://www.tcsp.cr.gov.hk/tcspls/search/public-search?k=TC008677"> TC008677.</a>`
              }}
            ></div>
          }
        />
      </div>
    )
  },
  {
    title:
      'What is the annual fee for maintaining your company in good standing after the first year of incorporation?',
    description: (
      <div>
        <BaseText
          className="md:whitespace-pre-line"
          title={`Our annual maintenance fee is HKD 7,740, which is 10% less than the fee charged during the incorporation year. This fee covers the same secretarial services provided in the first year, extending them for an additional one-year period.`}
        />
      </div>
    )
  }
];

const Faq = () => {
  const [isOpen, setIsOpen] = useState(1);
  return (
    <div className="flex md:flex-row flex-col md:my-[120px] mt-16 gap gap-12 w-full">
      <div className="flex flex-col md:w-1/4 w-full">
        <H2HeaderText title="People also ask" fontWeight="text-bold" />
        <div className="mt-6 sticky top-32">
          <FloatingCta
            title={'Register your company in Hong Kong with Statrys'}
            subtitle={'100% online and hassle-free'}
            imageUrl={FloatingCtaImg}
            buttonCaption={'Get Started'}
            buttonLink={ENDPOINTS?.HONG_KONG_COMPANY_REGISTRATION}
            backgroundColor={'bg-green-500'}
            primaryTextColor="text-white"
            secondaryTextColor="text-white"
            buttonBackgroundColor="bg-white"
            buttonCaptionTextColor="text-green-500"
            border="border-none"
            buttonClass="floating-cta-invoice-button"
            contentPadding="6"
            borderRadius="rounded-2xl"
            path={PATH_PAGE.hkIncorporation}
          />
        </div>
      </div>
      <div className="md:w-3/4 w-full">
        <div className="w-full mb-16">
          {whatsIncludedData?.map((data, id) => {
            return (
              <NumberedAccordian
                key={id}
                isLast={whatsIncludedData.length === id + 1}
                isOpen={isOpen === id + 1}
                onClick={() => setIsOpen(isOpen === id + 1 ? false : id + 1)}
                title={data?.title}
                description={data?.description}
                GreenBar={false}
                arrowImg={TriangleDown}
                needHr={false}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Faq;
