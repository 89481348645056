import React from 'react';
import BackButton from '../../ui/buttons/BackButton';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import XSText from '../../ui/typography/XSText';
import MSText from '../../ui/typography/MSText';
import TextInput from '../../ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import ValidationMessage from '../../ui/ValidationMessage';
import { useSelector, connect } from 'react-redux';
import * as CompanyAction from '../../../components/companyIncorporation/reduxStore/action';
import { API_CODE, COMPANY_SETUP_STEP, REGISTERED_COUNTRY } from '../../../constants/enum';

const CompanyName = ({ nextSubStep, nextStep, prevStep, updateCompanyIncorporation }) => {
  const companyAllInformation = useSelector(
    (state) => state?.companyIncorporationReducer?.getCompanyIncorporation?.data?.data || {}
  );
  const { register, handleSubmit, errors } = useForm();
  const back = () => {
    prevStep();
  };

  const onSubmit = async (data) => {
    if (data) {
      let payload = {
        companyName: data?.companyName,
        companySecondaryName: data?.secondCompanyName,
        companyId: companyAllInformation?._id,
        completedStep: COMPANY_SETUP_STEP?.COMPANY_NAME
      };
      const response = await updateCompanyIncorporation(payload);
      if (response?.status === API_CODE?.STATUS_200) {
        nextSubStep();
        nextStep();
      }
    }
  };

  return (
    <>
      {companyAllInformation?._id && (
        <div className="flex flex-col md:gap-8 gap-6 w-full h-full md:justify-between main-wrapper-container">
          <MDSubtitleText title="Company name" fontWeight="text-bold" />
          <div className="flex md:flex-row flex-col md:gap-12 gap-6">
            <div>
              <XSText title="List your top two preferred company names, in order of preference" />
              <form id="support-form" onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                  defaultValue={companyAllInformation?.companyName}
                  name="companyName"
                  label="First choice"
                  margin="mt-2"
                  inputRef={register({
                    required: true
                  })}
                  error={errors?.companyName ? true : false}
                  helperText={errors?.companyName && <ValidationMessage title="Please fill in a valid Company name" />}
                />
                <TextInput
                  defaultValue={companyAllInformation?.companySecondaryName}
                  name="secondCompanyName"
                  label="Second choice"
                  margin="mt-2"
                  inputRef={register({
                    required: true
                  })}
                  error={errors?.secondCompanyName ? true : false}
                  helperText={
                    errors?.secondCompanyName && <ValidationMessage title="Please fill in a valid Company name" />
                  }
                />
              </form>
            </div>
            <div className="flex flex-col gap-4 business-details-text-container">
              <MSText title="Things to know" fontWeight="text-bold" />
              <MSText title="We request two company names to streamline the incorporation of your company. If your first choice is unavailable, we immediately proceed with the second, avoiding delays." />
              {companyAllInformation?.registrationCountry === REGISTERED_COUNTRY.HONGKONG && (
                <MSText title='No matter the name you pick, it will end with the word "Limited" as per Hong Kong regulations.' />
              )}
              {companyAllInformation?.registrationCountry === REGISTERED_COUNTRY.SINGAPORE && (
                <MSText title="No matter the name you pick, it will end with the word “Pte. Ltd”." />
              )}
            </div>
          </div>
          <div className="flex md:flex-row flex-col-reverse justify-between">
            <BackButton onClick={back} className="md:w-[114px] w-full" />
            <PrimaryButton
              caption="Continue"
              color="text-white"
              className="w-full"
              isBorderRequired={true}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCompanyIncorporation: (payload) => dispatch(CompanyAction.updateCompanyIncorporation(payload))
  };
};

export default connect(null, mapDispatchToProps)(CompanyName);
