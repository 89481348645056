import React from 'react';
import NumberCircle from '../../common/NumberCircle';
import XXSText from '../../ui/typography/XXSText';
import Line from '../../common/Line';
const NormalStepper = ({ completedStep, currentStep, verifyEmailSteps }) => {
  return (
    <div className="my-8">
      {verifyEmailSteps?.map((verifySteps, index) => {
        return (
          <>
            {index !== 0 && <Line />}
            <div className="flex flex-row items-center justify-between my-2" key={index}>
              <div className="flex flex-row items-center gap-4">
                {!verifySteps?.isCompleted ? (
                  <NumberCircle
                    isStepCompleted={completedStep === verifySteps?.number ? true : false}
                    number={verifySteps?.number}
                    bgColor={completedStep === verifySteps?.number ? 'bg-green-500' : 'bg-green-200'}
                    textColor={completedStep === verifySteps?.number ? 'text-white' : 'text-green-500'}
                  />
                ) : (
                  <NumberCircle
                    isStepCompleted={verifySteps?.isCompleted ? true : false}
                    number={verifySteps?.number}
                    bgColor={verifySteps?.isCompleted ? 'bg-green-500' : 'bg-green-200'}
                    textColor={verifySteps?.isCompleted ? 'text-white' : 'text-green-500'}
                  />
                )}
                <XXSText title={verifySteps?.name} fontWeight={currentStep === verifySteps.number && 'text-bold'} />
              </div>
              <XXSText
                title={
                  completedStep?.length > 0 && !completedStep?.includes(verifySteps?.number) ? verifySteps?.time : '👍'
                }
                textColor="text-gray-450"
              />
            </div>
          </>
        );
      })}
    </div>
  );
};

NormalStepper.propTypes = {};
export default NormalStepper;
