import React, { useState } from 'react';
import '../../styles/pages/login.scss';
import LoginImgBanner from './LoginImgBanner';
import LoginForm from './LoginForm';
import BaseText from '../ui/typography/BaseText';
import { useLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';
import XSText from '../ui/typography/XSText';
import { LOGIN_TAB } from '../../constants/enum';
import Seo from '../seo';
import Logo from '../ui/Logo';

export default function LoginPage() {
  const location = useLocation();

  const params = new URLSearchParams(location?.search);
  const tab = params.get('tab');
  const defaultTabSelector = () => {
    if (tab === 'company-management') return LOGIN_TAB?.COMPANY_MANAGEMENT;
    else if (tab === 'invoicing-software') {
      return LOGIN_TAB?.INVOICE;
    } else {
      return LOGIN_TAB?.COMPANY_MANAGEMENT;
    }
  };
  const [activeLoginForm, setActiveLoginForm] = useState(defaultTabSelector());

  const handleActiveTab = (activeTab) => {
    setActiveLoginForm(activeTab);
    if (activeTab === LOGIN_TAB?.INVOICE) {
      navigate(`${process.env.INVOICE_APP_URL}/login`);
    }
    if (activeTab === LOGIN_TAB?.COMPANY_MANAGEMENT) {
      navigate('?tab=company-management');
    }
  };
  return (
    <div className="login-layout">
      <Seo
        title={
          activeLoginForm === LOGIN_TAB?.INVOICE
            ? 'Login - Invoicing | Statrys'
            : 'Login - Company Management | Statrys'
        }
        description={
          activeLoginForm === LOGIN_TAB?.INVOICE
            ? 'Log into your Statrys Invoicing Account.'
            : 'Log into your Company Management Portal.'
        }
      />
      <LoginImgBanner activeLoginForm={activeLoginForm}>
        <div className="bg-white md:p-10 py-10 px-4 rounded-lg md:mt-0">
          <div className="mb-10">
            <Logo />
          </div>
          <BaseText className="mb-4" title="Choose the service you want to use" />
          <div className="flex mb-4 cursor-pointer">
            <a
              href={`${process.env.MARKETING_SITE_URL}/login`}
              className={`w-full hover:no-underline border flex justify-center items-center gap-1 ${
                activeLoginForm === LOGIN_TAB?.BUSINESS_ACCOUNT ? 'border-coral-500 bg-salmon-500' : 'border-gray-200'
              } rounded-l-lg px-3 py-2`}
            >
              <XSText
                textAlign="text-center"
                textColor={activeLoginForm === LOGIN_TAB?.BUSINESS_ACCOUNT ? 'text-coral-500' : 'text-gray-500'}
                fontWeight={activeLoginForm === LOGIN_TAB?.BUSINESS_ACCOUNT ? 'text-bold' : 'text-medium'}
                title={'Business \nAccount'}
                className="mx-auto whitespace-pre-line"
              />
            </a>
            <a
              href={`${process.env.INVOICE_APP_URL}/login`}
              className={`w-full border hover:no-underline ${
                activeLoginForm === LOGIN_TAB?.INVOICE ? 'border-coral-500 bg-salmon-500' : 'border-gray-200'
              } px-3 py-2 flex items-center justify-center"`}
              onClick={() => handleActiveTab(LOGIN_TAB?.INVOICE)}
            >
              <XSText
                textAlign="text-center"
                textColor={activeLoginForm === LOGIN_TAB?.INVOICE ? 'text-coral-500' : 'text-gray-500'}
                fontWeight={activeLoginForm === LOGIN_TAB?.INVOICE ? 'text-bold' : 'text-medium'}
                title={`Invoicing\nSoftware`}
                className="mx-auto whitespace-pre-line"
              />
            </a>
            <div
              className={`w-full border hover:no-underline ${
                activeLoginForm === LOGIN_TAB?.COMPANY_MANAGEMENT ? 'border-coral-500 bg-salmon-500' : 'border-gray-200'
              } rounded-r-lg px-3 py-2 flex items-center justify-center"`}
              onClick={() => handleActiveTab(LOGIN_TAB?.COMPANY_MANAGEMENT)}
            >
              <XSText
                textAlign="text-center"
                textColor={activeLoginForm === LOGIN_TAB?.COMPANY_MANAGEMENT ? 'text-coral-500' : 'text-gray-500'}
                fontWeight={activeLoginForm === LOGIN_TAB?.COMPANY_MANAGEMENT ? 'text-bold' : 'text-medium'}
                title={`Company\nManagement`}
                className="mx-auto whitespace-pre-line"
              />
            </div>
          </div>
          <LoginForm activeLoginForm={activeLoginForm} />
        </div>
      </LoginImgBanner>
    </div>
  );
}
