import React from 'react';
import { SVG_TYPES } from '../constants/enum';
import MultiplleUser from '../images/icons/customSvg/MultiplleUser';
import PieLineGraph from '../images/icons/customSvg/PieLineGraph';
import AccountInvoice from '../images/icons/customSvg/AccountInvoice';
import CashPayment from '../images/icons/customSvg/CashPayment';
import Profile from '../images/icons/customSvg/Profile';
import Logout from '../images/icons/customSvg/Logout';
import Setting from '../images/icons/customSvg/Setting';
import AddCircle from '../images/icons/customSvg/AddCircle';

export const getSvgIcon = (type) => {
  switch (type) {
    case SVG_TYPES.DASHBOARD:
      return <PieLineGraph />;
    case SVG_TYPES.MULTIPLEUSER:
      return <MultiplleUser />;
    case SVG_TYPES.ACCOUNTINVOICE:
      return <AccountInvoice />;
    case SVG_TYPES.CASHPAYMENT:
      return <CashPayment />;
    case SVG_TYPES.PROFILE:
      return <Profile />;
    case SVG_TYPES.LOGOUT:
      return <Logout />;
    case SVG_TYPES.SETTING:
      return <Setting />;
    case SVG_TYPES.ADD_CIRCLE:
      return <AddCircle />;
    default:
      return <PieLineGraph />;
  }
};
