import React from 'react';
import Logo from '../components/ui/Logo';
import Header from '../components/Landing/Header';
import '../styles/pages/landing.scss';
import GuaranteedBatch from '../components/Landing/GuaranteedBatch';
import OnePackage from '../components/Landing/OnePackage';
import FiveSteps from '../components/Landing/FiveSteps';
import Faq from '../components/Landing/Faq';
import CompanyRegistrationCTA from '../components/Landing/CompanyRegistrationCTA';
import Footer from '../components/common/Footer';
import Seo from '../components/seo';

const Landing = () => {
  return (
    <div className="bg-customBg-secondaryBg">
      <Seo
        noIndex={true}
        unFollow={true}
        title="Hong kong incorporation"
        description="Register your company in Hong Kong with Statrys. Quick and easy online registration process. Get started today and enjoy a wide range of benefits."
      />
      <div className="pt-8 md:max-w-[1224px] w-full mx-auto md:px-0 px-4">
        <Logo />
        <div className="my-8">
          <Header />
        </div>
        <GuaranteedBatch />
        <OnePackage />
        <FiveSteps />
        <Faq />
        <CompanyRegistrationCTA />
        <Footer />
      </div>
    </div>
  );
};

export default Landing;
