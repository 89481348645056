import React from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import PropTypes from 'prop-types';
import TextInput from '../../ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import XSText from '../../ui/typography/XSText';
import { connect } from 'react-redux';
import * as actionsForRegistrationNewCompany from '../redux/action';
import { handleEnter, isBrowser } from '../../../helper/helpers';
import { MUI_COLORS } from '../../../constants/enum';
import SnackBar from '../../common/SnackBar';
import CheckCircle from '../../../images/icons/green-check-light.svg';
import AlertTriangle from '../../../images/icons/AlertTriangle.svg';
import StepsIndicator from '../../ui/StepsIndicator';
import BackButton from '../../ui/buttons/BackButton';
import { PATH_PAGE } from '../../../routes/paths';
function CompanyName({ nextStep, prevStep, newCompanyData, newCompanyAllInfo, setCompletedSteps, completedSteps }) {
  const { handleSubmit, register } = useForm();
  const stateData = isBrowser() && window?.history?.state;
  const onSubmit = (data) => {
    let newCompanyPayload = {
      ...newCompanyAllInfo,
      companyName: data.companyName
    };
    newCompanyData(newCompanyPayload);
    setCompletedSteps({
      ...completedSteps,
      companyInformationCompleted: true
    });
    nextStep();
  };
  function getCompanyName() {
    if (newCompanyAllInfo?.companyName) {
      return newCompanyAllInfo?.companyName;
    } else if (stateData?.companyName && stateData?.isCompanyNameAvailable) {
      return stateData?.companyName;
    } else {
      return '';
    }
  }

  return (
    <div className="bg-white md:p-8 p-4 rounded-lg flex flex-col items-stretch justify-between company-registration-form-container">
      {stateData?.isCompanyNameAvailable && (
        <SnackBar
          title="Congratulations!"
          subTitle={`${stateData?.companyName}, appears to be available!`}
          subTitleColor="text-green-500"
          icon={CheckCircle}
          className="mb-8 md:mt-0 mt-8"
        />
      )}
      {!stateData?.isCompanyNameAvailable && stateData?.companyName && (
        <SnackBar
          title="Oops!"
          subTitle={`${stateData?.companyName}, appears to be taken!`}
          description={`No need to worry. You can continue your registration \nproviding other options.`}
          icon={AlertTriangle}
          bgColor="bg-yellow-300"
          borderColor="border-yellow-500"
          className="mb-8 md:mt-0 mt-8"
        />
      )}
      <StepsIndicator numberOfSteps={6} currentStepNumber={0} />
      <div className="flex flex-col justify-center">
        <MDSubtitleText
          className="whitespace-pre-line mb-6"
          fontWeight="text-bold"
          title={`Let's get started with your new company`}
        />
        <div>
          <TextInput
            onKeyDown={handleEnter}
            defaultValue={getCompanyName()}
            name="companyName"
            label="Company name"
            placeholder="e.g. Red Dragon Limited"
            variant="filled"
            inputRef={register({
              required: false
            })}
          />
          <XSText className="mt-2" title="Not decided yet? Skip this step and decide later." />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row gap-2 self-end md:items-center w-full">
        {stateData?.path !== PATH_PAGE.hkIncorporation && <BackButton onClick={prevStep} />}
        <div className="w-full">
          <PrimaryButton
            id="cc-compinfo-skip"
            className="capitalize onboarding-button w-full"
            bgColor="bg-white"
            color={MUI_COLORS?.GRAY}
            style={{ border: `1px solid ${MUI_COLORS?.SILVER_MEDAL}`, padding: 0 }}
            fontSize="text-base"
            caption="Skip for now"
            linkClass="w-full"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
        <div className="w-full">
          <PrimaryButton
            id="cc-compinfo-continue"
            className="capitalize onboarding-button w-full"
            fontSize="text-base"
            caption="Continue"
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    newCompanyAllInfo: state?.newAccountSetup?.newCompanyRegistrationData,
    companyId: state?.newAccountSetup?.companyCreateDetails?.data?._id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    newCompanyData: (newCompanyRegistrationData) =>
      dispatch(actionsForRegistrationNewCompany.newCompanyRegistrationData(newCompanyRegistrationData)),
    companyCreate: (companyCreateData) => dispatch(actionsForRegistrationNewCompany.companyCreate(companyCreateData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyName);

CompanyName.propTypes = {
  newCompanyData: PropTypes.object,
  newCompanyAllInfo: PropTypes.object,
  nextStep: PropTypes.func,
  setCompletedSteps: PropTypes.func,
  completedSteps: PropTypes.object,
  companyCreate: PropTypes.func,
  location: PropTypes.func,
  setAllFieldsData: PropTypes.func,
  allFieldsData: PropTypes.object,
  companyId: PropTypes.object,
  prevStep: PropTypes.func
};
