import { Link } from 'gatsby';
import React from 'react';
import StatrysLogo from '../../../../static/statrys-logo.svg';
import PropTypes from 'prop-types';

export default function Navbar() {
  return (
    <Link
      to={process.env.SITE_URL}
      className="bg-customBg-secondaryBg flex md:justify-start justify-center md:absolute"
    >
      <div className="md:px-6 px-4 md:pt-6 pt-4">
        <img src={StatrysLogo} alt="Statrys Logo" width="146.24" height="40" />
      </div>
    </Link>
  );
}
Navbar.propTypes = {
  step: PropTypes.number
};
