import React from 'react';

const AddCircle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_1821_11259)">
        <path d="M8 5.1875V10.8125" stroke="#E15741" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M5.1875 8H10.8125" stroke="#E15741" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M0.96875 8C0.96875 9.8648 1.70954 11.6532 3.02816 12.9718C4.34677 14.2905 6.1352 15.0312 8 15.0312C9.8648 15.0312 11.6532 14.2905 12.9718 12.9718C14.2905 11.6532 15.0312 9.8648 15.0312 8C15.0312 6.1352 14.2905 4.34677 12.9718 3.02816C11.6532 1.70954 9.8648 0.96875 8 0.96875C6.1352 0.96875 4.34677 1.70954 3.02816 3.02816C1.70954 4.34677 0.96875 6.1352 0.96875 8Z"
          stroke="#E15741"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1821_11259">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default AddCircle;
