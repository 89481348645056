import React, { useState } from 'react';
import LGSubtitleText from '../../ui/typography/LGSubtitleText';
import XSText from '../../ui/typography/XSText';
import BackButton from '../../ui/buttons/BackButton';
import EmailMan from '../../../images/graphics/company-incorporation/got-email.png';
import Countdown, { zeroPad } from 'react-countdown';
import { Link } from 'gatsby';
import OtpInput from './OtpInput';
import axios from 'axios';
const VerifyOtp = ({ prevStep, nextStep }) => {
  const [resendLink, setResendLink] = useState(false);
  const [resendOtpClicked, setResendOtpClicked] = useState(false);
  const [timeUp, setTimeUp] = useState(false);

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setTimeUp(true);
      setResendLink(true);
      setResendOtpClicked(true);
      return <div></div>;
    } else {
      return (
        <span className={`${timeUp ? 'hidden' : 'block'} text-gray-400 ml-1 -mt-1`}>
          ({zeroPad(minutes)}:{zeroPad(seconds)})
        </span>
      );
    }
  };
  const reSendOtp = async () => {
    setTimeUp(false);
    setResendLink(false);
    await axios.post(`${process.env.BASE_API_URL}invoice/resendOtp`, {
      email: sessionStorage.getItem('email').replace(/ /g, '+')
    });
  };

  return (
    <div className="flex md:flex-row flex-col md:gap-12 gap-6 w-full initial-wrapper">
      <div className="md:w-1/2 w-full flex flex-col gap-3 justify-between">
        <LGSubtitleText title="Verify your email address" textWeight="text-medium" />
        <div>
          <div className="flex flex-col gap-4">
            <XSText
              title={
                <div>
                  A 6-digit code has been sent to{' '}
                  <span className="text-bold">{sessionStorage.getItem('email').replace(/ /g, '+')}</span>
                </div>
              }
            />
            <XSText title="Enter the code to verify your e-mail." />
          </div>
          <div className="mt-4">
            <OtpInput resendOtpClicked={resendOtpClicked} nextStep={nextStep} />
          </div>
          <div className="mt-8 flex flex-col">
            <XSText title="Did not receive the code?" />
            <div className="flex mt-1">
              <div onClick={() => (resendLink ? reSendOtp() : null)}>
                <XSText
                  className={resendLink ? 'cursor-pointer' : null}
                  textColor={resendLink ? 'text-coral-500' : 'text-gray-400'}
                  title="Resend the code"
                />
              </div>
              {!resendLink && <Countdown date={Date.now() + 60000 * 3} renderer={renderer} />}
              <XSText className="mx-1" title="or" />
              <Link to="/contact">
                <XSText textColor="text-coral-500" title="Contact us" />
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <BackButton onClick={() => prevStep()} />
        </div>
      </div>
      <div className="md:w-1/2 w-full flex justify-center">
        <img src={EmailMan} alt="mail for you" width="368" className="object-contain" />
      </div>
    </div>
  );
};

export default VerifyOtp;
