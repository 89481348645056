import React from 'react';
import PropTypes from 'prop-types';
import '../../../styles/pages/company-set-up/wrappers.scss';

const InitialWrapper = ({ children }) => {
  return (
    <div className="bg-white rounded-2xl initial-wrapper-container flex items-center w-full">
      <div className="md:p-8 p-4 w-full">{children}</div>
    </div>
  );
};

InitialWrapper.propTypes = {
  children: PropTypes.any
};

export default InitialWrapper;
