import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { REGEX, MUI_COLORS } from '../../../constants/enum';

export default function MobileNumberAutoComplete({
  label,
  value,
  defaultValue,
  options,
  setSelectedValue,
  multiple,
  disabled,
  inputRef,
  error,
  helperText,
  onInputValueChange,
  placeholder,
  name
}) {
  const [inputValue, setInputValue] = useState('');
  const useStyles = makeStyles({
    InputNoIconStyle: {
      '& .MuiChip-root': {
        fontFamily: 'HKGrotesk-Regular',
        fontSize: '16px',
        lineHeight: '18px',
        letterSpacing: '-0.5px',
        color: MUI_COLORS.WHITE,
        marginRight: '0px',
        backgroundColor: MUI_COLORS.PURPLE,
        borderRadius: '8px',
        padding: '10px',
        height: '38px',
        textTransform: 'capitalize'
      },
      '& .MuiChip-label': {
        paddingLeft: '0px',
        paddingRight: '6px'
      },
      '& .MuiChip-deleteIcon': {
        fill: MUI_COLORS.WHITE,
        margin: '0px',
        width: '18px',
        height: '18px'
      },
      '& .MuiFilledInput-root': {
        border: '1px solid #D6D6D6',
        overflow: 'hidden',
        borderRadius: 8,
        color: 'black',
        backgroundColor: disabled ? MUI_COLORS.LIGHT_GRAY : MUI_COLORS?.WHITE,
        minHeight: '68px',
        height: 'auto',
        '&.Mui-focused': {
          color: MUI_COLORS.DARK_CHARCOAL,
          borderColor: MUI_COLORS.PURPLE,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.24)'
        }
      },
      '& .Mui-disabled': {
        background: MUI_COLORS?.LIGHT_GRAY,
        cursor: 'not-allowed'
      },
      '& .MuiFilledInput-input': {
        padding: '12px 8px !important'
      },
      '& .MuiFilledInput-adornedStart': {
        paddingTop: '40px',
        paddingBottom: '10px'
      },
      '& .MuiInputBase-input': {
        background: 'content-box',
        boxSizing: 'inherit',
        letterSpacing: '-0.5px',
        fontFamily: 'HKGrotesk-Medium',
        color: MUI_COLORS.DARK_GRAY
      },
      '& .MuiFormLabel-root': {
        fontFamily: 'HKGrotesk-Regular',
        fontWeight: 400,
        fontSize: '14px',
        paddingTop: '1px',
        lineHeight: '16px',
        color: MUI_COLORS.GRAY
      },
      '& .MuiInputLabel-filled': {
        margin: 6,
        marginLeft: 5,
        letterSpacing: '-0.5px'
      },
      '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
        transform: 'translate(12px, 10px) scale(1)'
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, 5px) scale(1)',
        marginTop: 6
      },
      '& .PrivateNotchedOutline-legendLabelled-7 > span': {
        display: 'none'
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
        borderColor: 'black'
      },
      '& .MuiFormLabel-root.Mui-focused': {
        color: MUI_COLORS.GRAY
      },
      '& .MuiFilledInput-root.Mui-error': {
        backgroundColor: MUI_COLORS.LIGHT_CREAM,
        border: '1px solid #FF4F42'
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: MUI_COLORS.SUNSET_ORANGE
      },
      '& .MuiFormHelperText-root': {
        minWidth: '176px',
        fontFamily: 'HKGrotesk-Regular',
        letterSpacing: '-0.5px',
        fontSize: '14px',
        lineHeight: '16px',
        marginTop: '8px'
      },
      '& .MuiFormHelperText-contained': {
        marginRight: '0px',
        marginLeft: '16px'
      },
      '& input[type=number]': {
        '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      }
    }
  });
  const classes = useStyles();

  useEffect(() => {
    setInputValue(value ? value : defaultValue ? defaultValue : '');
  }, [value]);

  return (
    <div>
      <Autocomplete
        freeSolo={true}
        multiple={multiple}
        disabled={disabled}
        name={name}
        value={value}
        getOptionLabel={(option) => (option.displayString ? option.displayString : option.name)}
        defaultValue={defaultValue}
        onChange={(event, newValue) => {
          setSelectedValue(newValue);
        }}
        onBlur={() => onInputValueChange(inputValue)}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          if (newInputValue.match(REGEX.ONLY_STRING_REGEX)) {
            setInputValue(newInputValue);
          } else {
            const removingCountryName = newInputValue.replace(REGEX.REPLACE_STRING_REGEX, '');
            setInputValue(removingCountryName ? `+${removingCountryName}` : '');
          }
        }}
        id="controllable-states-demo"
        options={options}
        style={{ width: '100%' }}
        renderInput={(params) => (
          <TextField
            className={`${classes.InputNoIconStyle}`}
            name={name}
            value={value}
            {...params}
            label={label}
            placeholder={placeholder}
            variant="filled"
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            inputRef={inputRef}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </div>
  );
}

MobileNumberAutoComplete.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  options: PropTypes.array,
  setSelectedValue: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  inputRef: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  onInputValueChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string
};

MobileNumberAutoComplete.defaultProps = {
  multiple: false,
  disabled: false
};
