import { Link } from 'gatsby';
import React from 'react';
import StatrysLogo from '../../images/icons/statrysLogo.svg';

const Logo = () => {
  return (
    <Link to={process.env.MARKETING_SITE_URL}>
      <img src={StatrysLogo} alt="Statrys Logo" />
    </Link>
  );
};

export default Logo;
