import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import RegistrationSuccessPage from '../components/companyIncorporation/companyLastStep/RegistrationSuccessPage';
import PublicHeaderNavigation from '../components/Navigation/PublicHeaderNavigation';
import * as CompanyAction from '../components/companyIncorporation/reduxStore/action';
import NavigationBar from '../components/sideBar/NavigationBar';
import { getUser, isLoggedIn } from '../components/authService/auth';
import { SESSION_STORAGE_NAME } from '../constants/enum';
import { getUserCompanies } from '../helper/helpers';

function CompanyIncorporationSuccess({ getCompanyDetails, companyDetails }) {
  const user = getUser();
  async function companyInformation() {
    await getCompanyDetails(sessionStorage.getItem(SESSION_STORAGE_NAME.COMPANY_ID));
  }

  async function refreshCompanyDetails() {
    await getUserCompanies(sessionStorage.getItem('token'), user?.email || companyDetails?.data?.data?.email);
  }
  const isBrowser = typeof window !== 'undefined';
  useEffect(() => {
    companyInformation();
    if (Object.keys(user).length !== 0 || companyDetails?.data?.data?.email) {
      refreshCompanyDetails();
    }
  }, [isBrowser && sessionStorage.getItem(SESSION_STORAGE_NAME.COMPANY_ID)]);
  const MainPage = () => {
    return (
      <div className="bg-white rounded-lg max-h-[520px]">
        <RegistrationSuccessPage />
      </div>
    );
  };

  return (
    <div className="w-full bg-customBg-secondaryBg h-[100dvh]">
      {isLoggedIn() &&
      typeof window !== 'undefined' &&
      sessionStorage.getItem(SESSION_STORAGE_NAME.ALL_COMPANIES) !== null ? (
        <NavigationBar noSideBar={true} children={<MainPage />} />
      ) : (
        <div className="max-w-7xl layout-container pt-4 mx-auto">
          <PublicHeaderNavigation hideSignInButton={true} />
          <main className="flex h-[88dvh] justify-center items-center">
            <MainPage />
          </main>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    companyDetails: state?.companyIncorporationReducer?.getCompanyIncorporation
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyDetails: (companyId) => dispatch(CompanyAction.getCompanyIncorporation(companyId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyIncorporationSuccess);
