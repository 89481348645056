import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';

export const profileRequestStart = () => ({ type: actionTypes.USER_PROFILE_REQUEST_START });
export const profileRequestFail = (error) => ({ type: actionTypes.USER_PROFILE_REQUEST_FAILURE, error: error });
export const profileRequestSuccess = (profilePayload) => ({
  type: actionTypes.USER_PROFILE_REQUEST_SUCCESS,
  profileData: profilePayload
});

export const userProfile = (profilePayload) => {
  return async (dispatch) => {
    try {
      dispatch(profileRequestStart());
      let profileResponse = await Api.put(ENDPOINTS.USER_PROFILE, profilePayload);
      dispatch(profileRequestSuccess(profileResponse?.data));
      return profileResponse;
    } catch (error) {
      dispatch(profileRequestFail(error.response));
      return error?.response;
    }
  };
};

export const getUserProfileRequestStart = () => ({ type: actionTypes.GET_USER_PROFILE_REQUEST_START });
export const getUserProfileRequestFail = (error) => ({
  type: actionTypes.GET_USER_PROFILE_REQUEST_FAILURE,
  error: error
});
export const getUserProfileRequestSuccess = (userProfilePayload) => ({
  type: actionTypes.GET_USER_PROFILE_REQUEST_SUCCESS,
  userProfileData: userProfilePayload
});

export const getUserProfile = () => {
  return async (dispatch) => {
    try {
      dispatch(getUserProfileRequestStart());
      let getUserProfileResponse = await Api.get(ENDPOINTS.COMPANY_USER_PROFILE);
      dispatch(getUserProfileRequestSuccess(getUserProfileResponse?.data));
      return getUserProfileResponse;
    } catch (error) {
      dispatch(getUserProfileRequestFail(error.response));
      return error?.response;
    }
  };
};

export const changePasswordRequestStart = () => ({ type: actionTypes.CHANGE_PASSWORD_REQUEST_START });
export const changePasswordRequestFail = (error) => ({
  type: actionTypes.CHANGE_PASSWORD_REQUEST_FAILURE,
  error: error
});
export const changePasswordRequestSuccess = (payload) => ({
  type: actionTypes.CHANGE_PASSWORD_REQUEST_SUCCESS,
  changePasswordData: payload
});

export const changePassword = (changePasswordPayload) => {
  return async (dispatch) => {
    try {
      dispatch(changePasswordRequestStart());
      let changePasswordResponse = await Api.put(ENDPOINTS.CHANGE_PASSWORD, changePasswordPayload);
      dispatch(changePasswordRequestSuccess(changePasswordResponse?.data));
      return changePasswordResponse;
    } catch (error) {
      dispatch(changePasswordRequestFail(error.response));
      return error?.response;
    }
  };
};

export const currencyPreferenceRequestStart = () => ({ type: actionTypes.CURRENCY_PREFERENCE_REQUEST_START });
export const currencyPreferenceRequestFail = (error) => ({
  type: actionTypes.CURRENCY_PREFERENCE_REQUEST_FAILURE,
  error: error
});
export const currencyPreferenceRequestSuccess = (payload) => ({
  type: actionTypes.CURRENCY_PREFERENCE_REQUEST_SUCCESS,
  currencyPreferenceData: payload
});

export const currencyPreference = (currencyPreferencePayload) => {
  return async (dispatch) => {
    try {
      dispatch(currencyPreferenceRequestStart());
      let currencyPreferenceResponse = await Api.post(ENDPOINTS.CURRENCY_PREFERENCE, currencyPreferencePayload);
      dispatch(currencyPreferenceRequestSuccess(currencyPreferenceResponse?.data));
      return currencyPreferenceResponse;
    } catch (error) {
      dispatch(currencyPreferenceRequestFail(error.response));
      return error?.response;
    }
  };
};

export const getCurrencyPreferenceRequestStart = () => ({ type: actionTypes.GET_CURRENCY_PREFERENCE_REQUEST_START });
export const getCurrencyPreferenceRequestFail = (error) => ({
  type: actionTypes.GET_CURRENCY_PREFERENCE_REQUEST_FAILURE,
  error: error
});
export const getCurrencyPreferenceRequestSuccess = (payload) => ({
  type: actionTypes.GET_CURRENCY_PREFERENCE_REQUEST_SUCCESS,
  getCurrencyPreferenceData: payload
});

export const getCurrencyPreference = (getCurrencyPreferencePayload) => {
  return async (dispatch) => {
    try {
      dispatch(getCurrencyPreferenceRequestStart());
      let getCurrencyPreferenceResponse = await Api.get(ENDPOINTS.CURRENCY_PREFERENCE, getCurrencyPreferencePayload);
      dispatch(getCurrencyPreferenceRequestSuccess(getCurrencyPreferenceResponse?.data));
      return getCurrencyPreferenceResponse;
    } catch (error) {
      dispatch(getCurrencyPreferenceRequestFail(error.response));
      return error?.response;
    }
  };
};
