import React from 'react';
import TrustPilotLogo from '../../images/icons/trustpilot-logo.svg';
import TrustPilotStars from '../../images/icons/trustpilot-four-star.png';
import PropTypes from 'prop-types';

const TrustPilotReviewLine = ({ className }) => {
  return (
    <a
      href="https://www.trustpilot.com/review/statrys.com"
      target="_blank"
      className={`md:px-0 px-6 ${className}`}
      rel="noopener noreferrer"
    >
      <div className="flex flex-wrap md:gap-4 gap-2 items-center md:justify-start justify-center">
        <img src={TrustPilotLogo} alt="trustpilot logo" width="91" height="23" loading="eager" />
        <div className="vl"></div>
        <div className="mt-1 text-bold text-base">Rated Excellent</div>
        <img src={TrustPilotStars} alt="trust pilot stars" width="119" height="23" loading="eager" />
      </div>
    </a>
  );
};
TrustPilotReviewLine.propTypes = {
  className: PropTypes.string
};

export default TrustPilotReviewLine;
