import React, { useState } from 'react';
import Prices from './Prices';
import WhatsIncludedAccordion from '../common/WhatsIncludedAccordian';
import { Link } from 'gatsby';
import InfoPopUp from '../ui/InfoPopUp';
import { MUI_COLORS } from '../../constants/enum';
import BaseText from '../ui/typography/BaseText';
import DiscountGreenImage from '../../images/graphics/registerCompany/specialDiscountGreen.webp';

const info = 'Subject to approval';

const whatsIncludedData = [
  {
    title: 'Incorporation Services',
    description: (
      <span className="leading-6">
        <ul className="list-disc ml-4">
          <li>Verification of the company name availability</li>
          <li>Preparation and filing of incorporation documents</li>
          <li> Articles of Association</li>
          <li>Government fees (HKD3,970 - included in our price)</li>
          <li>Certificate of Incorporation</li>
          <li>
            Business Registration Certificate (for 1 year){' '}
            <Link to={12} key={12} spy={true} smooth={true} offset={-90} duration={500} className="text-bold">
              Annual Renewal
            </Link>
          </li>
          <li>Company chops (corporate seals)</li>
          <li>24/7 access to company documents on our online platform</li>
        </ul>
      </span>
    )
  },
  {
    title: 'Company Secretary Services',
    description: (
      <span className="leading-6">
        <ul className="list-disc ml-4">
          <li>Provision of a Secretary Company (1 year)</li>
          <li>Provision of a Designated Representative (1 year) </li>
          <li>Filing of Annual Return (NAR1)</li>
          <li>Automated reminders of filing deadlines</li>
          <li>Maintenance of the significant controller register</li>
          <li>Maintenance of the statutory records</li>
          <li>Preparation of the Annual General Meeting (AGM)</li>
          <li>All of your statutory records and compliance documents in one place, accessible online 24/7</li>
        </ul>
      </span>
    )
  },
  {
    title: 'Registered Address in Hong Kong',
    description: (
      <span className="leading-6">
        <ul className="list-disc ml-4">
          <li>
            Registered address for one year{' '}
            <Link to={12} key={12} spy={true} smooth={true} offset={-90} duration={500} className="text-bold">
              Annual Renewal
            </Link>
          </li>
          <li>Scanning and forwarding of mail</li>
        </ul>
      </span>
    )
  },
  {
    title: (
      <>
        <div className="flex flex-row gap-2 items-center">
          <h4 className="text-xl">Business Account</h4>
          <InfoPopUp
            popUpClass={info}
            info="Subject to approval"
            infoIconColor={MUI_COLORS.GREEN}
            iconWidth={15}
            iconHeight={15}
          />
        </div>
        <BaseText className="italic md:hidden block header-info-text" title={`(${info})`} />
      </>
    ),
    description: (
      <span className="leading-6">
        <ul className="list-disc ml-4">
          <li>Assistance with online business account opening</li>
          <li>Certification of documents for business account opening</li>
        </ul>
      </span>
    )
  }
];

const OnePackage = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="flex md:flex-row flex-col gap-8 w-full mt-20">
      <div className="flex flex-col gap gap-4 rounded-lg md:w-1/2 one-time-payment-main-container w-full">
        <div className="one-time-payment-container bg-white">
          <div className="flex md:flex-row flex-col-reverse md:px-8 px-4 md:pt-10 pt-4 justify-between">
            <div className="flex flex-col gap-6">
              <h2 className="offer-and-price-section md:w-[90%] w-full md:whitespace-pre-line text-bold">
                One package, One price All Included
              </h2>
              <Prices DiscountImage={DiscountGreenImage} />
            </div>
          </div>
          <div className="bg-yellow-300 w-full rounded-b-2xl mt-10">
            <div className="flex flex-col gap gap-2 py-4 px-8 text text-xl letter-spacing-p leading-5">
              <div className="text-bold pb-1">
                Save <span className="text-green-500 text-bold">HKD1,056</span> per year on <br />
                your Statrys Business Account
              </div>
              Monthly fee waived as long as you use <br className="md:block hidden" /> our company secretary services.
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-1/2 w-[100%] rounded-lg">
        <div className="flex flex-col">
          <h2 className="text-2xl whitespace-pre-line mb-4 text-blue-800 tracking-tighter text-bold">
            What’s included:
          </h2>
          {whatsIncludedData?.map((data, id) => {
            return (
              <WhatsIncludedAccordion
                isLast={whatsIncludedData.length === id + 1}
                key={id + 1}
                isOpen={isOpen === id + 1}
                onClick={() => setIsOpen(isOpen === id + 1 ? false : id + 1)}
                title={data?.title}
                description={data?.description}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OnePackage;
