import React, { useEffect } from 'react';
import StatrysIcon from '../../../images/icons/statrys-logo.svg';

const ParentLoader = () => {
  useEffect(() => {
    setTimeout(function () {
      document.getElementById('___loader').style.display = 'none';
    }, 70);
  }, []);
  return (
    <div
      key={`loader`}
      id="___loader"
      style={{
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999999,
        width: '100vw',
        height: '100vh'
      }}
    >
      <div className="loader mx-auto">
        <svg viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <circle className="load three" cx="60" cy="60" r="40" strokeLinecap="round" />
          <circle className="load two" cx="60" cy="60" r="40" strokeLinecap="round" />
          <circle className="load one" cx="60" cy="60" r="40" strokeLinecap="round" />
          <g></g>
        </svg>
        <img src={StatrysIcon} alt="statrys icon" style={{ position: 'absolute', width: '40px' }} />
      </div>
    </div>
  );
};

export default ParentLoader;
