export const NEW_COMPANY_REGISTRATION = 'NEW_COMPANY_REGISTRATION';
export const COMPLETED_COMPANY_REGISTRATION = 'COMPLETED_COMPANY_REGISTRATION';
export const ADD_STAKEHOLDER_DATA = 'ADD_STAKEHOLDER_DATA';
export const STAKEHOLDER_EDIT_DETAILS = 'STAKEHOLDER_EDIT_DETAILS';
export const STAKEHOLDER_DETAILS_REMOVE = 'STAKEHOLDER_DETAILS_REMOVE';
export const ADD_ALL_STAKEHOLDER = 'ADD_ALL_STAKEHOLDER';

export const COMPANY_CREATE_REQUEST_START = 'COMPANY_CREATE_REQUEST_START';
export const COMPANY_CREATE_REQUEST_SUCCESS = 'COMPANY_CREATE_REQUEST_SUCCESS';
export const COMPANY_CREATE_REQUEST_FAILURE = 'COMPANY_CREATE_REQUEST_FAILURE';

export const COMPANY_UPDATE_REQUEST_START = 'COMPANY_UPDATE_REQUEST_START';
export const COMPANY_UPDATE_REQUEST_SUCCESS = 'COMPANY_UPDATE_REQUEST_SUCCESS';
export const COMPANY_UPDATE_REQUEST_FAILURE = 'COMPANY_UPDATE_REQUEST_FAILURE';

export const COMPANY_DETAILS_REQUEST_START = 'COMPANY_DETAILS_REQUEST_START';
export const COMPANY_DETAILS_REQUEST_SUCCESS = 'COMPANY_DETAILS_REQUEST_SUCCESS';
export const COMPANY_DETAILS_REQUEST_FAILURE = 'COMPANY_DETAILS_REQUEST_FAILURE';

export const UPLOAD_FILE_REQUEST_START = 'UPLOAD_FILE_REQUEST_START';
export const UPLOAD_FILE_REQUEST_SUCCESS = 'UPLOAD_FILE_REQUEST_SUCCESS';
export const UPLOAD_FILE_REQUEST_FAILURE = 'UPLOAD_FILE_REQUEST_FAILURE';

export const PAYMENT_REQUEST_START = 'PAYMENT_REQUEST_START';
export const PAYMENT_REQUEST_SUCCESS = 'PAYMENT_REQUEST_SUCCESS';
export const PAYMENT_REQUEST_FAILURE = 'PAYMENT_REQUEST_FAILURE';

export const DELETE_STAKHOLDER_REQUEST_START = 'DELETE_STAKHOLDER_REQUEST_START';
export const DELETE_STAKHOLDER_SUCCESS = 'DELETE_STAKHOLDER_SUCCESS';
export const DELETE_STAKHOLDER_FAILURE = 'DELETE_STAKHOLDER_FAILURE';

export const EXISTING_COMPANY_REGISTRATION_REQUEST_START = 'EXISTING_COMPANY_REGISTRATION_REQUEST_START';
export const EXISTING_COMPANY_REGISTRATION_SUCCESS = 'EXISTING_COMPANY_REGISTRATION_SUCCESS';
export const EXISTING_COMPANY_REGISTRATION_FAILURE = 'EXISTING_COMPANY_REGISTRATION_FAILURE';
