import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Profile from '../../components/company/Profile';
import PropTypes from 'prop-types';
import * as CompanyDetailsActions from '../../components/company/reduxStore/action';
import { API_CODE, COMPANY_STATUS, PAGE_TITLE } from '../../constants/enum';
import Page from '../../components/Page';
import Layout from '../../components/layout';
import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../routes/paths';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Organization from '../../components/company/organization/Organization';
import CompanySecretary from '../../components/company/companySecretary/CompanySecretary';

function CompanyDocuments({ companyStackHoldersDetails, companyProfileDetails }) {
  const [companyId, setCompanyId] = useState();

  const companyRegistartion = async () => {
    const companyProfileResponse = await companyProfileDetails();
    if (companyProfileResponse?.status === API_CODE.STATUS_200) {
      setCompanyId(companyProfileResponse?.data?.data?._id);
    }
  };

  useEffect(() => {
    companyRegistartion();
  }, []);
  return (
    <Layout>
      <Page title="Company" className="w-full">
        <HeaderBreadcrumbs
          heading={PAGE_TITLE.COMPANY_DOCUMENTS}
          links={[{ name: '' }]}
          sx={{ height: '56px', display: 'flex', alignItems: 'center', marginBottom: '32px' }}
        />

        <div className="w-full">
          <CompanySecretary companyId={companyId} />
        </div>
      </Page>
    </Layout>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    companyProfileDetails: () => dispatch(CompanyDetailsActions.companyProfileDetails())
  };
};

export default connect(null, mapDispatchToProps)(CompanyDocuments);

CompanyDocuments.propTypes = {
  companyProfileDetails: PropTypes.func
};
