import React, { useState } from 'react';
import H2HeaderText from '../ui/typography/H2HeaderText';
import H4HeaderText from '../ui/typography/H4HeaderText';
import NumberedAccordian from '../../components/common/NumberedAccordian';
import ProfessionsManDoctorIcon from '../../images/icons/inline/professions-man-doctor.inline.svg';
import CheckListIcon from '../../images/icons/inline/checklist.inline.svg';
import CursorChooseIcon from '../../images/icons/inline/cursor-choose.inline.svg';
import BaseText from '../ui/typography/BaseText';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import XMSText from '../ui/typography/XMSText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { ENDPOINTS } from '../../service/end-points';
import PropTypes from 'prop-types';
import InfoPopUp from '../ui/InfoPopUp';
import { MUI_COLORS } from '../../constants/enum';
import { StaticImage } from 'gatsby-plugin-image';
import { PATH_PAGE } from '../../routes/paths';
const info = 'Subject to requirements as per applicable regulations';
const whatsIncludedData = [
  {
    title: 'Apply with us online for your company registration',
    description: (
      <div>
        <BaseText
          className="md:whitespace-pre-line"
          title={`Quick 5-minute application - Just give us the details needed to meet \nHong Kong's local company registration laws.`}
        />
        <BaseText
          className="md:whitespace-pre-line mt-2"
          title={`We prepare the necessary documents and send them to you for \nelectronic signature.`}
          fontWeight="text-bold"
        />
        <PrimaryButton
          className="mt-6 five-steps-get-started-btn"
          isBorderRequired={true}
          caption="Get started here"
          textColor="text-white"
          bgColor="bg-green-500"
          path={PATH_PAGE.hkIncorporation}
          urlLink={ENDPOINTS?.HONG_KONG_COMPANY_REGISTRATION}
        />
      </div>
    )
  },
  {
    title: 'Submission of the application',
    description: (
      <BaseText
        className="md:whitespace-pre-line"
        title={`We submit the application documents to the Hong Kong Company Registry. We actively monitor progress to ensure timely processing.`}
      />
    )
  },
  {
    title: 'Your company is registered! 🎉',
    description: (
      <div>
        <BaseText
          className="md:whitespace-pre-line"
          title={`We gather all the official documents confirming your new Hong Kong company's registration.`}
        />
        <BaseText
          className="md:whitespace-pre-line mt-2"
          title={`This includes the Certificate of Incorporation from the Hong Kong Company Registry and the Business Registration Certificate from the Inland Revenue Department.`}
        />
      </div>
    )
  },
  {
    title: 'Open a business account',
    description: (
      <>
        <BaseText
          className="md:whitespace-pre-line"
          title={
            <div className="flex flex-row gap-2 items-center relative">
              We assist you in opening a business account with Statrys, all done remotely and within just 48 hours.
              <InfoPopUp
                iconContainerClass="open-business-icon"
                popUpClass="open-business"
                info={info}
                infoIconColor={MUI_COLORS.GREEN}
                iconWidth={15}
                iconHeight={15}
              />
            </div>
          }
        />
        <BaseText className="italic md:hidden block header-info-text" title={`(${info})`} />
      </>
    )
  },
  {
    title: 'Save money on your accounting',
    description: (
      <div>
        <BaseText
          className="md:whitespace-pre-line"
          title={`Tell us your accounting needs, and we'll get price quotes from various Hong Kong accounting firms for you. Pick the one that best fits your budget.`}
        />
      </div>
    )
  }
];

const AccountingComponent = ({ className }) => {
  return (
    <div className={`flex md:flex-row flex-col mt-auto relative ${className}`}>
      <div className="md:mt-auto mt-2">
        <MDSubtitleText
          className="whitespace-pre-line"
          fontWeight="text-regular"
          title={`“Don’t rush when deciding \non accounting and tax. Wait \na few months if you want to \nmake the right decision.`}
        />
        <MDSubtitleText textColor="text-green-500" fontWeight="text-bold" title="That will save you money”" />
        <BaseText className="mt-6" fontWeight="text-bold" title="Nestor Garcia" />
        <XMSText className="mb-6" title="Head of Company Creation Services" />
      </div>
      <StaticImage
        src={'../../images/graphics/registerCompany/nestor-tax.png'}
        className="nestor-tax-img block"
        alt="NestorTaxImg"
      />
    </div>
  );
};
export default function FiveSteps() {
  const [isOpen, setIsOpen] = useState(1);

  return (
    <div>
      <div className="mt-28 flex md:flex-row flex-col justify-between w-full">
        <div className="flex items-end flex-col flex-nowrap justify-between">
          <H2HeaderText
            className="w-full md:whitespace-pre-line"
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: `<span class="text-green-500">5 steps </span> to start \nyour business \nin Hong Kong`
                }}
              ></div>
            }
          />
          <StaticImage
            src={'../../images/graphics/registerCompany/company-reg-chalisa.png'}
            className="companyRegChalisaImg mt-24"
            alt="company-reg-chalisa-img"
            width={317}
            height={539}
          />
        </div>
        <div className="md:w-[688px] w-full mb-16">
          {whatsIncludedData?.map((data, id) => {
            return (
              <NumberedAccordian
                key={id}
                isLast={whatsIncludedData.length === id + 1}
                number={id + 1}
                isOpen={isOpen === id + 1}
                onClick={() => setIsOpen(isOpen === id + 1 ? false : id + 1)}
                title={data?.title}
                description={data?.description}
              />
            );
          })}
        </div>
      </div>
      <div className="bg-yellow-300 rounded-lg md:py-8 py-6 md:px-6 px-4 five-steps-container flex md:flex-row flex-col md:gap-20 gap-4 justify-between">
        <div className="flex flex-col items-stretch">
          <div>
            <H2HeaderText title={`Accounting \nand Tax`} className="md:whitespace-normal whitespace-pre-line" />
            <H4HeaderText
              className="mt-2"
              fontWeight="text-regular"
              title={
                <div>
                  We help you <span className="text-bold">save money</span> with an accounting plan matching your needs:
                </div>
              }
            />
          </div>
          <AccountingComponent className="md:block hidden" />
        </div>
        <div className="flex flex-col md:gap-12 gap-6 md:w-10/12 w-full">
          <div className="flex md:gap-4 gap-2 md:justify-end justify-center">
            <ProfessionsManDoctorIcon width={24} height={24} />
            <div className="w-4/5">
              <H4HeaderText title="Get expert accounting support" />
              <BaseText title="We have built a trusted network of accounting firms, rigorously vetted to ensure the highest quality of service for our clients." />
            </div>
          </div>
          <div className="flex gap-4 md:justify-end justify-center">
            <CheckListIcon width={24} height={24} />
            <div className="w-4/5">
              <H4HeaderText className="accounting-points-heading" title="Receive quotes from approved accountants" />
              <BaseText title='If you choose "Yes" to our accounting assistance, we will confidentially submit your request to the selected accounting firms and request quotations on an anonymous basis' />
            </div>
          </div>
          <div className="flex gap-4 md:justify-end justify-center">
            <CursorChooseIcon width={24} height={24} />
            <div className="w-4/5">
              <H4HeaderText title="Choose the best option for your needs" />
              <BaseText title="We will present you with the top three competitive quotes, allowing you to make an informed decision." />
            </div>
          </div>
          <AccountingComponent className="md:hidden block" />
        </div>
      </div>
    </div>
  );
}

AccountingComponent.propTypes = {
  className: PropTypes.string
};
