import React from 'react'
import "../../../styles/statrys-lucky-spinner.scss"
import StatrysIcon from "../../../images/icons/statrys-icon.svg"
export default function StatrysLucky() {
    return (
        <div className="loader">
            <svg viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <circle className="load three" cx="60" cy="60" r="40" strokeLinecap="round" />
                <circle className="load two" cx="60" cy="60" r="40" strokeLinecap="round" />
                <circle className="load one" cx="60" cy="60" r="40" strokeLinecap="round" />
                <g>
                </g>
            </svg>
            <img src={StatrysIcon} alt="statrys icon" className="absolute w-10" />
        </div>
    )
}
