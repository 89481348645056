import React, { useEffect, useState } from 'react';
import BackButton from '../../ui/buttons/BackButton';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import XSText from '../../ui/typography/XSText';
import CustomCheckBox from '../../common/CustomCheckBox';
import industries from '../../../constants/industries.json';
import { useSelector, connect } from 'react-redux';
import * as CompanyAction from '../../../components/companyIncorporation/reduxStore/action';
import { API_CODE, COMPANY_SETUP_STEP } from '../../../constants/enum';

const Industry = ({ nextSubStep, prevSubStep, prevStep, nextStep, updateCompanyIncorporation }) => {
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [subIndustries, setSubIndustries] = useState([]);
  const companyAllInformation = useSelector(
    (state) => state?.companyIncorporationReducer?.getCompanyIncorporation?.data?.data || {}
  );
  const continueClick = async () => {
    if (selectedIndustries) {
      let payload = {
        subIndustries: selectedIndustries,
        companyId: companyAllInformation?._id,
        completedStep: COMPANY_SETUP_STEP?.BUSINESS_DESCRIPTION
      };
      const response = await updateCompanyIncorporation(payload);
      if (response?.status === API_CODE?.STATUS_200) {
        nextSubStep();
        nextStep();
        sessionStorage.setItem('step', COMPANY_SETUP_STEP?.INDUSTRY);
      }
    }
  };

  useEffect(() => {
    const getSubIndustries = industries.filter((industry) => industry?.name === companyAllInformation?.industries?.[0]);
    if (getSubIndustries.length === 0) {
      nextSubStep();
      nextStep();
    } else {
      setSubIndustries(getSubIndustries?.[0]?.subTag);
      setSelectedIndustries(companyAllInformation?.subIndustries);
    }
  }, [companyAllInformation?.industries]);

  const onBack = () => {
    prevSubStep();
    prevStep();
  };

  const selectIndustry = (industry) => {
    const isAlreadySelected = selectedIndustries?.find((industries) => industries?.code === industry?.code);
    if (!isAlreadySelected) {
      setSelectedIndustries([...selectedIndustries, industry]);
    } else {
      const toggle = selectedIndustries.filter((industries) => industries.code !== industry?.code);
      setSelectedIndustries(toggle);
    }
  };

  return (
    <div className="flex flex-col gap-8 w-full justify-between main-wrapper-container ">
      <MDSubtitleText title="Industry" fontWeight="text-bold" />
      <div className="flex flex-col gap-4">
        <XSText
          className="md:whitespace-pre-line"
          title={
            <div
              dangerouslySetInnerHTML={{
                __html: `Choose the category or categories below that best describe your company's activity`
              }}
            ></div>
          }
        />
        <div className="flex flex-wrap gap-2">
          {subIndustries?.map((industry, index) => {
            return (
              <CustomCheckBox
                selected={selectedIndustries?.find((industries) => industries?.code === industry?.code)}
                key={index}
                onClick={() => selectIndustry(industry)}
                label={industry?.name}
              />
            );
          })}
        </div>
      </div>

      <div className="flex flex-row justify-between w-full">
        <div className="flex md:flex-row flex-col-reverse justify-between w-full">
          <BackButton onClick={onBack} className="md:w-[114px] w-full" />
          <PrimaryButton
            caption="Continue"
            color="text-white"
            className="w-full"
            isBorderRequired={true}
            onClick={continueClick}
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCompanyIncorporation: (payload) => dispatch(CompanyAction.updateCompanyIncorporation(payload))
  };
};

export default connect(null, mapDispatchToProps)(Industry);
