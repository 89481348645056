import React from 'react';
import { PRODUCT_PAGES_URL } from '../../../constants/enum';
import XSText from '../../ui/typography/XSText';

export default function TermCondition() {
  return (
    <>
      <div className="flex flex-row items-center mt-6">
        <XSText
          className="text-center"
          title={
            <div>
              By purchasing the package you agree to our{' '}
              <a href="/terms-and-conditions" className="text-bold text-coral-500" target="_blank" rel="noreferrer">
                Terms and Conditions,{' '}
              </a>
              and{' '}
              <a
                href={PRODUCT_PAGES_URL?.PRIVACY_POLICY_URL}
                className=" text-bold text-coral-500"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy.
              </a>
            </div>
          }
        />
      </div>
    </>
  );
}
