import React, { useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import PropTypes from 'prop-types';
import { COMPANY_REGISTRATION_COUNTRY, FEES } from '../../../constants/enum';

const PaypalCheckoutButton = ({ onPayPalClick, onSuccessfulPayPalPayment, onPayPalDecline, registrationCountry }) => {
  const product = {
    description: 'Company Registration',
    price:
      registrationCountry === COMPANY_REGISTRATION_COUNTRY.HONG_KONG ? FEES?.FOR_PAY_PAL : FEES?.SINGAPORE_FOR_PAY_PAL
  };

  const [error, setError] = useState(null);

  if (error) {
    alert(error);
  }
  return (
    <PayPalScriptProvider
      options={{
        'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: registrationCountry === COMPANY_REGISTRATION_COUNTRY.HONG_KONG ? 'HKD' : 'SGD'
      }}
    >
      <PayPalButtons
        style={{
          layout: 'horizontal',
          height: 48,
          tagline: false,
          shape: 'rect',
          borderRadius: 8
        }}
        onClick={() => onPayPalClick()}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: product.description,
                amount: {
                  value: product.price
                }
              }
            ],
            application_context: {
              shipping_preference: 'NO_SHIPPING'
            }
          });
        }}
        onApprove={async (data, action) => {
          const order = await action.order.capture();
          await onSuccessfulPayPalPayment(order);
        }}
        onCancel={() => onPayPalDecline()}
        onError={(err) => {
          onPayPalDecline();
          setError(err);
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PaypalCheckoutButton;

PaypalCheckoutButton.propTypes = {
  onPayPalClick: PropTypes.func,
  onSuccessfulPayPalPayment: PropTypes.func,
  phoneNumberWithCountryCode: PropTypes.string,
  onPayPalDecline: PropTypes.func,
  height: PropTypes.number,
  registrationCountry: PropTypes.string
};
