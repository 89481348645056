import React from 'react';
import H3HeaderText from '../ui/typography/H3HeaderText';
import '../../styles/components/floating-cta.scss';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import MSText from '../ui/typography/MSText';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';

export default function FloatingCta({
  title,
  subtitle,
  imageUrl,
  buttonCaption,
  buttonLink,
  borderRadius,
  border,
  backgroundColor,
  primaryTextColor,
  secondaryTextColor,
  buttonBackgroundColor,
  buttonCaptionTextColor,
  bgColor,
  heading,
  buttonClass,
  contentPadding,
  path
}) {
  return (
    <Link to={buttonLink} className={`hover:no-underline`}>
      <div
        id="floating-cta"
        className={`${backgroundColor} ${borderRadius} ${border} border-gray-200 floating-cta-container overflow-hidden pt-${contentPadding}`}
      >
        <div className={`px-${contentPadding}`}>
          <H3HeaderText fontWeight="text-light" title={title} textColor={primaryTextColor} />
          <PrimaryButton
            className={`${buttonClass} w-full`}
            caption={buttonCaption}
            type={true}
            bgColor={buttonBackgroundColor}
            color={buttonCaptionTextColor}
            isBorderRequired={true}
            style={{ backgroundColor: heading, color: bgColor }}
            urlLink={buttonLink}
            path={path}
          />

          <MSText className="pt-2 mb-6" title={subtitle} textColor={`${secondaryTextColor}`} />
        </div>
        {imageUrl && (
          <GatsbyImageWrapper
            className="h-full w-full pl-4 -mb-8 floating-cta-faq-img"
            image={{
              url: imageUrl,
              height: 290,
              width: 290
            }}
            alt={title}
            loading="lazy"
            width="290"
            height="290"
          />
        )}
      </div>
    </Link>
  );
}

FloatingCta.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imageUrl: PropTypes.any,
  buttonCaption: PropTypes.string,
  buttonLink: PropTypes.string,
  borderRadius: PropTypes.string,
  border: PropTypes.string,
  backgroundColor: PropTypes.string,
  primaryTextColor: PropTypes.string,
  secondaryTextColor: PropTypes.string,
  buttonBackgroundColor: PropTypes.string,
  buttonCaptionTextColor: PropTypes.string,
  bgColor: PropTypes.string,
  heading: PropTypes.string,
  fromCompanyCreationProductPage: PropTypes.bool,
  buttonClass: PropTypes.string,
  contentPadding: PropTypes.string
};

FloatingCta.defaultProps = {
  borderRadius: 'rounded-lg',
  border: 'border',
  backgroundColor: 'bg-coral-500',
  primaryTextColor: 'text-white',
  secondaryTextColor: 'text-salmon-500',
  buttonBackgroundColor: 'bg-white',
  buttonCaptionTextColor: 'text-coral-500',
  fromCompanyCreationProductPage: false,
  buttonClass: 'floating-cta-button',
  contentPadding: '8',
  bgColor: 'bg-green-500'
};
