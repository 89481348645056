import React, { useState } from 'react';
import InformtionCircle from '../../images/icons/customSvg/informationCircle';
import XXSText from './typography/XXSText';
import PropTypes from 'prop-types';
import { MUI_COLORS } from '../../constants/enum';
import BaseText from './typography/BaseText';
import '../../styles/components/info-pop-up.scss';

export default function InfoPopUp({
  info,
  popUpClass,
  infoIconColor,
  iconWidth,
  iconheight,
  children,
  alwaysShow,
  iconContainerClass,
  showTextInMobileView
}) {
  const [handleLink, setHandleLink] = useState(false);

  return (
    <>
      <div className={`${iconContainerClass} 'h-full items-center flex'`}>
        {handleLink ? (
          <div className={`rounded-lg w-96 absolute p-6  ${popUpClass} shadow-2xl bg-white `}>
            <XXSText title={<div dangerouslySetInnerHTML={{ __html: info }}></div>} />
            <div className=" absolute md:w-full left-0 right-0 mx-auto mt-5 triangle-down"></div>
          </div>
        ) : null}
        {showTextInMobileView && (
          <BaseText
            className="md:hidden block"
            textColor="text-coral-500"
            fontSize="text-base"
            title={<div dangerouslySetInnerHTML={{ __html: info }}></div>}
          />
        )}

        <div
          onMouseOver={() => setHandleLink(true)}
          onMouseLeave={() => setHandleLink(false)}
          className={`${children ? 'ml-0' : 'ml-1'} ${
            alwaysShow ? 'block' : 'hidden'
          }  md:block rounded-full cursor-pointer`}
        >
          {children ? children : <InformtionCircle stroke={infoIconColor} width={iconWidth} height={iconheight} />}
        </div>
      </div>
    </>
  );
}

InfoPopUp.propTypes = {
  info: PropTypes.string,
  popUpClass: PropTypes.string,
  LinkTitle: PropTypes.string,

  alwaysShow: PropTypes.string,
  infoIconColor: PropTypes.string,
  iconWidth: PropTypes.number,
  iconheight: PropTypes.number,
  children: PropTypes.any,
  iconContainerClass: PropTypes.any,
  showTextInMobileView: PropTypes.bool
};
InfoPopUp.defaultProps = {
  infoIconColor: MUI_COLORS.CORAL,
  showTextInMobileView: false
};
