import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/components/dropdown.scss';
import XSText from '../ui/typography/XSText';

export default function DropDown({ options, className, selectOption }) {
  return (
    <div className="-mt-2 md:-ml-3 -ml-40 h-24">
      <div className="arrow-up mt-3 md:ml-4 ml-44"></div>
      <div className={`bg-white dropdown-main-container ${className}`}>
        <div className="flex flex-col p-2">
          {options?.map((option, index) => {
            return (
              <div onClick={() => selectOption(option)} key={index}>
                <XSText
                  title={option?.title}
                  className="py-2 px-5 dropdown-title cursor-pointer rounded-lg"
                  fontWeight="text-medium"
                  textColor="text-blue-800"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

DropDown.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
  selectOption: PropTypes.func
};
DropDown.defaultProps = {
  options: []
};
