import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { API_CODE } from '../../../constants/enum';
import { handleEnter } from '../../../helper/helpers';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import SelectInputAutoComplete from '../../ui/inputs/selectInputAutoComplete';
import TextInput from '../../ui/inputs/TextInput';
import MSText from '../../ui/typography/MSText';
import XSText from '../../ui/typography/XSText';
import ValidationMessage from '../../ui/ValidationMessage';
import UploadFile from '../common/UploadFile';
import PropTypes from 'prop-types';
import * as commonReduxActions from '../../commonReduxStore/reduxStore/action';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import BackButton from '../../ui/buttons/BackButton';
import countries from '../../../constants/countries.json';
import EditOwnerDirectorDetails from './EditOwnerDetails';

const ownerDirectorData = {
  firstName: 'Sunil',
  lastName: 'Prajapati',
  type: 'OWNER_DIRECTOR',
  code: '---'
};

function RecidentialAddress({
  uploadFile,
  prevStep,
  nextStep,
  isAddingOwner,
  isOwnerDirectorEdit,
  currentStackHolder,
  setCurrentStackHolder
}) {
  const [residence, setResidence] = useState(currentStackHolder?.countryOfResidence);
  const [correspondenceResidence, setCorrespondenceResidence] = useState(
    currentStackHolder?.correspondenceIncorporationAddress?.residence
      ? currentStackHolder?.correspondenceIncorporationAddress?.residence
      : ''
  );
  const { register, handleSubmit, errors } = useForm();
  const [error, setError] = useState({
    residenceError: '',
    correspondenceResidenceError: '',
    proofOfAddressError: false,
    isAddressSameOrNotClicked: false
  });
  const [residentialAddress, setResidentialAddress] = useState('');
  const [saveImage, setSaveImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(
    currentStackHolder?.proofOfAddress
      ? [
          {
            preview: currentStackHolder?.proofOfAddress?.documentUrl,
            name: currentStackHolder?.proofOfAddress?.documentName,
            type: currentStackHolder?.proofOfAddress?.type
          }
        ]
      : ''
  );
  const [uploadError, setUploadError] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState('');
  const [proofOfAddressImg, setProofOfAddressImg] = useState(currentStackHolder?.proofOfAddress);

  const back = () => {
    prevStep();
  };
  const onSubmit = (data) => {
    if (!residence) {
      setError({ ...error, residenceError: true });
    } else if (!selectedImage) {
      setError({ ...error, proofOfAddressError: true });
    } else if (!residentialAddress) {
      setError({ ...error, isAddressSameOrNotClicked: true });
    } else {
      setError({ ...error, proofOfAddressError: false });
      setCurrentStackHolder({
        ...currentStackHolder,
        address: data?.address,
        city: data?.city,
        postalCode: data?.postalCode,
        countryOfResidence: residence,
        proofOfAddress: proofOfAddressImg,
        isAddressSameAsResidentialAddress: residentialAddress === 'no' ? false : true,
        correspondenceIncorporationAddress: {
          city: residentialAddress === 'yes' ? data?.city : data?.correspondenceCity,
          postalCode: residentialAddress === 'yes' ? data?.postalCode : data?.correspondencePostalCode,
          residence: residentialAddress === 'yes' ? residence : correspondenceResidence,
          address: residentialAddress === 'yes' ? data?.address : data?.correspondenceAddress
        }
      });
      nextStep();
    }
  };

  const fileSelection = async (acceptedFiles) => {
    setAcceptedFile(acceptedFiles);
    let selectedimg = acceptedFiles?.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );
    setSelectedImage(selectedimg);
    const formData = new FormData();
    formData.append('file', selectedimg[0]);
    const uploadDocument = await uploadFile(formData);
    if (uploadDocument?.status === API_CODE.STATUS_200) {
      let addressImgPayload = {
        documentUrl: uploadDocument?.data?.data?.file,
        documentName: acceptedFiles?.[0]?.name,
        DocumentType: 'Proof of address ',
        isAvailableOnCompanyProfile: true,
        type: acceptedFiles?.[0]?.type
      };
      setProofOfAddressImg(addressImgPayload);
    }
    setSaveImage(true);
  };

  const handleRemoveFile = () => {
    setSelectedImage('');
    setAcceptedFile('');
  };

  const chooseResidential = (option) => {
    setResidentialAddress(option);
    setError({ ...error, isAddressSameOrNotClicked: false });
  };
  return (
    <div className="flex flex-col md:gap-8 gap-6 w-full h-full">
      <MDSubtitleText title="Owners and directors" fontWeight="text-bold" />
      {isOwnerDirectorEdit && (
        <EditOwnerDirectorDetails
          firstName={ownerDirectorData.firstName}
          lastName={ownerDirectorData.lastName}
          type={ownerDirectorData.type}
          cardCode={ownerDirectorData.code}
        />
      )}
      <div className="flex md:flex-row flex-col whitespace-pre-line md:mt-6 mt-0">
        <div className="md:w-4/6 w-full mr-14">
          <XSText className="mb-2 " title="Residential address" />
          <form id="residential-form" onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              onKeyDown={handleEnter}
              defaultValue={currentStackHolder?.address}
              name="address"
              label="Address"
              placeholder="e.g. Parc de Montjuïc, s/n, 08038 Barcelona, Spain"
              className="w-full"
              inputRef={register({
                required: true
              })}
              error={errors.address ? true : false}
              helperText={errors.address && <ValidationMessage title="Please fill in your address" />}
            />
            <div className="flex flex-col md:flex-row gap-4 mt-2">
              <TextInput
                onKeyDown={handleEnter}
                name="city"
                label="City"
                textFieldContainerClass="w-full"
                defaultValue={currentStackHolder?.city}
                inputRef={register({
                  required: {
                    value: true,
                    message: 'Please fill in a valid City'
                  }
                })}
                error={errors.city ? true : false}
                helperText={errors.city ? <ValidationMessage title={errors.city.message} /> : null}
              />
              <TextInput
                onKeyDown={handleEnter}
                name="postalCode"
                label="Postcode (Optional)"
                textFieldContainerClass="w-full"
                defaultValue={currentStackHolder?.postalCode}
                inputRef={register({
                  required: {
                    value: false
                  }
                })}
              />
            </div>
            <div className="mt-2">
              <SelectInputAutoComplete
                defaultValue={currentStackHolder?.countryOfResidence}
                id="ba-perso-country"
                label="Country"
                options={countries}
                value={residence}
                setSelectedValue={setResidence}
                error={error.residenceError ? true : false}
                helperText={
                  error.residenceError ? <ValidationMessage title="Please fill in your Country of Residence" /> : null
                }
              />
            </div>
            <XSText className="mt-6" title="Upload a proof of address dated less than 3 months" />
            <UploadFile
              saveImage={saveImage}
              selectedImage={selectedImage}
              uploadError={uploadError}
              acceptedFile={acceptedFile?.[0]}
              fileSelection={fileSelection}
              handleRemoveFile={handleRemoveFile}
            />
            {error?.proofOfAddressError ? <ValidationMessage title="Please upload the proof of address" /> : null}
            <XSText
              className="mt-6"
              title={`Is ${
                isAddingOwner ? 'their' : 'your'
              } correspondence address the same as\n your residential address?`}
            />
            <div className="flex md:flex-row flex-col mt-2 md:gap-4 gap-2 w-full">
              <PrimaryButton
                className={`md:w-[220px] w-full ${
                  residentialAddress === 'yes' && 'active-selcted'
                } residential-yes-and-no-button flex items-center justify-center ${
                  error?.isAddressSameOrNotClicked && 'error-btn'
                }`}
                id="residential-correspondence-btn-yes"
                bgColor="bg-white"
                linkClass="w-full"
                color="text-gray-450"
                fontSize="text-base"
                caption="Yes"
                onClick={() => chooseResidential('yes')}
              />
              <PrimaryButton
                className={`md:w-[220px] w-full ${
                  residentialAddress === 'no' && 'active-selcted'
                } residential-yes-and-no-button flex items-center justify-center ${
                  error?.isAddressSameOrNotClicked && 'error-btn'
                }`}
                id="residential-correspondence-btn-no"
                bgColor="bg-white"
                color="text-gray-450"
                linkClass="w-full"
                fontSize="text-base"
                caption="No"
                onClick={() => chooseResidential('no')}
              />
            </div>
            {error?.isAddressSameOrNotClicked && <ValidationMessage title="Please choose one option" />}
            {residentialAddress === 'no' && (
              <div className="mt-6">
                <XSText className="mb-2" title="Provide a correspondence address" />

                <TextInput
                  onKeyDown={handleEnter}
                  defaultValue={currentStackHolder?.correspondenceIncorporationAddress?.address}
                  name="correspondenceAddress"
                  label="Address"
                  placeholder="e.g. Parc de Montjuïc, s/n, 08038 Barcelona, Spain"
                  className="w-full"
                  inputRef={register({
                    required: true
                  })}
                  error={errors.address ? true : false}
                  helperText={errors.address && <ValidationMessage title="Please fill in your address" />}
                />
                <div className="flex flex-col md:flex-row gap-4 mt-2">
                  <TextInput
                    onKeyDown={handleEnter}
                    name="correspondenceCity"
                    label="City"
                    textFieldContainerClass="w-full"
                    defaultValue={currentStackHolder?.correspondenceIncorporationAddress?.city}
                    inputRef={register({
                      required: {
                        value: true,
                        message: 'Please fill in a valid Issued Date'
                      }
                    })}
                    error={errors.city ? true : false}
                    helperText={errors.city ? <ValidationMessage title={errors.city.message} /> : null}
                  />
                  <TextInput
                    onKeyDown={handleEnter}
                    defaultValue={currentStackHolder?.correspondenceIncorporationAddress?.postalCode}
                    name="correspondencePostalCode"
                    label="Postcode (Optional)"
                    textFieldContainerClass="w-full"
                    inputRef={register({
                      required: {
                        value: false
                      }
                    })}
                  />
                </div>
                <div className="mt-2">
                  <SelectInputAutoComplete
                    id="ba-perso-country"
                    label="Country"
                    defaultValue={currentStackHolder?.correspondenceIncorporationAddress?.residence}
                    options={countries}
                    value={correspondenceResidence}
                    setSelectedValue={setCorrespondenceResidence}
                    error={error.correspondenceResidenceError ? true : false}
                    helperText={
                      error.correspondenceResidenceError ? (
                        <ValidationMessage title="Please fill in your Country of Residence" />
                      ) : null
                    }
                  />
                </div>
              </div>
            )}
          </form>
        </div>
        <div className="md:w-2/6 w-full md:mt-0 mt-4">
          <MSText fontWeight="text-bold" title="Things to know" />
          <MSText
            className="mt-4"
            title={
              <div>
                Accepted
                <span className="text-bold"> proof of address </span>
                include utility bills, bank statements, payment card statements, or official government documents, dated
                within the last three months.
              </div>
            }
          />
        </div>
      </div>
      <div className="flex md:flex-row flex-col-reverse justify-between">
        <BackButton onClick={back} className="md:w-[114px] w-full" />
        <PrimaryButton
          caption="Continue"
          color="text-white"
          className="w-full"
          isBorderRequired={true}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    uploadFile: (uploadedImage) => dispatch(commonReduxActions.fileUpload(uploadedImage))
  };
};

export default connect(null, mapDispatchToProps)(RecidentialAddress);

RecidentialAddress.propTypes = {
  openDynamicModal: PropTypes.bool,
  closeDynamicModal: PropTypes.any
};
