import React from 'react';
import LGSubtitleText from '../../ui/typography/LGSubtitleText';
import XXSText from '../../ui/typography/XXSText';
import XSText from '../../ui/typography/XSText';
import NormalStepper from '../Steppers/NormalStepper';
import BaseText from '../../ui/typography/BaseText';
import Avatar from '../../ui/Avatar';
import IconArrowRight from '../../../images/icons/arrow-right-1.svg';
import AddSquare from '../../../images/icons/add-Square.svg';
import { getLocalStorageValue, stringTruncate } from '../../../helper/helpers';
import { MOBILE_DEVICE, STORAGE_NAME } from '../../../constants/enum';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const ExistedUser = ({ nextStep, completedStep, currentStep, setExistingUserFlow }) => {
  const { width } = useWindowDimensions();
  const fullName =
    getLocalStorageValue(STORAGE_NAME.IS_EXISTED_USER).firstName +
    ' ' +
    getLocalStorageValue(STORAGE_NAME.IS_EXISTED_USER).lastName;
  const verifyEmailSteps = [
    {
      number: 1,
      name: 'Select your account',
      time: '2 min'
    },
    {
      number: 2,
      name: 'Fill in your company information',
      time: '2 min'
    },
    {
      number: 3,
      name: 'Add Owners and directors',
      time: '5 min'
    },
    {
      number: 4,
      name: 'Confirm and sign',
      time: '1 day'
    }
  ];
  const whichUserFlow = (isExistingFlow) => {
    setExistingUserFlow(isExistingFlow);
    nextStep();
  };

  const LIMIT_OF_NAME = width < MOBILE_DEVICE.MOBILE_WIDTH ? 25 : 52;
  const LIMIT_OF_EMAIL = width < MOBILE_DEVICE.MOBILE_WIDTH ? 25 : 42;
  return (
    <div className="flex md:flex-row flex-col md:gap-12 gap-6 w-full">
      <div className="md:w-1/2 w-full">
        <LGSubtitleText
          className="md:whitespace-pre-line"
          title={`Let’s Get Your Company \nUp and Running!`}
          textWeight="text-medium"
        />
        <XXSText
          title="We'll guide you step by step during the next 10 min to ensure your company meets all the requirements"
          className="mt-4"
        />
        <NormalStepper completedStep={completedStep} currentStep={currentStep} verifyEmailSteps={verifyEmailSteps} />
      </div>
      <div className="bg-green-200 rounded-2xl md:w-1/2 w-full">
        <div className="md:p-6 p-4 flex flex-col justify-center h-full">
          <BaseText
            title={`Your email is already \nlinked to a Statrys account.`}
            className="md:whitespace-pre-line"
            lineHeight="leading-[18px]"
          />
          <XXSText title="Register a new company with your Statrys user" className="mt-10" />
          <div
            className="rounded-lg bg-white border border-gray-400 mt-2 cursor-pointer h-[72px] flex flex-col justify-center hover:border hover:border-purple-500 hover:shadow-lg"
            onClick={() => whichUserFlow(true)}
          >
            <div className="p-4 flex flex-row justify-between">
              <div className="flex flex-row gap-4 items-center md:w-full w-[50%]">
                <Avatar name={fullName} avatarStyling="w-10 h-10" borderRadius="rounded-lg" />
                <div className="flex flex-col">
                  <XSText title={stringTruncate(fullName, LIMIT_OF_NAME)} fontWeight="text-bold" />
                  <XSText
                    title={stringTruncate(getLocalStorageValue(STORAGE_NAME.IS_EXISTED_USER).email, LIMIT_OF_EMAIL)}
                    textClass="block overflow-hidden overflow-ellipsis md:w-full w-[80%]"
                  />
                </div>
              </div>
              <img src={IconArrowRight} alt="arrow right" />
            </div>
          </div>
          <XXSText title="or register the company with a new one" className="mt-4" />
          <div
            className="rounded-lg bg-white border border-gray-400 mt-2 cursor-pointer h-[72px] hover:border hover:border-purple-500 hover:shadow-lg"
            onClick={() => whichUserFlow(false)}
          >
            <div className="p-4 flex flex-row justify-between">
              <div className="flex flex-row gap-4 items-center">
                <div className="bg-green-500 rounded-lg w-10 h-10 flex justify-center items-center">
                  <img src={AddSquare} alt="AddSquare" />
                </div>
                <XSText title="Create new user" fontWeight="text-bold" />
              </div>
              <img src={IconArrowRight} alt="arrow right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExistedUser;
