import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../routes/paths';
import { isBrowser, getUserCompanies } from '../../helper/helpers';
import { PAGE_TITLE, LOGIN_TAB } from '../../constants/enum';

const BASE_API_URL = process.env.BASE_API_URL;
export const getUser = () =>
  isBrowser() && window.localStorage.getItem('user') //Get LocalStorage Data of User
    ? JSON.parse(window.localStorage.getItem('user'))
    : {};

const setUser = async (user, token) => {
  if (Object.keys(user).length !== 0) {
    typeof window !== 'undefined' && window.localStorage.setItem('user', JSON.stringify(user)); //Set LocalStorage Data of User
    user?.companyId && window.sessionStorage.setItem('companyId', user?.companyId);
    try {
      const checkUserCompanies = await getUserCompanies(token, user?.email);
      const isCompanyApproved = checkUserCompanies?.data?.data?.companies?.find(
        (company) => company?.isApproved === true
      );
      if (checkUserCompanies?.data?.data?.companies.length > 1) {
        navigate(PATH_PAGE?.companiesList, {
          state: {
            userDetails: checkUserCompanies?.data?.data
          }
        });
      } else if (checkUserCompanies?.data?.data?.companies.length === 1) {
        const isCompanyFinished = checkUserCompanies?.data?.data?.companies?.find(
          (company) => company?.finishedApplication === true
        );
        if (isCompanyApproved) {
          navigate(PATH_PAGE.companyProfile);
        } else if (isCompanyFinished) {
          navigate(`${PATH_PAGE.companyIncorporationSuccess}?continue-form=true`);
        } else if (!isCompanyFinished) {
          navigate(`${PATH_PAGE.companyIncorporation}?continue-form=true`);
        }
      } else {
        navigate(`${PATH_PAGE.companyIncorporation}?continue-form=true`);
      }
    } catch (error) {
      alert(error.response?.data?.message);
    }
    isBrowser() && window.localStorage.setItem('pageName', JSON.stringify(PAGE_TITLE.DASHBOARD));
  }
};
const navigateToLogin = () => navigate(PATH_PAGE.login);

export const handleLogin = (token, userDetails, activeTab) => {
  if (token) {
    sessionStorage.setItem('token', token);
    setUser(
      {
        id: userDetails?._id,
        isVerified: userDetails?.isVerified,
        email: userDetails?.email,
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        isCompanyOwner: userDetails?.isCompanyOwner,
        isIncorporation: activeTab === LOGIN_TAB?.INVOICE ? false : userDetails?.isIncorporation,
        isApproved: userDetails?.isApproved,
        companyId: userDetails?.companyId,
        phoneNumber: userDetails?.phoneNumber
      },
      token
    );
    return true;
  }
  return false;
};

export const isLoggedIn = () => {
  const token = typeof window !== 'undefined' && sessionStorage.getItem('token');
  return token ? true : false;
};

export const logOut = () => {
  sessionStorage.clear();
  typeof window !== 'undefined' && window.localStorage.clear();
  setUser({});
  navigateToLogin();
};
