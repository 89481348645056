import React from 'react';
import Batch from './Batch';
import { StaticImage } from 'gatsby-plugin-image';

const HeaderRightSide = () => {
  return (
    <div className="flex justify-end relative">
      <div className="right-side-container-image">
        <Batch
          title="“Statrys delivers all I have been hoping for”"
          reviewerBg="bg-blue-500"
          reviewerLogo="SJ"
          reviewerName="Steffens Joern"
          reviewerLocation="TR"
          className="absolute z-[2] first-batch shadow-md"
          reviewerTextColor="text-white"
        />
        <Batch
          title="“The best way to incorporate in HK with a bank account”"
          reviewerBg="bg-yellow-500"
          reviewerLogo="KH"
          reviewerName="Ken H."
          reviewerLocation="TH"
          className="absolute z-[2] second-batch shadow-md"
        />
        <Batch
          title="“I saved so much time and money and didn’t visit HK”"
          reviewerBg="bg-green-200"
          reviewerLogo="PA"
          reviewerName="Pak Alexsander"
          reviewerLocation="VN"
          className="absolute z-[2] third-batch shadow-md"
        />

        <StaticImage
          src={'../../images/graphics/registerCompany/company-registration-hero.png'}
          className="hong-kong-company-registration-image rounded-lg"
          alt="Picture of a business man with a laptop smiling"
          width={484}
          height={488}
          loading="eager"
        />
      </div>
    </div>
  );
};

export default HeaderRightSide;
