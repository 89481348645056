import * as actionTypes from './actionTypes';
let initialState = {
  newCompanyRegistrationData: {},
  currentStakeHolder: {},
  completedRegistration: [],
  allStakeHolder: [],
  stakeHolderForEdit: {},
  companyCreateDetails: {},
  companyUpdateDetails: {},
  allCompanyDetails: {},
  uploadPassportFile: {},
  paymentDetails: {},
  deletedStakHolderResponse: {},
  existingCompanyRegistration: {},
  loading: false,
  error: false
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NEW_COMPANY_REGISTRATION:
      return {
        ...state,
        newCompanyRegistrationData: { ...state.newCompanyRegistrationData, ...action.newCompanyRegistrationData }
      };
    case actionTypes.COMPLETED_COMPANY_REGISTRATION:
      return {
        ...state,
        completedRegistration: [...action.completedCompanyRegistrationData]
      };
    case actionTypes.ADD_ALL_STAKEHOLDER:
      return {
        ...state,
        allStakeHolder: [...action.stakeHolder]
      };
    case actionTypes.ADD_STAKEHOLDER_DATA:
      return {
        ...state,
        currentStakeHolder: { ...state.currentStakeHolder, ...action.currentStakeHolder }
      };
    case actionTypes.STAKEHOLDER_EDIT_DETAILS:
      return {
        ...state,
        stakeHolderForEdit: action.stakeHolderInfo
      };
    case actionTypes.STAKEHOLDER_DETAILS_REMOVE:
      return {
        ...state,
        stakeHolderForEdit: initialState.stakeHolderForEdit
      };

    case actionTypes.COMPANY_CREATE_REQUEST_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.COMPANY_CREATE_REQUEST_SUCCESS:
      return {
        ...state,
        companyCreateDetails: action.companyCreatePayload,
        loading: false,
        error: false
      };
    case actionTypes.COMPANY_CREATE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case actionTypes.COMPANY_UPDATE_REQUEST_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.COMPANY_UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        companyUpdateDetails: action.companyUpdatePayload,
        loading: false,
        error: false
      };
    case actionTypes.COMPANY_UPDATE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case actionTypes.COMPANY_DETAILS_REQUEST_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.COMPANY_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        allCompanyDetails: action.companyDetails,
        loading: false,
        error: false
      };
    case actionTypes.COMPANY_DETAILS_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case actionTypes.UPLOAD_FILE_REQUEST_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.UPLOAD_FILE_REQUEST_SUCCESS:
      return {
        ...state,
        uploadPassportFile: action.fileData,
        loading: false,
        error: false
      };
    case actionTypes.UPLOAD_FILE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case actionTypes.PAYMENT_REQUEST_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        paymentDetails: action.paymentData,
        loading: false,
        error: false
      };
    case actionTypes.PAYMENT_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case actionTypes.DELETE_STAKHOLDER_REQUEST_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.DELETE_STAKHOLDER_SUCCESS:
      return {
        ...state,
        deletedStakHolderResponse: action.deletedData,
        loading: false,
        error: false
      };
    case actionTypes.DELETE_STAKHOLDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case actionTypes.EXISTING_COMPANY_REGISTRATION_REQUEST_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.EXISTING_COMPANY_REGISTRATION_SUCCESS:
      return {
        ...state,
        existingCompanyRegistration: action.existingCompanyCreatePayload,
        loading: false,
        error: false
      };
    case actionTypes.EXISTING_COMPANY_REGISTRATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
};
export default reducer;
