import React from 'react';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import XSText from '../ui/typography/XSText';
import PropTypes from 'prop-types';

export default function SnackBar({
  title,
  subTitle,
  subTitleColor,
  description,
  bgColor,
  borderColor,
  icon,
  className
}) {
  return (
    <div className={`border ${borderColor} ${className} rounded-lg`}>
      <div className={`${bgColor} flex flex-row p-4 gap gap-4 rounded-lg items-start`}>
        {icon && <img src={icon} alt="icon" width="37.5" height="37.5" />}
        <div className="flex flex-col gap gap-2">
          <MDSubtitleText title={title} fontWeight="text-bold" />
          {subTitle && <XSText title={subTitle} textColor={subTitleColor} fontWeight="text-bold" />}
          {description && <XSText title={description} className="md:whitespace-pre-line" />}
        </div>
      </div>
    </div>
  );
}

SnackBar.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  bgColor: PropTypes.string,
  borderColor: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleColor: PropTypes.string,
  icon: PropTypes.any,
  className: PropTypes.string
};
SnackBar.defaultProps = {
  title: 'title',
  bgColor: 'bg-green-200',
  borderColor: 'border-green-500',
  subTitleColor: 'text-gray-500'
};
