import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ValidationMessage from '../../ui/ValidationMessage';
import TextInput from '../../ui/inputs/TextInput';
import LGSubtitleText from '../../ui/typography/LGSubtitleText';
import PasswordMan from '../../../images/graphics/company-incorporation/set-password.png';
import BackButton from '../../ui/buttons/BackButton';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import { API_CODE, COMPANY_SETUP_STEP, STORAGE_NAME } from '../../../constants/enum';
import axios from 'axios';
import { ENDPOINTS } from '../../../service/services';
import { getLocalStorageValue } from '../../../helper/helpers';

const Login = ({ prevStep, setStep }) => {
  const { register, handleSubmit, errors, setError, control } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const responseType = 'INVALID_PASSWORD';
  const onSubmit = async (data) => {
    try {
      setLoading(true);
      data.companyId = sessionStorage.getItem('companyId');
      data.email = getLocalStorageValue(STORAGE_NAME.IS_EXISTED_USER)?.email;
      const response = await axios.post(`${process.env.BASE_API_URL}${ENDPOINTS.EXISTING_USER_LOGIN}`, data);
      if (response?.status === API_CODE.STATUS_200) {
        sessionStorage.setItem('token', response?.data?.data?.token);
        setStep(COMPANY_SETUP_STEP?.START_COMPANY_INFORMATION);
      }
    } catch (error) {
      if (error?.response?.data?.responseType === responseType) {
        setError('password', { type: 'required' });
        setErrorMessage(error?.response?.data?.message);
      }
    }
    setLoading(false);
  };
  return (
    <div className="flex md:flex-row flex-col md:gap-12 gap-6 w-full initial-wrapper">
      <div className="md:w-1/2 w-full flex flex-col justify-between">
        <LGSubtitleText title="Log into your account" textWeight="text-medium" />
        <div className="flex flex-row md:mt-0 mt-4 justify-between w-full">
          <form id="login account" className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              defaultValue={getLocalStorageValue(STORAGE_NAME.IS_EXISTED_USER)?.email}
              disabled
              name="email"
              label="Email address"
              variant="filled"
              inputRef={register({
                required: true
              })}
              textFieldContainerClass="w-full"
              error={errors?.email ? true : false}
              helperText={errors?.email && <ValidationMessage title={'Please fill in a valid password'} />}
            />
            <div className="w-full">
              <Controller
                control={control}
                name="password"
                render={({ value }) => (
                  <TextInput
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    name="password"
                    onRightSideAdornment={() => setShowPassword(true)}
                    value={value}
                    endAdornment={showPassword ? <Visibility /> : <VisibilityOff />}
                    textFieldContainerClass="mt-4"
                    inputRef={register({
                      required: true
                    })}
                    error={errors?.password ? true : false}
                    helperText={
                      errors?.password && (
                        <ValidationMessage title={errorMessage ? errorMessage : 'Please fill in a valid password'} />
                      )
                    }
                  />
                )}
              />
            </div>
          </form>
        </div>
        <div className="flex md:flex-row flex-col-reverse md:mt-0 mt-4 justify-between">
          <BackButton onClick={() => prevStep()} className="md:w-[114px] w-full" />
          <PrimaryButton
            caption="Continue"
            color="text-white"
            isLoading={loading}
            className="w-full"
            isBorderRequired={true}
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
      <div className="md:w-1/2 w-full flex justify-center">
        <img src={PasswordMan} alt="mail for you" width="368" className="object-contain" />
      </div>
    </div>
  );
};

export default Login;
