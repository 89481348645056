import React, { useState, useEffect } from 'react';
import SEO from '../../components/seo';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import '../../styles/pages/new-company-signup/start.scss';
import Navbar from '../../components/new-company-signup/components/Navbar';
import { SIGN_UP_FLOW_CONSTANT, COMPANY_REGISTRATION_COUNTRY, COMPANY_CREATE_STEPS } from '../../constants/enum';
import { ENDPOINTS } from '../../service/end-points';
import CompanyOwners from '../../components/new-company-signup/CompanyOwners';
import H4HeaderText from '../../components/ui/typography/H4HeaderText';
import XXSText from '../../components/ui/typography/XXSText';
import CompanyName from '../../components/new-company-signup/companyDetails/CompanyName';
import Industry from '../../components/new-company-signup/Industry';
import Accounting from '../../components/new-company-signup/Accounting';
import PersonalInformation from '../../components/new-company-signup/PersonalInformation';
import PaymentUI from '../../components/new-company-signup/checkout/PaymentUI';
import SatisfactionGuaranteed from '../../components/new-company-signup/components/SatisfactionGuaranteed';
import IndustryWarningBox from '../../components/new-company-signup/components/IndustryWarningBox';
import XSText from '../../components/ui/typography/XSText';
import MSText from '../../components/ui/typography/MSText';
import Location from '../../images/icons/location.svg';
import TrustPilotLogo from '../../images/icons/trustpilot-logo.svg';
import TrustPilotStars from '../../images/icons/trustpilot-stars.svg';
import IconComma from '../../images/icons/inverted-comma.svg';
import CheckListIcon from '../../images/icons/checklist.svg';
import ProfessionalManDoctorIcon from '../../images/icons/inline/professional-man-doctor.inline.svg';
import CursorChooseIcon from '../../images/icons/inline/cursor-choose.inline.svg';
import Review from '../../components/new-company-signup/Review';
import GreenTick from '../../images/icons/green-tick.svg';
import { connect } from 'react-redux';
import * as actionsForRegistrationNewCompany from '../../components/new-company-signup/redux/action';
import RegistrationCountry from '../../components/new-company-signup/companyDetails/RegistrationCountry';
import NavigationBar from '../../components/sideBar/NavigationBar';
import { isLoggedIn } from '../../components/authService/auth';
import ParentLoader from '../../components/ui/loaders/ParentLoader';
import Banner from '../../components/common/Banner';
const hongKongservicePoints = [
  {
    heading: `Incorporation Services`,
    points: [
      `Filing with the Companies Registry in Hong Kong`,
      `Certificate of Incorporation`,
      `Business Registration Certificate`,
      <div key="company-kit">
        Company kit:
        <ul className="list-disc ml-6 mt-1">
          <li>Company chops (corporate seals)</li>
          <li className="mt-1">Articles of Association</li>
        </ul>
      </div>
    ]
  },
  {
    heading: `Company Secretary services`,
    points: [
      <p key="comp-sec-services">
        Company secretarial services for <span className="text-bold">one year</span>
      </p>,
      <p key="comp-services-2">
        Registered address in Hong Kong for <span className="text-bold">one year</span>
      </p>,
      `Filing of Annual Return (NAR1)`,
      `Maintenance of the statutory records`,
      `Preparation of the Annual General Meeting`,
      `All of your statutory records and compliance documents in one place, accessible online 24/7`
    ]
  },
  {
    heading: `Registered address in Hong Kong`,
    points: [
      <p key="registered-address">
        Registered address for <span className="text-bold">one year</span>
      </p>,
      `Scanning and forwarding of mail`
    ]
  },
  {
    heading: `Business Account opening`,
    points: [
      `Assistance in opening a Statrys business account, inclluding payment card (Subject to application and approval)`
    ]
  }
];
const singaporeServicePoints = [
  {
    heading: `Incorporation Services`,
    points: [
      `Incorporation Filing with the Accounting Corporate Regulatory and Authority (”ACRA”).`,
      `Certificate of Incorporation`,
      `Business Profile`,
      `Provision of a Local Nominee Director (1 year)`,
      <div key="company-kit">
        Company kit:
        <ul className="list-disc ml-6 mt-1">
          <li>Company chops (corporate seals)</li>
          <li className="mt-1">Constitution of the Company</li>
        </ul>
      </div>
    ]
  },
  {
    heading: `Company Secretary services`,
    points: [
      <p key="comp-sec-services">
        Company secretarial services for <span className="text-bold">one year</span>
      </p>,
      `Annual Return Filing with ACRA for each calendar year`,
      `Maintenance of the statutory records`,
      `Preparation of Annual General Meeting (”AGM”)`,
      `All of your statutory records and compliance documents in one place, accessible online 24/7`
    ]
  },
  {
    heading: `Registered address in Singapore`,
    points: [
      <p key="registered-address">
        Registered address for <span className="text-bold">one year</span>
      </p>,
      `Scanning and forwarding of mail`
    ]
  }
];
function Start({ location, newCompanyAllInfo, newCompanyData }) {
  const { state } = location;
  const query = new URLSearchParams(location?.search);
  const registrationCountryParam = query.get('country');
  const [payload, setPayload] = useState({
    step: registrationCountryParam ? 1 : 0
  });
  const [allFieldsData, setAllFieldsData] = useState({
    companyName: ''
  });
  let initialStateOfCompliation = {
    companyInformationCompleted: false,
    ownerInformationCompleted: false,
    paymentCompleted: false
  };
  const [completedSteps, setCompletedSteps] = useState(initialStateOfCompliation);
  const servicePoints =
    newCompanyAllInfo?.registrationCountry === COMPANY_REGISTRATION_COUNTRY?.HONG_KONG
      ? hongKongservicePoints
      : singaporeServicePoints;
  const { step } = payload;
  let nextStep = () => {
    setPayload((prev) => ({
      ...prev,
      step: step + 1
    }));
  };
  let prevStep = () => {
    state?.fromCompanyCreationProductPage
      ? navigate('/hong-kong-company-registration')
      : state?.path === SIGN_UP_FLOW_CONSTANT?.GET_STARTED
      ? navigate(ENDPOINTS?.OPEN_ACCOUNT)
      : setPayload((prev) => ({
          ...prev,
          step: step - 1 > -1 ? step - 1 : 0
        }));
  };
  const params = new URLSearchParams(location?.search);
  const intentId = params.get('payment_intent');
  useEffect(() => {
    const companyDataFromSession = JSON.parse(sessionStorage.getItem('newCompanyAllInfo'));
    newCompanyData(companyDataFromSession);
    if (companyDataFromSession?.step === 7) {
      setPayload({ ...payload, step: 7 });
      setCompletedSteps({ companyInformationCompleted: true, ownerInformationCompleted: true, paymentCompleted: true });
    }
  }, []);

  const stepHandler = async () => {
    window.onpopstate = () => {
      prevStep();
    };
    if (registrationCountryParam) {
      let newCompanyPayload = {
        ...newCompanyAllInfo,
        registrationCountry: registrationCountryParam
      };
      await newCompanyData(newCompanyPayload);
    }
    if (step === 0 && !intentId) {
      return navigate('?=company-registration-country', {
        state: {
          fromCompanyCreationProductPage: state?.fromCompanyCreationProductPage
        }
      });
    }
    if (step === 1 && !intentId) {
      return navigate('?=company-information', {
        state: {
          companyName: state?.companyName,
          isCompanyNameAvailable: state?.isCompanyNameAvailable,
          path: state?.path
        }
      });
    }
    if (step === 2) {
      return navigate('?=owners-directors');
    }
    if (step === 3) {
      return navigate('?=industry');
    }
    if (step === COMPANY_CREATE_STEPS?.ACCOUNTING) {
      return navigate('?=accounting');
    }
    if (step === 5) {
      return navigate('?=personal-information');
    }
    if (step === COMPANY_CREATE_STEPS?.REVIEW) {
      return navigate('?=incorporation-package');
    }
    if (step === 7 && !intentId) {
      return navigate('?=payment');
    }
  };

  useEffect(() => {
    stepHandler();
  }, [step]);

  let selectedCase = () => {
    switch (payload.step) {
      default:
        return <h1>coming soon...</h1>;

      case 0:
        return (
          <RegistrationCountry
            nextStep={nextStep}
            setCompletedSteps={setCompletedSteps}
            completedSteps={completedSteps}
            setAllFieldsData={setAllFieldsData}
            allFieldsData={allFieldsData}
          />
        );
      case 1:
        return (
          <CompanyName
            nextStep={nextStep}
            prevStep={prevStep}
            setCompletedSteps={setCompletedSteps}
            completedSteps={completedSteps}
            setAllFieldsData={setAllFieldsData}
            allFieldsData={allFieldsData}
          />
        );
      case 2:
        return (
          <CompanyOwners
            nextStep={nextStep}
            prevStep={prevStep}
            setCompletedSteps={setCompletedSteps}
            completedSteps={completedSteps}
          />
        );
      case 3:
        return (
          <Industry
            nextStep={nextStep}
            prevStep={prevStep}
            setCompletedSteps={setCompletedSteps}
            completedSteps={completedSteps}
          />
        );
      case COMPANY_CREATE_STEPS?.ACCOUNTING:
        return (
          <Accounting
            nextStep={nextStep}
            prevStep={prevStep}
            setCompletedSteps={setCompletedSteps}
            completedSteps={completedSteps}
            setPayload={setPayload}
          />
        );
      case 5:
        return (
          <PersonalInformation
            nextStep={nextStep}
            prevStep={prevStep}
            setCompletedSteps={setCompletedSteps}
            completedSteps={completedSteps}
            setPayload={setPayload}
          />
        );
      case COMPANY_CREATE_STEPS?.REVIEW:
        return (
          <Review
            nextStep={nextStep}
            prevStep={prevStep}
            setCompletedSteps={setCompletedSteps}
            completedSteps={completedSteps}
            setPayload={setPayload}
            registrationCountry={newCompanyAllInfo?.registrationCountry}
          />
        );
      case 7:
        return (
          <PaymentUI
            prevStep={prevStep}
            setCompletedSteps={setCompletedSteps}
            completedSteps={completedSteps}
            nextStep={nextStep}
            registrationCountry={newCompanyAllInfo?.registrationCountry}
          />
        );
    }
  };

  let selectedInfo = () => {
    switch (payload.step) {
      default:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <H4HeaderText fontWeight="text-regular" title="Things to know" />
            <XXSText
              className={'whitespace-pre-line mt-4'}
              title={`Your company name must be unique. Avoid using any trademark names, or names that are too similar to existing businesses. That may cause the rejection of your application.
No matter the name you pick, it will end with the word "Limited" as per Hong Kong regulations.
If you are not sure yet about your company name you can skip this step and decide later.`}
            />
            <SatisfactionGuaranteed />
          </div>
        );

      case 0:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <img src={IconComma} alt="IconComma" className="mb-4" />
            <XXSText title="I saved so much time and money [...] because they know the essentials and offered a package with secretary services and address. This is a must and you anyhow will need to outsource if it is a shell company." />
            <div className="mt-6 flex flex-row gap gap-2">
              <div className={` bg-blue-200 rounded-full name-container flex items-center justify-center`}>
                <XSText title="PA" className="p-2" textColor="text-gray-500" fontWeight="text-bold" />
              </div>
              <div className="flex flex-col gap gap-1">
                <MSText title="Pak Alexsander" />
                <div className="flex flex-row gap gap-2">
                  <img src={Location} alt="location" width="14" height="14" />
                  <MSText title="BL" textColor="text-gray-450" />
                </div>
              </div>
            </div>
            <a href="https://www.trustpilot.com/review/statrys.com" target="_blank" rel="noopener noreferrer">
              <div className="flex flex-wrap gap-4 md:mt-6 mt-3 items-center">
                <img src={TrustPilotLogo} width="91" height="23" alt="trustpilot logo" loading="eager" />
                <div className="vl"></div>
                <div className="mt-1 text-bold text-base">Rated Excellent</div>
                <img src={TrustPilotStars} alt="trust pilot stars" width="90" height="23" loading="eager" />
              </div>
            </a>
            <SatisfactionGuaranteed />
          </div>
        );
      case 1:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <H4HeaderText fontWeight="text-regular" title="Things to know" />
            <XXSText
              className={'whitespace-pre-line mt-4'}
              title={`Your company name must be unique. Avoid using any trademark names, or names that are too similar to existing businesses. That may cause the rejection of your application.\n\n${
                newCompanyAllInfo.registrationCountry === COMPANY_REGISTRATION_COUNTRY.HONG_KONG
                  ? 'No matter the name you pick, it will end with the word "Limited" as per Hong Kong regulations.'
                  : 'No matter the name you pick, it will end with the word “Pte. Ltd”.'
              }\n\nIf you are not sure yet about your company name you can skip this step and decide \nlater.`}
            />
            <SatisfactionGuaranteed />
          </div>
        );
      case 2:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <H4HeaderText fontWeight="text-regular" title="Things to know" />
            <XXSText
              className={'whitespace-pre-line mt-4'}
              title={`A company owner is a person who owns shares in the company.\n\nA company owner may also be called a shareholder, member, or partner. These are legal terms to qualify a company owner.`}
            />
            <SatisfactionGuaranteed />
          </div>
        );
      case 3:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <H4HeaderText fontWeight="text-regular" title="Things to know" />
            <XXSText
              className={'whitespace-pre-line mt-4'}
              title={`Pick up in our list the type of business that best matches with the future activity of your company. If no option fits, select “Others” and provide a brief description.`}
            />
            <IndustryWarningBox />
          </div>
        );
      case 4:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <H4HeaderText
              fontWeight="text-regular"
              title={
                <span>
                  We help you <span className="text-bold">save</span> money with your accounting.
                  <br />
                  See how it works:
                </span>
              }
            />
            <div className="mt-5 flex gap-4 items-start">
              <div className="w-6">
                <ProfessionalManDoctorIcon />
              </div>
              <div>
                <XSText fontWeight="text-bold" title="Get expert accounting support" />
                <XSText
                  className="mt-2"
                  title="We have built a network of selected accounting firms to ensure the highest quality of services for our clients."
                />
              </div>
            </div>
            <div className="mt-5 flex gap-4 items-start">
              <img src={CheckListIcon} alt="CheckListIcon" />
              <div>
                <XSText fontWeight="text-bold" title="Receive multiple price quotes" />
                <XSText
                  className="mt-2"
                  title="If you ask our assistance for accounting, we will on an anonymous basis solicit price quotations from the accounting firms present in our network."
                />
              </div>
            </div>
            <div className="mt-5 flex gap-4 items-start">
              <div className="w-6">
                <CursorChooseIcon />
              </div>
              <div>
                <XSText fontWeight="text-bold" title="Choose the best option for your needs" />
                <XSText
                  className="mt-2"
                  title="We will present you with the three most competitive quotes, allowing you to make an informed decision."
                />
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <H4HeaderText fontWeight="text-regular" title="Things to know" />
            <XXSText
              className={'whitespace-pre-line mt-4'}
              title={`We will use your personal information to communicate with you during the company incorporation process.\n\nYour personal information will be kept secure and used only for the purpose of assisting you with incorporating your company.`}
            />
            <SatisfactionGuaranteed />
          </div>
        );
      case 6:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <H4HeaderText
              fontWeight="text-regular whitespace-pre-line"
              title={`One package, One price \nAll included`}
            />
            {servicePoints?.map((service, index) => {
              return (
                <div key={index}>
                  <XSText fontWeight="text-bold" className="mt-4" title={service?.heading} />
                  {service?.points.map((point, pointIndex) => {
                    return (
                      <div className="flex flex-row items-start mt-3" key={pointIndex}>
                        <img src={GreenTick} alt="check" width="16" height="16" />
                        <XSText title={point} className="ml-1 whitespace-pre-line" />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      case 7:
        return (
          <div className="company-registration-info-container md:mx-10 mx-4">
            <img src={IconComma} alt="IconComma" className="mb-4" />
            <XXSText title="I saved so much time and money [...] because they know the essentials and offered a package with secretary services and address. This is a must and you anyhow will need to outsource if it is a shell company." />
            <div className="mt-6 flex flex-row gap gap-2">
              <div className={` bg-blue-200 rounded-full name-container flex items-center justify-center`}>
                <XSText title="PA" className="p-2" textColor="text-gray-500" fontWeight="text-bold" />
              </div>
              <div className="flex flex-col gap gap-1">
                <MSText title="Pak Alexsander" />
                <div className="flex flex-row gap gap-2">
                  <img src={Location} alt="location" width="14" height="14" />
                  <MSText title="BL" textColor="text-gray-450" />
                </div>
              </div>
            </div>
            <a href="https://www.trustpilot.com/review/statrys.com" target="_blank" rel="noopener noreferrer">
              <div className="flex flex-wrap gap-4 md:mt-6 mt-3 items-center">
                <img src={TrustPilotLogo} width="91" height="23" alt="trustpilot logo" loading="eager" />
                <div className="vl"></div>
                <div className="mt-1 text-bold text-base">Rated Excellent</div>
                <img src={TrustPilotStars} alt="trust pilot stars" width="90" height="23" loading="eager" />
              </div>
            </a>
            <SatisfactionGuaranteed />
          </div>
        );
    }
  };
  const MainChildren = () => {
    return (
      <div className="md:pl-5 md:px-6 px-4 md:pt-0 pt-6 md:pb-0 pb-20  md:mt-0 flex flex-col md:flex-row md:gap-4 gap-16 items-center justify-center self-center h-full mx-auto">
        {selectedCase()}
        {selectedInfo()}
      </div>
    );
  };

  return (
    <div className="bg-customBg-secondaryBg md:h-screen min-h-screen w-screen relative md:overflow-hidden">
      <ParentLoader />
      <div className="company-signup-blur"></div>
      <SEO keywords={[`Statrys`]} title={'Registration'} description={'Registration of personal info'} />
      {isLoggedIn() ? (
        <NavigationBar hideBanner={true} noSideBar={true} children={<MainChildren />} />
      ) : (
        <>
          <Navbar step={step} />
          <MainChildren />
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    newCompanyAllInfo: state?.newAccountSetup?.newCompanyRegistrationData
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    newCompanyData: (newCompanyRegistrationData) =>
      dispatch(actionsForRegistrationNewCompany.newCompanyRegistrationData(newCompanyRegistrationData))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Start);
Start.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.any,
  newCompanyAllInfo: PropTypes.object,
  newCompanyData: PropTypes.func
};
