import React, { useEffect, useState } from 'react';
import Cross from '../../images/icons/customSvg/Cross';
import BaseText from '../ui/typography/BaseText';
import PropTypes from 'prop-types';
import { MUI_COLORS } from '../../constants/enum';

export default function Banner({ bannerData, setIsBannerVisible }) {
  const [bannerVisible, setBannerVisible] = useState(null);

  const closeButtonClicked = async () => {
    sessionStorage.setItem('isBannerHidden', true);
    setBannerVisible(false);
    setIsBannerVisible && setIsBannerVisible(false);
  };
  useEffect(() => {
    const bannerState = sessionStorage.getItem('isBannerHidden');
    setBannerVisible(null);
  }, []);
  return (
    <>
      {bannerVisible && (
        <div
          className=" w-full bg-coral-500 md:h-14 items-center px-5 flex justify-between"
          style={{ backgroundColor: bannerData?.background_color, zIndex: 99999 }}
          id="announcement-banner"
        >
          <div></div>
          <div className="flex items-center ">
            <div className="py-[9px] pl-0 md:pl-[29.5px] flex gap gap-1 relative">
              <BaseText title={<div style={{ color: bannerData?.text_color }}>{bannerData?.title?.text}</div>} />
            </div>
          </div>
          <div onClick={() => closeButtonClicked()} className="cursor-pointer">
            <Cross stroke={MUI_COLORS.WHITE} />
          </div>
        </div>
      )}
    </>
  );
}

Banner.propTypes = {
  onCloseClick: PropTypes.func,
  bannerData: PropTypes.object
};
Banner.defaultProps = {
  noNeedBottomImage: false,
  bannerData: {
    link: {
      url: '/under-maintenance'
    },
    background_color: MUI_COLORS?.SUNGLOW,
    link_text: { text: 'Start now!' },
    text_color: MUI_COLORS?.DARK_CHARCOAL,
    title: {
      text: 'Scheduled Maintenance: We will be offline on Sunday, 4th August between 11 AM - 1 PM HKT for maintenance. Thank you for your understanding.'
    }
  }
};
