import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/whats-new-accordian.scss';
import BaseText from '../ui/typography/BaseText';
import GreenTriangleDown from '../../images/icons/green-triangle-down.svg';
export default function NumberedAccordian({
  title,
  description,
  isOpen,
  onClick,
  isLast,
  number,
  GreenBar,
  arrowImg,
  needHr
}) {
  return (
    <div onClick={onClick} className="transform w-full">
      <div className="accordion-container cursor-pointer w-full">
        <div className="flex md:gap-0 gap-4 justify-between">
          <div className="flex gap-4 items-center">
            {number && (
              <div className="bg-green-200 rounded-full text-bold w-12 h-12 flex items-center text-green-500 justify-center pricing-heading">
                {number}
              </div>
            )}
            <BaseText fontWeight="text-bold" title={title} />
          </div>
          <img
            src={arrowImg ? arrowImg : GreenTriangleDown}
            className={isOpen && 'transform rotate-180'}
            alt="GreenTriangleDown"
          />
        </div>
        <div className={isOpen ? 'block' : 'hidden'}>
          {needHr && <hr className="w-full  border-t-1 border-green-200 my-6" />}
          <BaseText className={`whitespace-pre-line ${needHr ? 'mt-4' : 'mt-6'}`} line title={description} />
        </div>
      </div>
      {!isLast && (
        <div className={`flex gap-2 ${!GreenBar && 'h-4 w-4'}`}>
          {GreenBar && <div className="h-4 w-4 bg-green-500 ml-10 green-bar"></div>}
        </div>
      )}
    </div>
  );
}

NumberedAccordian.propTypes = {
  title: PropTypes.bool,
  description: PropTypes.string,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  isLast: PropTypes.bool,
  number: PropTypes.number,
  GreenBar: PropTypes.bool,
  arrowImg: PropTypes.any,
  needHr: PropTypes.bool
};

NumberedAccordian.defaultProps = {
  GreenBar: true,
  needHr: true
};
