import React from 'react';
import PropTypes from 'prop-types';
import GreenTriangleDown from '../../images/icons/green-triangle-down.svg';
import XSText from '../ui/typography/XSText';
import PlusIcon from '../../images/icons/plus.svg';
export default function WhatsIncludedAccordion({ title, description, isOpen, onClick, isLast }) {
  return (
    <div onClick={onClick} className="transform">
      <div className="accordion-container cursor-pointer">
        <div className="flex md:gap-0 gap-4 justify-between">
          <h4 className="text-xl">{title}</h4>
          <img src={GreenTriangleDown} className={isOpen && 'transform rotate-180'} alt="GreenTriangleDown" />
        </div>
        <div className={isOpen ? 'block' : 'hidden'}>
          <hr className="w-full  border-t-1 border-green-200 my-6" />
          <XSText className="whitespace-pre-line mt-4" line title={description} />
        </div>
      </div>
      {!isLast && (
        <div className="flex gap-2 ml-6">
          <img src={PlusIcon} alt="PlusIcon" />
          <hr className="w-full  border-t-1 border-green-200 my-4" />
        </div>
      )}
    </div>
  );
}

WhatsIncludedAccordion.propTypes = {
  title: PropTypes.bool,
  description: PropTypes.string,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  isLast: PropTypes.bool
};
