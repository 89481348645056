import React from 'react';
import BaseText from '../ui/typography/BaseText';
import PropTypes from 'prop-types';
import Pin from '../../images/icons/pin.svg';
import MSText from '../ui/typography/MSText';
import XSText from '../ui/typography/XSText';

export default function Batch({
  title,
  className,
  reviewerBg,
  reviewerLogo,
  reviewerTextColor,
  reviewerName,
  reviewerLocation
}) {
  return (
    <div className={`rounded-lg bg-white w-64 cursor-pointer batch-background ${className}`}>
      <div className="flex flex-row gap gap-4 px-4 pt-4 items-start">
        <BaseText title={title} textColor="text-gray-500" />
      </div>
      <div className="mt-2 flex flex-row gap gap-2 px-4 pb-4">
        <div className={`${reviewerBg} rounded-full batch-name-container flex items-center justify-center`}>
          <XSText title={reviewerLogo} className="p-2" textColor={reviewerTextColor} fontWeight="text-bold" />
        </div>
        <div className="flex flex-row items-center gap gap-1">
          <MSText title={reviewerName} fontWeight="text-bold" />
          <div className="flex flex-row gap gap-1">
            <img src={Pin} alt="location" width="14" height="14" />
            <MSText title={reviewerLocation} textColor="text-gray-450" />
          </div>
        </div>
      </div>
    </div>
  );
}
Batch.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  reviewerBg: PropTypes.string,
  reviewerLogo: PropTypes.string,
  reviewerName: PropTypes.string,
  reviewerLocation: PropTypes.string,
  reviewerTextColor: PropTypes.string
};
Batch.defaultProps = {
  reviewerTextColor: 'text-gray-500'
};
