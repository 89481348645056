import { combineReducers } from 'redux';
import ConfirmEmailReducer from '../../components/confirmEmailAccount/reduxStore/reducer';
import LoginReducer from '../../components/login/reduxStore/reducer';
import SignupReducer from '../../components/signup/reduxStore/reducer';
import UserProfileReducer from '../../components/profile/reduxStore/reducer';
import AccountSettings from '../../components/accountSetting/reduxStore/reducer';
import CommonReduxStore from '../../components/commonReduxStore/reduxStore/reducer';
import SupplierReducer from '../../components/supplier/reduxStore/reducer';
import CompanyReducer from '../../components/company/reduxStore/reducer';
import CompanyIncorporationReducer from '../../components/companyIncorporation/reduxStore/reducer';
import NewAccountSetup from '../../components/new-company-signup/redux/reducer';
import OtpReducer from '../../components/otp/reduxStore/reducer';

const appReducer = combineReducers({
  signupReducer: SignupReducer,
  loginReucer: LoginReducer,
  confirmEmailReducer: ConfirmEmailReducer,
  userProfieReducer: UserProfileReducer,
  accountSettings: AccountSettings,
  otpReducer: OtpReducer,
  commonReduxStore: CommonReduxStore,
  supplierReducer: SupplierReducer,
  companyReducer: CompanyReducer,
  companyIncorporationReducer: CompanyIncorporationReducer,
  newAccountSetup: NewAccountSetup
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT' || action.type === 'RESET_REDUX_DATA') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
