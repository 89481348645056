import React from 'react';
import PlusIcon from '../../images/icons/customSvg/PlusIcon';
import { MUI_COLORS } from '../../constants/enum';
import InfoPopUp from '../ui/InfoPopUp';
import BaseText from '../ui/typography/BaseText';
import { StaticImage } from 'gatsby-plugin-image';
import CheckInCircle from '../../images/icons/customSvg/checkByStreamLineHq';

const SpecialPoint = () => {
  const info = 'Subject to requirements as per applicable regulations';
  return (
    <div className="flex flex-row gap-4 items-center">
      <div className="w-8 h-8 bg-green-200 rounded-full flex justify-center items-center">
        <CheckInCircle fill={MUI_COLORS.GREEN} />
      </div>
      <div className="md:py-4 py-6 flex flex-row md:gap-6 gap-2 justify-between items-center relative w-full">
        <div className="flex md:gap-6 gap-2 items-center justify-between ">
          <BaseText
            title={`Your new\nCompany`}
            fontWeight="text-bold"
            textAlign="text-center"
            className="whitespace-pre-line w-full"
            style={{ lineHeight: '100%' }}
          />
          <PlusIcon />
          <div className="flex">
            <BaseText
              textColor="text-coral-500"
              style={{ lineHeight: '100%' }}
              fontWeight="text-bold"
              textAlign="text-center"
              title={`Business \nAccount`}
              className="whitespace-pre-line w-full"
            />
            <InfoPopUp
              popUpClass={'mt-[-116px] w-[453px] ml-[-181px] z-10'}
              info={info}
              infoIconColor={MUI_COLORS.GREEN}
              iconWidth={15}
              iconHeight={15}
            />
          </div>
        </div>
        <StaticImage
          src="../../images/graphics/registerCompany/five-days.png"
          alt="FiveDays"
          quality={100}
          objectFit="contain"
          className="position-absolute md:ml-[274px] ml-[220px] z-[3] md:w-20 w-16"
        />
      </div>
    </div>
  );
};

export default SpecialPoint;
