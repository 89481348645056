import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as CompanyDetailsActions from '../../components/company/reduxStore/action';
import { API_CODE, PAGE_TITLE } from '../../constants/enum';
import Page from '../../components/Page';
import Layout from '../../components/layout';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import MailRoom from '../../components/company/mailRoom/MailRoom';

function CompanyMailroom({ companyProfileDetails, companyDocumentsDetails }) {
  const [documentsDetails, setDocumentsDetails] = useState([]);
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);
  const [companyId, setCompanyId] = useState();

  const companyRegistartion = async () => {
    const companyProfileResponse = await companyProfileDetails();
    if (companyProfileResponse?.status === API_CODE.STATUS_200) {
      setCompanyId(companyProfileResponse?.data?.data?._id);
    }
  };

  const companyDocuments = async () => {
    setIsDocumentsLoading(true);
    const companyDocumentsResponse = await companyDocumentsDetails();
    if (companyDocumentsResponse?.status === API_CODE.STATUS_200) {
      setDocumentsDetails(companyDocumentsResponse?.data?.data[0]?.mailRoomDocuments);
      setIsDocumentsLoading(false);
    }
  };

  useEffect(() => {
    companyDocuments();
    companyRegistartion();
  }, []);
  return (
    <Layout>
      <Page title="Company" className="w-full">
        <HeaderBreadcrumbs
          heading={PAGE_TITLE.COMPANY_MAILROOM}
          links={[{ name: '' }]}
          sx={{ height: '56px', display: 'flex', alignItems: 'center', marginBottom: '32px' }}
        />

        <div className="w-full">
          <MailRoom documentsDetails={documentsDetails} isDocumentsLoading={isDocumentsLoading} companyId={companyId} />
        </div>
      </Page>
    </Layout>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    companyProfileDetails: () => dispatch(CompanyDetailsActions.companyProfileDetails()),
    companyDocumentsDetails: () => dispatch(CompanyDetailsActions.companyDocumentsDetails())
  };
};

export default connect(null, mapDispatchToProps)(CompanyMailroom);

CompanyMailroom.propTypes = {
  companyProfileDetails: PropTypes.func,
  companyDocumentsDetails: PropTypes.func
};
