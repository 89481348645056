import React from 'react';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import { Link, navigate } from 'gatsby';
import StatrysLogo from '../../images/icons/statrysLogo.svg';
import MDSubtitleText from '../../components/ui/typography/MDSubtitleText';
import XSText from '../../components/ui/typography/XSText';
import TextInput from '../../components/ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import { API_CODE, REGEX } from '../../constants/enum';
import ValidationMessage from '../ui/ValidationMessage';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as ConfirmEmailActions from './reduxStore/action';
import { PATH_PAGE } from '../../routes/paths';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import { useSelector } from 'react-redux';
function ConfirmEmailAccountForm({ confirmEmail, emailFromLogin, path, resendCode }) {
  const emailRegex = REGEX.EMAIL_REGEX;
  const isLoading = useSelector((state) => state.confirmEmailReducer?.loading);
  const { handleSubmit, register, errors } = useForm();
  const onSubmit = async (data) => {
    let payload = {
      email: data?.email.trim()
    };
    if (path === 'resend-code') {
      const resendCodeResponse = await resendCode(payload);
      if (resendCodeResponse?.status === API_CODE.STATUS_200) {
        navigate(PATH_PAGE?.verifyEmail, {
          state: {
            fromResendCode: true,
            emailForConfirmation: data?.email.trim()
          }
        });
      }
    } else {
      const confirmEmailResponse = await confirmEmail(payload);
      if (confirmEmailResponse?.status === API_CODE.STATUS_200) {
        navigate(PATH_PAGE?.verifyEmail, {
          state: {
            fromForgotPassword: true,
            emailForConfirmation: data?.email.trim()
          }
        });
      }
    }
  };
  return (
    <div className="flex lg:px-32 md:px-20 px-8 w-full">
      <div className="flex flex-col justify-center h-full w-full">
        <img src={StatrysLogo} className="login-logo" alt="statrys-logo" />
        <MDSubtitleText className="md:whitespace-pre-line mt-8" title={`Confirm your \ne-mail account`} />
        <XSText
          title={`We will send you a code to ensure it is a valid e-mail \naddress so you can proceed to reset your password.`}
          className="mt-4 md:whitespace-pre-line"
        />
        <form id="confirm-email-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="md:w-10/12 mt-10 ">
            <TextInput
              defaultValue={emailFromLogin && emailFromLogin}
              name="email"
              label="Email Address"
              variant="filled"
              inputRef={register({
                required: true,
                pattern: {
                  value: emailRegex,
                  message: 'Please fill in a valid Email Address'
                }
              })}
              error={errors?.email ? true : false}
              helperText={errors?.email && <ValidationMessage title={'Please fill in a valid Email Address'} />}
            />
            {isLoading ? (
              <div className="flex items-center justify-center">
                <StatrysLoader />
              </div>
            ) : (
              <PrimaryButton
                id="confirm-email-account-btn"
                bgColor="bg-coral-500"
                className="button w-full mt-6"
                caption="Confirm"
                onClick={handleSubmit(onSubmit)}
              />
            )}

            <div className="flex items-center mt-8">
              <XSText title="Having issue" />
              <Link to="/cotactus" className="ml-1 text-coral-500">
                Contact Us
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    confirmEmail: (loginPayload) => dispatch(ConfirmEmailActions.confirmEmail(loginPayload)),
    resendCode: (resendCodePayload) => dispatch(ConfirmEmailActions.resendCode(resendCodePayload))
  };
};

export default connect(null, mapDispatchToProps)(ConfirmEmailAccountForm);
ConfirmEmailAccountForm.propTypes = {
  confirmEmail: PropTypes.func,
  emailFromLogin: PropTypes.string,
  path: PropTypes.string
};
