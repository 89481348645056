import React from 'react';
import Layout from '../components/layout';
import Page from '../components/Page';
import CompanyList from '../components/companyList/CompanyList';
import { SESSION_STORAGE_NAME } from '../constants/enum';

const CompanyListing = () => {
  const IsBrowser = typeof window !== 'undefined';
  const stateData = IsBrowser && window?.history?.state;
  return (
    <Layout noSideBar={true}>
      <Page title="Company List" className="h-full mt-4">
        <div className="flex items-center justify-center h-full">
          <CompanyList
            data={
              stateData?.userDetails ||
              JSON.parse(IsBrowser && sessionStorage.getItem(SESSION_STORAGE_NAME?.ALL_COMPANIES))
            }
          />
        </div>
      </Page>
    </Layout>
  );
};

export default CompanyListing;
