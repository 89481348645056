import React from 'react';
import LGSubtitleText from '../../ui/typography/LGSubtitleText';
import XXSText from '../../ui/typography/XXSText';
import LetterWithBox from '../../../images/graphics/company-incorporation/letter-with-box.png';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import StampOfStatrys from '../../../images/graphics/company-incorporation/stamp-of-statrys.png';
import XSText from '../../ui/typography/XSText';
import { useSelector } from 'react-redux';
import { OFFICE_ADDRESS, REGISTERED_COUNTRY } from '../../../constants/enum';

const RegistrationSuccessPage = () => {
  const companyAllInformation = useSelector(
    (state) => state?.companyIncorporationReducer?.getCompanyIncorporation?.data?.data || {}
  );

  return (
    <div className="p-8">
      <div className="flex md:flex-row flex-col md:gap-12 gap-6 w-full items-center">
        <div className="md:w-1/2 w-full">
          <LGSubtitleText title="Great! We are done!" textWeight="text-medium" />
          <XXSText
            textColor="text-gray-450"
            title="We're ready to begin the process of registering your company."
            className="mt-4 mb-2"
          />
          <XXSText textColor="text-gray-450" className="mt-16" title="Your new registered address:" />
          <div className="flex justify-between rounded-lg mt-4 border p-6">
            <div className="h-36 flex flex-col justify-end">
              <XSText fontWeight="text-bold" title={companyAllInformation?.companyName} />
              {companyAllInformation?.registrationCountry === REGISTERED_COUNTRY.HONGKONG && (
                <XSText textColor="text-gray-450 whitespace-pre-line" title={OFFICE_ADDRESS.HONG_KONG_ADDRESS} />
              )}
              {companyAllInformation?.registrationCountry === REGISTERED_COUNTRY.SINGAPORE && (
                <XSText textColor="text-gray-450 whitespace-pre-line" title={OFFICE_ADDRESS.SINGAPORE_ADDRESS} />
              )}
            </div>
            <div>
              <img src={StampOfStatrys} alt="stamp image of statrys" width="107" height="90" />
            </div>
          </div>
          {companyAllInformation?.isApproved && (
            <PrimaryButton
              caption="Take a look at our invoice platform"
              color="text-white"
              className="mt-16"
              bgColor="bg-green-500"
              isBorderRequired={true}
            />
          )}
        </div>
        <div className="md:w-1/2 w-full flex justify-center company-success-page-container">
          <img src={LetterWithBox} alt="let's start company" width="368" className="object-contain" />
        </div>
      </div>
    </div>
  );
};

export default RegistrationSuccessPage;
