import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StepsIndicator from '../ui/StepsIndicator';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import BackButton from '../ui/buttons/BackButton';
import ValidationMessage from '../ui/ValidationMessage';
import SelectInputAutoComplete from '../../components/ui/inputs/selectInputAutoComplete';
import TextInput from '../ui/inputs/TextInput';
import { connect } from 'react-redux';
import * as actionsForRegistrationNewCompany from './redux/action';
import IndustriesList from '../../data/industries-list.json';
function Industry({ prevStep, nextStep, newCompanyData, newCompanyAllInfo }) {
  const [error, setError] = useState({ industryError: '' });
  const [industry, setIndustry] = useState(newCompanyAllInfo?.industry);
  const [industryDescription, setIndustryDescription] = useState(newCompanyAllInfo?.industryDescription);
  const handleContinue = async () => {
    if (!industry) {
      setError({ industryError: true });
    } else {
      let newCompanyPayload = {
        ...newCompanyAllInfo,
        industry: industry,
        industryDescription: industryDescription
      };
      await newCompanyData(newCompanyPayload);
      nextStep();
    }
  };
  useEffect(() => {
    if (industry) {
      setError({ industryError: false });
    }
  }, [industry]);
  return (
    <div className="bg-white md:p-8 p-4 rounded-lg flex flex-col items-stretch justify-between company-registration-form-container">
      <StepsIndicator numberOfSteps={6} currentStepNumber={2} />
      <div className="flex flex-col justify-center step-field-container md:py-0 py-4">
        <MDSubtitleText
          className="whitespace-pre-line mb-6"
          fontWeight="text-bold"
          title={`What is the business of your company?`}
        />
        <SelectInputAutoComplete
          label="Industry"
          options={IndustriesList}
          value={industry}
          setSelectedValue={setIndustry}
          error={error.industryError ? true : false}
          helperText={error.industryError ? <ValidationMessage title="Please select a valid Industry" /> : null}
        />
        {industry === 'Others' && (
          <TextInput
            margin="mt-4"
            name="description"
            label="Description"
            placeholder="e.g. I create and sell artworks online."
            value={industryDescription}
            onChange={(e) => setIndustryDescription(e.target.value)}
          />
        )}
      </div>
      <div className="flex md:flex-row flex-col-reverse justify-between items-center w-full">
        <BackButton onClick={prevStep} className="w-full" />
        <PrimaryButton
          className="capitalize md:w-32 w-full onboarding-button"
          fontSize="text-base"
          caption="Continue"
          onClick={handleContinue}
          linkClass="md:w-auto w-full"
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    newCompanyAllInfo: state?.newAccountSetup?.newCompanyRegistrationData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    newCompanyData: (newCompanyRegistrationData) =>
      dispatch(actionsForRegistrationNewCompany.newCompanyRegistrationData(newCompanyRegistrationData))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Industry);

Industry.propTypes = {
  nextStep: PropTypes.number,
  prevStep: PropTypes.func,
  setCompletedSteps: PropTypes.func,
  completedSteps: PropTypes.bool,
  newCompanyData: PropTypes.func,
  newCompanyAllInfo: PropTypes.object
};
