import React from 'react';
import XSText from '../ui/typography/XSText';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { ENDPOINTS } from '../../service/end-points';
import { StaticImage } from 'gatsby-plugin-image';
import Prices from './Prices';
import H2HeaderText from '../ui/typography/H2HeaderText';
import DiscountGreenImage from '../../images/graphics/registerCompany/specialDiscountGreen.webp';
import { PATH_PAGE } from '../../routes/paths';

const CompanyRegistrationCTA = () => {
  return (
    <div className="bg-green-200 rounded-2xl mt-14 flex md:flex-row flex-col shadow-md relative overflow-hidden">
      <div className="cr-cta-blur md:block hidden"></div>
      <div className="md:pl-10 pl-4 md:pr-0 pr-4 md:pt-0 pt-4">
        <div className="flex md:flex-row flex-col h-full md:gap-0 gap-4 justify-between">
          <div className="flex flex-col justify-between md:py-12 py-0">
            <div className="flex md:flex-row flex-col-reverse justify-between">
              <div className="flex flex-col">
                <div className="flex flex-col">
                  <H2HeaderText
                    className="offer-and-price-section md:whitespace-pre-line text-bold"
                    title={`One package, One price \n All Included`}
                  />
                </div>
                <Prices buttonRequired={false} DiscountImage={DiscountGreenImage} />
              </div>
            </div>
            <MDSubtitleText
              className="mt-4"
              fontWeight="text-medium"
              title={
                <div
                  dangerouslySetInnerHTML={{
                    __html: `Save <span class="text-green-500 text-bold">HKD1,056</span> per year on <br class="md:block hidden"/>
    your Statrys Business Account`
                  }}
                ></div>
              }
            />
            <PrimaryButton
              caption="Get Started"
              urlLink={ENDPOINTS?.HONG_KONG_COMPANY_REGISTRATION}
              className="w-full md:mt-8 mt-6"
              bgColor="bg-green-500"
              isBorderRequired={true}
              fromCompanyCreationProductPage={true}
              path={PATH_PAGE.hkIncorporation}
            />
            <XSText title="100% Online application" className="mt-3" />
          </div>
          <StaticImage
            src="../../images/graphics/registerCompany/registration-success.png"
            alt="client got the company registration"
            loading="lazy"
            objectFit="contain"
            className="relative z-[2] flex self-end md:w-1/2 w-full"
          />
        </div>
      </div>
    </div>
  );
};
export default CompanyRegistrationCTA;
