import React, { useEffect, useState } from 'react';
import { Box, Card, Divider, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import useTable, { emptyRows } from '../../../hooks/useTable';
import { TableNoData, TableEmptyRows } from '../../table';
import XSText from '../../ui/typography/XSText';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StatrysLoader from '../../ui/loaders/StatrysLoader';
import BaseText from '../../ui/typography/BaseText';
import Scrollbar from '../../Scrollbar';
import { ROW_PER_PAGE_OPTIONS, STATUS_COLORS } from '../../../constants/enum';
import LatestUpdatesTable from '../../../sections/@company/LatestUpdatesTable';

function CompanyUpdatesTable({ latestUpdatesDetails, isLatestUpdatesLoading, handleToShowLatestData }) {
  const { page, rowsPerPage, selected, onSelectRow, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: 'dateCreated'
  });
  const [tableData, setTableData] = useState([]);

  const isNotFound = tableData?.length === 0;

  useEffect(() => {
    if (latestUpdatesDetails) {
      setTableData(latestUpdatesDetails);
    } else {
      setTableData([]);
    }
  }, [latestUpdatesDetails]);

  return (
    <>
      <Card
        sx={{
          '&.MuiPaper-root': {
            position: 'unset',
            boxShadow: 'none',
            border: `1px solid ${STATUS_COLORS.GREY}`
          }
        }}
      >
        <div className="m-6">
          <BaseText title="Latest updates" fontSize="text-lg" textColor="text-blue-100" fontWeight="text-bold" />
        </div>
        <Divider />
        {!isLatestUpdatesLoading ? (
          <>
            <Scrollbar>
              <TableContainer sx={{ position: 'relative' }}>
                <Table>
                  <TableBody>
                    {tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <>
                        <LatestUpdatesTable row={row} handleToShowLatestData={() => handleToShowLatestData(row)} />
                      </>
                    ))}

                    <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, tableData?.length)} />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[
                  ROW_PER_PAGE_OPTIONS.TEN,
                  ROW_PER_PAGE_OPTIONS.TWENTY_FIVE,
                  ROW_PER_PAGE_OPTIONS.FIFTY
                ]}
                component="div"
                sx={{
                  '&.MuiTablePagination-root': {
                    borderTop: 'none'
                  }
                }}
                count={tableData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            </Box>
          </>
        ) : (
          <StatrysLoader />
        )}
      </Card>
    </>
  );
}

export default CompanyUpdatesTable;

CompanyUpdatesTable.propTypes = {
  latestUpdatesDetails: PropTypes.array,
  isLatestUpdatesLoading: PropTypes.bool
};
