import React, { useState } from 'react';
import MDSubtitleText from '../../components/ui/typography/MDSubtitleText';
import XSText from '../ui/typography/XSText';
import VerifyOtpInput from '../otp/verifyOtpInput';
import Countdown, { zeroPad } from 'react-countdown';
import { Link, navigate } from 'gatsby';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PATH_PAGE } from '../../routes/paths';
import { useLocation } from '@reach/router';
import * as SignupActions from '../../components/signup/reduxStore/action';
function VerifyEmailForm({ email, fromForgotPassword, emailForConfirmation, fromResendCode, marketingSignup }) {
  const [resendLink, setResendLink] = useState(false);
  const [timeUp, setTimeUp] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location?.search);
  const queryEmail = query?.get('email')?.replace(/ /g, '+');
  if (queryEmail) {
    marketingSignup({ email: queryEmail });
  }
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setTimeUp(true);
      setResendLink(true);
      return <div></div>;
    } else {
      return (
        <span className={`${timeUp ? 'hidden' : 'block'} text-gray-400 ml-1 -mt-1`}>
          ({zeroPad(minutes)}:{zeroPad(seconds)})
        </span>
      );
    }
  };
  const reSendOtp = () => {
    navigate(PATH_PAGE?.confirmEmailAccount, {
      state: {
        email: email,
        path: 'resend-code'
      }
    });
  };

  return (
    <div className="verify-email-container flex flex-col justify-between h-full">
      <div className="login">
        <MDSubtitleText
          className="md:whitespace-pre-line"
          fontWeight="text-bold"
          title={`Verify your e-mail account`}
        />
      </div>
      <div className="mt-6">
        <XSText
          title={
            <div>
              A 6-digit code has been sent to{' '}
              <span className="text-bold">
                {emailForConfirmation ? emailForConfirmation : queryEmail ? queryEmail : email}
              </span>
            </div>
          }
          className="mt-4"
        />
        <XSText title="Enter the code to verify your e-mail." className="my-4" />
        <VerifyOtpInput
          fromForgotPassword={fromForgotPassword}
          fromResendCode={fromResendCode}
          emailForConfirmation={emailForConfirmation ? emailForConfirmation : queryEmail}
        />
      </div>
      <div className="mt-6 flex flex-col">
        <XSText title="Did not receive the code?" />
        <div className="flex mt-1">
          {queryEmail?.length ? (
            <a href={`${process.env.MARKETING_SITE_URL}/invoicing-software`}>
              <XSText
                className={resendLink ? 'cursor-pointer' : null}
                textColor={resendLink ? 'text-coral-500' : 'text-gray-400'}
                title="Resend the code"
              />
            </a>
          ) : (
            <div onClick={() => (resendLink ? reSendOtp() : null)}>
              <XSText
                className={resendLink ? 'cursor-pointer' : null}
                textColor={resendLink ? 'text-coral-500' : 'text-gray-400'}
                title="Resend the code"
              />
            </div>
          )}
          <div onClick={() => (resendLink ? reSendOtp() : null)}>
            <XSText
              className={resendLink ? 'cursor-pointer' : null}
              textColor={resendLink ? 'text-coral-500' : 'text-gray-400'}
              title="Resend the code"
            />
          </div>
          {<Countdown date={Date.now() + 60000 * 3} renderer={renderer} />}
          <XSText className="mx-1" title="or" />
          <Link to="/contact">
            <XSText textColor="text-coral-500" title="Contact us" />
          </Link>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    email: state?.signupReducer?.signupDetails?.data?.email
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    marketingSignup: (signupPayload) => dispatch(SignupActions.marketingSignup(signupPayload))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailForm);
VerifyEmailForm.propTypes = {
  email: PropTypes.string,
  fromForgotPassword: PropTypes.bool,
  emailForConfirmation: PropTypes.string,
  fromResendCode: PropTypes.bool,
  signup: PropTypes.func
};

VerifyEmailForm.defaultProps = {
  fromForgotPassword: false
};
