import React, { useState, useEffect, useRef } from 'react';
import BaseText from './typography/BaseText';
import PropTypes from 'prop-types';
import { getAvatarBackgroundColor } from '../../helper/helpers';
import { AVATAR_COlORS } from '../../constants/enum';
export default function Avatar({ name, avatarName, avatarStyling, avatarImage, fontSize, borderRadius }) {
  const [avatarTextColor, setAvatarTextColor] = useState('text-white');
  const avatarText = name ? name.charAt(0) : '-';
  const box = useRef(0);

  useEffect(() => {
    if (!avatarImage) {
      const avatarBG = getAvatarBackgroundColor(name ? avatarText : avatarName);
      box.current.style.backgroundColor = getAvatarBackgroundColor(name ? avatarText : avatarName);
      if (
        avatarBG == AVATAR_COlORS[4] ||
        avatarBG == AVATAR_COlORS[5] ||
        avatarBG == AVATAR_COlORS[6] ||
        avatarBG == AVATAR_COlORS[7]
      ) {
        setAvatarTextColor('text-gray-300');
      } else {
        setAvatarTextColor('text-white');
      }
    }
  }, [avatarName]);

  return (
    <div>
      {avatarImage ? (
        <img className={`${avatarStyling} rounded-full object-cover`} src={avatarImage} alt="avatarImage" />
      ) : (
        <div ref={box} className={`bg-purple-500 ${borderRadius} flex items-center justify-center ${avatarStyling}`}>
          <BaseText
            className="uppercase"
            fontWeight="text-bold"
            title={name ? avatarText : avatarName}
            fontSize={fontSize}
            textColor={avatarTextColor}
          />
        </div>
      )}
    </div>
  );
}

Avatar.propTypes = {
  name: PropTypes.string,
  avatarName: PropTypes.string,
  avatarStyling: PropTypes.string,
  borderRadius: PropTypes.string
};

Avatar.defaultProps = {
  avatarStyling: 'w-12 h-12',
  borderRadius: 'rounded-full'
};
