import { MatchingOopsApiErrorStatus, Matching401ApiErrorStatus, redirectUserToLogin } from '../../helper/helpers';
import { STATUS } from '../../constants/enum';
import { TrackJS } from 'trackjs';
import axios from 'axios';
import { navigate } from 'gatsby';

const BASE_API_URL = process.env.MARKETING_BASE_API_URL;
const instance = axios.create({
  baseURL: BASE_API_URL
});
const sourceMetadataEndpoints = [
  //eslint-disable-next-line
  { url: '^(/auth-management/).*$', exactMatch: false },
  { url: '^(/onboarding-management/).*$', exactMatch: false },
  { url: '/v2/otp', exactMatch: true },
  { url: '/v1/otp/verify', exactMatch: true },
  //eslint-disable-next-line
  { url: '^(/v1/users/)[^//]{0,36}$', exactMatch: false },
  { url: '/v1/users', exactMatch: true },
  { url: '/v1/roles', exactMatch: true },
  { url: '/v1/password', exactMatch: true },
  { url: '/v2/password', exactMatch: true },
  { url: '/v1/passwordToken/validate', exactMatch: true },
  { url: '/v1/password/recovery', exactMatch: true },
  { url: '/v1/password/resendLink', exactMatch: true },
  { url: '/.well-known/jwks.json', exactMatch: true },
  { url: '/v1/user', exactMatch: true }
];
const isSourceMetadataRequired = (url) => {
  let endpoint = sourceMetadataEndpoints.find((endpoint) => {
    if (endpoint.exactMatch) {
      return url == endpoint.url;
    } else {
      const rExp2 = new RegExp(endpoint.url, 'g');
      return url.match(rExp2) != undefined;
    }
  });
  return endpoint != null;
};
instance.interceptors.request.use(
  async (config) => {
    const token = await sessionStorage.getItem('userLogin');
    if (token && token.length > 0) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    let appVersion = await sessionStorage.getItem('app-version');
    let requiredUrl = config?.url;
    let isBaseUrlIncluded = requiredUrl.includes(process.env.BASE_API_URL);
    if (isBaseUrlIncluded) {
      requiredUrl = requiredUrl.replace(process.env.BASE_API_URL, '');
    }
    if (requiredUrl.charAt(0) === '/') {
      requiredUrl = requiredUrl.replace('/', '');
    }
    let metadataRequiredStatus = isSourceMetadataRequired(`/${requiredUrl}`);
    if (appVersion && appVersion.length > 0 && metadataRequiredStatus) {
      config.headers['platform-version'] = `COMPANY_INCORPORATION-${appVersion.toString()}`;
    }
    return config;
  },
  (err) => {
    return err;
  }
);

const responseHandler = (response) => {
  return response;
};
instance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => {
    let errorObj = error.response;
    let errorTrackJSObj = error.response?.request?._trackJs;
    if (errorObj) {
      TrackJS.console.log({
        message: errorObj?.data?.message,
        url: errorTrackJSObj?.url,
        status: errorObj.status,
        statusText: errorObj.statusText,
        response: errorObj.data
      });

      TrackJS.track(
        errorObj.status + ' ' + errorObj.statusText + ': ' + errorTrackJSObj?.method + ' ' + errorTrackJSObj?.url
      );
    }
    if (process.env.MAINTENANCE_FLOW_ENABLED === STATUS.TRUE) {
      if (typeof window !== `undefined`) {
        navigate('/under-maintenance');
      }
    }
    if (MatchingOopsApiErrorStatus(error)) {
      navigate('/oops', {
        state: {
          message: error?.response?.data?.message,
          errorList: error?.response?.data?.items,
          apiErrorCode: error?.response?.data?.code,
          apiStatusCode: error?.response?.status
        }
      });
    }

    if (Matching401ApiErrorStatus(error)) {
      redirectUserToLogin();
    }

    return Promise.reject(error);
  }
);

export default instance;
