import React from 'react';
import PropTypes from 'prop-types';

function H2HeaderText({ title, className, fontWeight, textColor, textAlign, textItalic }) {
  return (
    <div className={`${className}`}>
      <h2 className={`${fontWeight}  ${textColor} ${textAlign}`}>
        {title} <span className="italic">{textItalic}</span>
      </h2>
    </div>
  );
}

export default H2HeaderText;

H2HeaderText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  textColor: PropTypes.string,
  fontWeight: PropTypes.string,
  textAlign: PropTypes.string,
  textItalic: PropTypes.string
};

H2HeaderText.defaultProps = {
  textColor: 'text-gray-500',
  textAlign: 'text-left',
  fontWeight: 'text-bold'
};
