import React from 'react';
import IconAssetAsian from '../../images/graphics/Logo/asset-asian.svg';
import Award from '../../images/graphics/Logo/award.png';
import config from '../../config/config.json';
import '../../styles/footer.scss';
import Dbs from '../common/Dbs';
import Logo from '../ui/Logo';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function Footer() {
  const { width } = useWindowDimensions();
  return (
    <footer className="footer">
      <div className="footer-links flex md:flex-row flex-col items-start justify-between gap-4">
        <div>
          <Logo />
          <div className="copy-right">
            © Copyright {new Date().getFullYear()} Statrys Ltd. <br />
            All rights reserved.
          </div>
        </div>
        <Dbs
          type="retail-award"
          image={Award}
          awardTitle={`SME Payment Solutions of \nthe Year - Hong Kong`}
          noNeedBottomImage={true}
          customWidth="123.787"
          className="statrys-certificate"
        />
        <Dbs
          type="dbs"
          image={IconAssetAsian}
          customWidth={width < 767 ? 45 : 56}
          awardTitle={`Best Payments and Collections\n Solution Hong Kong 2022`}
          className="statrys-certificate-dbs"
        />
        <div className="footer-contact-details div-link">
          <div className="flex flex-row justify-between items-start">
            <p className="text-bold mb-2 text-coral-500">Contact</p>
            <div className="gap-2 flex items-center">
              <div className="item">
                <a href={config.social_media.linkedIn} target="_blank" rel="noreferrer">
                  <img src="../../../linkedin.svg" loading="lazy" width="14" height="15" alt="LinkedIn Icon" />
                </a>
              </div>

              <div className="item">
                <a href={config.social_media.fb} target="_blank" rel="noreferrer">
                  <img src="../../../facebook.svg" loading="lazy" width="16" height="16" alt="Facebook Icon" />
                </a>
              </div>

              <div className="item">
                <a href={config.social_media.youtube} target="_blank" rel="noreferrer">
                  <img src="../../../youtube.svg" loading="lazy" width="16" height="12" alt="YouTube Icon" />
                </a>
              </div>
            </div>
          </div>

          <ul>
            <li className="h-4">
              <a href={`tel:${config.phone_number}`}>
                <div className="flex items-center">
                  <img src="../../../phone.svg" loading="lazy" width="17" height="17" alt="Icon Phone" />
                  <span>{config.phone_number}</span>
                </div>
              </a>
            </li>

            <li className="h-4">
              <a href={config.whatsapp_link}>
                <div className="flex items-center">
                  <img src="../../../whatsapp.svg" loading="lazy" width="17" height="17" alt="Icon Whatsapp" />
                  <span>{config.whatsapp}</span>
                </div>
              </a>
            </li>

            <li className="h-4">
              <a href={config.email_support_link}>
                <div className="flex items-center">
                  <img src="../../../mailbox.svg" loading="lazy" width="17" height="17" alt="Icon Mailbox" />
                  <span>{config.email_support}</span>
                </div>
              </a>
            </li>
            <li>
              <div className="flex items-start">
                <img src="../../../location.svg" loading="lazy" width="16" height="16" alt="location" />
                <div className="flex flex-col">
                  <p>{config.location.l1}</p>
                  <p>{config.location.l2}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="mt-7">
        <p className="footer-text whitespace-pre-line  w-full text-gray-300 pt-5 md:pt-0">
          {config.footerText.lineOne}
        </p>
      </div>
    </footer>
  );
}
export default Footer;
