import React, { useEffect, useState } from 'react';
import AlertTriangle from '../../../images/icons/alert-triangle.svg';
import BackButton from '../../ui/buttons/BackButton';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import MSText from '../../ui/typography/MSText';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import { COMPANY_SETUP_STEP, OWNER_DIRECTOR_TYPES, REGEX } from '../../../constants/enum';
import TextInput from '../../ui/inputs/TextInput';
import XSText from '../../ui/typography/XSText';
import { handleEnter } from '../../../helper/helpers';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { FormControl } from '@mui/material';
import SelectInputAutoComplete from '../../ui/inputs/selectInputAutoComplete';
import { useLocation } from '@reach/router';
import { FILE_EXTENTION_TYPE } from '../../../constants/enum';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ValidationMessage from '../../ui/ValidationMessage';
import Countries from '../../../data/countries.json';
import EditOwnerDirectorDetails from './EditOwnerDetails';

const ownerDirectorData = {
  firstName: 'Sunil',
  lastName: 'Prajapati',
  type: 'OWNER_DIRECTOR',
  code: '---'
};

function PersonalInformationForm({
  nextStep,
  prevStep,
  isAddingOwner,
  pdfURL,
  passportUploadResponse,
  isOwnerDirectorEdit,
  setStep,
  stackHolderType,
  currentStackHolder,
  setCurrentStackHolder,
  companyAllInformation,
  stackHolderPayload
}) {
  const { register, handleSubmit, errors, setValue, getValues } = useForm();
  const [error, setError] = useState({ dobError: '', invalidDate: false, nationalityError: false, expiryDate: false });
  const [nationality, setNationality] = useState(currentStackHolder?.nationality);
  const location = useLocation();
  const [imageUrl, setImageUrl] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function handlePageShifting(index) {
    setPageNumber(index + 1);
  }

  const getFileExtension = (filename) => {
    const extension = filename?.split('.').pop();
    return extension;
  };

  const handleChangeNumber = (min, max) => (event) => {
    const { name, value } = event.target;
    let numberValue = value.replace(/\D/g, '');
    let inputNumber = parseInt(numberValue, 10);
    if (inputNumber > max) inputNumber = max;
    if (inputNumber < min) inputNumber = min;
    if ((event.target.name === 'day' || event.target.name === 'month') && event.target.value.length > 1) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
    if (event.target.name === 'year' && numberValue > max) {
      setError({ dobError: 'You must be at least 18 years old to apply' });
    } else {
      setError({ dobError: '' });
    }
    setValue(name, numberValue ? numberValue : '');
    validateDateOfBirth();
  };

  const validateDateOfBirth = () => {
    const { day, month, year } = getValues(['day', 'month', 'year']);

    if (year && month > 0 && day && !moment([year, month - 1, day]).isValid()) {
      setError({ invalidDate: true });
    } else {
      setError({ invalidDate: false });
    }
  };
  const onSubmit = (data) => {
    if (nationality) {
      if (!error.dobError) {
        if (moment(data?.expiresDate).isBefore(moment(data?.issuedDate))) {
          setError({ ...error, expiryDate: true });
        } else {
          if (
            stackHolderPayload?.length === 0 &&
            (companyAllInformation?.stakeholder?.[0]?.isMainUser === false ||
              companyAllInformation?.stakeholder?.length === 0)
          ) {
            setCurrentStackHolder({
              ...currentStackHolder,
              email: companyAllInformation?.email,
              firstName: data.firstName || companyAllInformation?.stakeholder?.[0]?.firstName,
              isDirector:
                stackHolderType === OWNER_DIRECTOR_TYPES?.DIRECTOR ||
                stackHolderType === OWNER_DIRECTOR_TYPES.OWNER_DIRECTOR
                  ? true
                  : false,
              isShareholder:
                stackHolderType === OWNER_DIRECTOR_TYPES?.OWNER ||
                stackHolderType === OWNER_DIRECTOR_TYPES.OWNER_DIRECTOR
                  ? true
                  : false,
              isDisabled: false,
              isMainUser: true,
              isContactPerson: true,
              isSignificantController: companyAllInformation?.stakeholder?.[0]?.isSignificantController,
              lastName: data.lastName,
              phoneNumber: companyAllInformation?.stakeholder?.[0]?.phoneNumber,
              id: companyAllInformation?.stakeholder?.[0]?._id,
              dateOfBirth: {
                day: data.day,
                month: data.month,
                year: data.year
              },
              passportId: data?.passportNumber || passportUploadResponse?.idNumber?.value,
              expiry: data?.expiresDate,
              issued: data?.issuedDate,
              nationality: nationality,
              stackHolderType: OWNER_DIRECTOR_TYPES?.OWNER
            });
          } else {
            setCurrentStackHolder({
              ...currentStackHolder,
              isDirector:
                stackHolderType === OWNER_DIRECTOR_TYPES?.DIRECTOR ||
                stackHolderType === OWNER_DIRECTOR_TYPES.OWNER_DIRECTOR
                  ? true
                  : false,
              isShareholder:
                stackHolderType === OWNER_DIRECTOR_TYPES?.OWNER ||
                stackHolderType === OWNER_DIRECTOR_TYPES.OWNER_DIRECTOR
                  ? true
                  : false,
              firstName: data.firstName,
              lastName: data.lastName,
              dateOfBirth: {
                day: data.day,
                month: data.month,
                year: data.year
              },
              passportId: passportUploadResponse?.idNumber?.value || data?.passportNumber,
              expiry: data?.expiresDate,
              issued: data?.issuedDate,
              nationality: nationality,
              stackHolderType: stackHolderType
            });
          }
          nextStep();
        }
      }
    } else {
      setError({ ...error, nationalityError: true });
    }
  };
  const back = () => {
    if (isOwnerDirectorEdit) {
      setStep(COMPANY_SETUP_STEP?.OWNERS_AND_DIRECTOR_SELECTOR);
    } else {
      prevStep();
    }
  };

  useEffect(() => {
    if (nationality) {
      setError({ ...error, nationalityError: false });
    }
  }, [nationality]);
  useEffect(() => {
    if (location?.state?.urlOfImage) {
      setImageUrl(location?.state?.urlOfImage);
    } else {
      setImageUrl(pdfURL);
    }
  }, [passportUploadResponse]);

  const findCountryByCode = () => {
    const countryByCode = Countries.find((country) => country?.code === passportUploadResponse?.country?.value);
    if (countryByCode) {
      setNationality(countryByCode);
    }
    return countryByCode?.name;
  };

  return (
    <div className="flex flex-col md:gap-8 gap-6 w-full h-full">
      <MDSubtitleText title="Owners and directors" fontWeight="text-bold" />
      <div className="flex md:flex-row flex-col-reverse">
        <div className="md:w-4/6 w-full mr-14">
          {isOwnerDirectorEdit && (
            <EditOwnerDirectorDetails
              firstName={ownerDirectorData.firstName}
              lastName={ownerDirectorData.lastName}
              type={ownerDirectorData.type}
              cardCode={ownerDirectorData.code}
            />
          )}
          <XSText
            className="mt-6 md:whitespace-pre-line"
            title={`Verify that ${
              isAddingOwner ? 'their' : 'your'
            } personal information is accurate.\nIf there are any errors, please make the necessary edits.`}
          />
          <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
            <FormControl className="w-full">
              <XSText className="mt-6" title="Name" />
              <div className="flex flex-col md:flex-row gap-4 mt-2">
                <TextInput
                  onKeyDown={handleEnter}
                  name="firstName"
                  label="First Name"
                  textFieldContainerClass="w-full"
                  defaultValue={
                    currentStackHolder?.firstName
                      ? currentStackHolder?.firstName
                      : passportUploadResponse?.givenNames?.[0]?.value
                  }
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'Please fill in a valid First Name'
                    },
                    pattern: {
                      value: REGEX.ONLY_ALPHABETS_AND_SPACE_ALLOWED,
                      message: 'Only alphabets and whitespaces are allowed.'
                    }
                  })}
                  error={errors.name ? true : false}
                  helperText={errors.name ? <ValidationMessage title={errors.name.message} /> : null}
                />
                <TextInput
                  onKeyDown={handleEnter}
                  defaultValue={
                    currentStackHolder?.lastName ? currentStackHolder?.lastName : passportUploadResponse?.surname?.value
                  }
                  name="lastName"
                  label="Last Name"
                  textFieldContainerClass="w-full"
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'Please fill in a valid Last Name'
                    },
                    pattern: {
                      value: REGEX.ONLY_ALPHABETS_AND_SPACE_ALLOWED,
                      message: 'Only alphabets and whitespaces are allowed.'
                    }
                  })}
                  error={errors.lastName ? true : false}
                  helperText={errors.lastName ? <ValidationMessage title={errors.lastName.message} /> : null}
                />
              </div>

              <XSText className="mt-8" title={'Date of birth'} />
              <div className="flex flex-col md:flex-row gap-4 mt-2 w-full">
                <TextInput
                  onKeyDown={handleEnter}
                  defaultValue={
                    currentStackHolder?.dateOfBirth?.day
                      ? currentStackHolder?.dateOfBirth?.day
                      : passportUploadResponse?.birthDate?.value
                      ? moment(passportUploadResponse?.birthDate?.value, 'YYYY/MM/DD').date()
                      : ''
                  }
                  type="text"
                  name="day"
                  label="Day"
                  textFieldContainerClass="w-full"
                  placeholder="DD"
                  onChange={handleChangeNumber(1, 31)}
                  max={2}
                  inputRef={register({
                    required: true,
                    min: 1,
                    max: 31
                  })}
                  error={errors.day ? true : false}
                />
                <TextInput
                  onKeyDown={handleEnter}
                  defaultValue={
                    currentStackHolder?.dateOfBirth?.month
                      ? currentStackHolder?.dateOfBirth?.month
                      : passportUploadResponse?.birthDate?.value
                      ? moment(passportUploadResponse?.birthDate?.value, 'YYYY/MM/DD').month() + 1
                      : ''
                  }
                  type="text"
                  name="month"
                  label="Month"
                  textFieldContainerClass="w-full"
                  placeholder="MM"
                  onChange={handleChangeNumber(1, 12)}
                  max={2}
                  inputRef={register({
                    required: true,
                    min: 1,
                    max: 12
                  })}
                  error={errors.month ? true : false}
                />
                <TextInput
                  onKeyDown={handleEnter}
                  defaultValue={
                    currentStackHolder?.dateOfBirth?.year
                      ? moment(currentStackHolder?.dateOfBirth?.year, 'YYYY/MM/DD').year()
                      : passportUploadResponse?.birthDate?.value
                      ? moment(passportUploadResponse?.birthDate?.value, 'YYYY/MM/DD').year()
                      : ''
                  }
                  type="text"
                  name="year"
                  label="Year"
                  textFieldContainerClass="w-full"
                  placeholder="YYYY"
                  onChange={handleChangeNumber(1, new Date().getFullYear() - 18)}
                  max={4}
                  inputRef={register({
                    required: true,
                    min: 1900,
                    max: new Date().getFullYear() - 18
                  })}
                  error={errors.year ? true : false}
                />
              </div>
              {errors.day || errors.month || errors.year || error.invalidDate ? (
                <div className="ml-5 mt-2 flex">
                  <img src={AlertTriangle} alt="alert triangle" />
                  <MSText
                    className="ml-2"
                    textColor="select-input-error"
                    title={'Please fill in a valid Date of birth'}
                  />
                </div>
              ) : null}
              {error.dobError ? (
                <div className="ml-5 mt-2 flex">
                  <img src={AlertTriangle} alt="alert triangle" />
                  <MSText className="ml-2" textColor="select-input-error" title={error.dobError} />
                </div>
              ) : null}
              <XSText className="mt-8 mb-2" title={'Passport'} />
              <div className="flex flex-col md:flex-row gap-4 mt-2">
                <TextInput
                  onKeyDown={handleEnter}
                  name="passportNumber"
                  label="Passport Number"
                  textFieldContainerClass="w-full"
                  inputRef={register()}
                  defaultValue={
                    currentStackHolder?.passportId
                      ? currentStackHolder?.passportId
                      : passportUploadResponse?.idNumber?.value
                  }
                />
                <div className="w-full">
                  <SelectInputAutoComplete
                    label="Country of issue"
                    id="new-owner-nationality"
                    options={Countries}
                    defaultValue={
                      currentStackHolder?.nationality
                        ? () => findCountryByCode(currentStackHolder?.nationality)
                        : () => findCountryByCode(nationality)
                    }
                    value={currentStackHolder?.nationality}
                    setSelectedValue={setNationality}
                    error={error.nationalityError ? true : false}
                    helperText={
                      error.nationalityError ? <ValidationMessage title="Please fill in your Nationality" /> : null
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 mt-2">
                <TextInput
                  onKeyDown={handleEnter}
                  name="issuedDate"
                  label="Issued"
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  textFieldContainerClass="w-full"
                  defaultValue={
                    currentStackHolder?.issued
                      ? currentStackHolder?.issued
                      : passportUploadResponse?.issuanceDate?.value
                  }
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'Please fill in a valid Issued Date'
                    }
                  })}
                  error={errors.issuedDate ? true : false}
                  helperText={
                    errors.issuedDate ? (
                      <div className="flex gap-2 items-center">
                        <img src={AlertTriangle} alt="alert triangle" />
                        {errors.issuedDate.message}
                      </div>
                    ) : null
                  }
                />
                <TextInput
                  onKeyDown={handleEnter}
                  name="expiresDate"
                  label="Expires"
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  textFieldContainerClass="w-full"
                  defaultValue={
                    currentStackHolder?.expiry ? currentStackHolder?.expiry : passportUploadResponse?.expiryDate?.value
                  }
                  inputRef={register({
                    required: {
                      value: true,
                      message: 'Please fill in a valid Expires Date'
                    }
                  })}
                  error={errors.expiresDate || error?.expiryDate ? true : false}
                  helperText={
                    errors.expiresDate ? (
                      <div className="flex gap-2 items-center">
                        <img src={AlertTriangle} alt="alert triangle" />
                        {errors.expiresDate.message}
                      </div>
                    ) : null
                  }
                />
              </div>
              {error?.expiryDate && <ValidationMessage title="expiry date is not valid" />}
            </FormControl>
          </form>
        </div>
        <div className="md:w-2/6 md:mt-0 mt-4 w-full flex flex-col justify-center items-center">
          <>
            {getFileExtension(location?.state?.urlOfImage ? imageUrl : pdfURL) === FILE_EXTENTION_TYPE.PNG ||
            getFileExtension(location?.state?.urlOfImage ? imageUrl : pdfURL) === FILE_EXTENTION_TYPE.JPEG ||
            getFileExtension(location?.state?.urlOfImage ? imageUrl : pdfURL) === FILE_EXTENTION_TYPE.JPG ? (
              <img className="passport-upload-container" src={imageUrl ? imageUrl : pdfURL} alt="Uploaded Image" />
            ) : (
              <div>
                <Document
                  file={
                    currentStackHolder?.passportImageDocument ? currentStackHolder?.passportImageDocument : imageUrl
                  }
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <div className="importing-invoice-pdf-preview">
                    <Page pageNumber={pageNumber} key={pageNumber} />
                  </div>
                  {pageNumber > 0 && (
                    <div className="importing-invoice-multi-pages flex mt-6 ">
                      {Array.from(new Array(numPages), (el, index) => (
                        <>
                          {numPages > 1 && (
                            <div className={`${index + 1 === pageNumber && 'active-pdf-tab'} mr-4`}>
                              <Page
                                className={`other-pdf-container cursor-pointer`}
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                width={70}
                                height={100}
                                onClick={() => handlePageShifting(index)}
                              />
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  )}
                </Document>
              </div>
            )}
            <PrimaryButton
              className="capitalize onboarding-button py-6 px-4 mt-4 w-full"
              linkClass="w-full"
              bgColor="bg-white"
              color="text-coral-500"
              fontSize="text-base"
              caption="Upload again"
              onClick={back}
            />
          </>
        </div>
      </div>

      <div className="flex md:flex-row flex-col-reverse justify-between">
        <BackButton onClick={back} className="md:w-[114px] w-full" />
        <PrimaryButton
          caption="Continue"
          color="text-white"
          className="w-full"
          isBorderRequired={true}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
}

export default PersonalInformationForm;
