import React, { useState } from 'react';
import PropTypes from 'prop-types';
import StepsIndicator from '../ui/StepsIndicator';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import XSText from '../ui/typography/XSText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import BackButton from '../ui/buttons/BackButton';
import { connect } from 'react-redux';
import * as actionsForRegistrationNewCompany from './redux/action';
import ValidationMessage from '../ui/ValidationMessage';

function CompanyOwners({ prevStep, nextStep, newCompanyData, newCompanyAllInfo }) {
  const [selectedNumberOfOwners, setSelectedNumberOfOwners] = useState(newCompanyAllInfo?.selectedNumberOfOwners);
  const [selectedOwnerError, setSelectedOwnerError] = useState(false);
  const handleContinue = async () => {
    if (selectedNumberOfOwners) {
      let newCompanyPayload = {
        ...newCompanyAllInfo,
        selectedNumberOfOwners: selectedNumberOfOwners
      };
      await newCompanyData(newCompanyPayload);
      nextStep();
    } else {
      setSelectedOwnerError(true);
    }
  };
  const handleSelectedOwner = (value) => {
    setSelectedNumberOfOwners(value);
    setSelectedOwnerError(false);
  };
  return (
    <div className="bg-white md:p-8 p-4 rounded-lg flex flex-col items-stretch justify-between company-registration-form-container w-full">
      <StepsIndicator numberOfSteps={6} currentStepNumber={1} />
      <div className="flex flex-col justify-center step-field-container">
        <MDSubtitleText
          className="whitespace-pre-line mb-6"
          fontWeight="text-bold"
          title={`Tell us the number of owners in your company`}
        />
        <div className="flex gap-2">
          {[1, 2, 3, 4, 5, 6].map((value) => (
            <div
              className={`w-12 cursor-pointer h-12 border flex rounded-lg items-center justify-center border-gray-400 ${
                selectedNumberOfOwners === value && 'bg-coral-500'
              }`}
              key={value}
              onClick={() => handleSelectedOwner(value)}
            >
              <XSText
                textColor={selectedNumberOfOwners === value && 'text-white'}
                title={`${value}${value === 6 ? '+' : ''}`}
              />
            </div>
          ))}
        </div>
        {selectedOwnerError && <ValidationMessage title="Please select number of owners in your company" />}
      </div>
      <div className="flex md:flex-row flex-col-reverse justify-between items-center w-full">
        <BackButton onClick={prevStep} className="w-full" />
        <PrimaryButton
          className="capitalize md:w-32 w-full onboarding-button"
          fontSize="text-base"
          caption="Continue"
          onClick={handleContinue}
          linkClass="md:w-auto w-full"
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    newCompanyAllInfo: state?.newAccountSetup?.newCompanyRegistrationData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    newCompanyData: (newCompanyRegistrationData) =>
      dispatch(actionsForRegistrationNewCompany.newCompanyRegistrationData(newCompanyRegistrationData))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyOwners);
CompanyOwners.propTypes = {
  nextStep: PropTypes.number,
  prevStep: PropTypes.func,
  setCompletedSteps: PropTypes.func,
  completedSteps: PropTypes.bool,
  newCompanyData: PropTypes.func,
  newCompanyAllInfo: PropTypes.object
};
