import React from 'react';
import XMSText from '../ui/typography/XMSText';

const Badge = ({ color, title }) => {
  return (
    <div className={`border border-${color} bg-${color} width-fit-content rounded-md`}>
      <div className="py-1 px-2">
        <XMSText title={title} fontWeight="text-bold" />
      </div>
    </div>
  );
};

export default Badge;
