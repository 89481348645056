import React from 'react';
import '../styles/pages/login.scss';
import LoginComponent from '../components/login/Login';
import ParentLoader from '../components/ui/loaders/ParentLoader';

export default function Login() {
  return (
    <>
      <ParentLoader />
      <LoginComponent />
    </>
  );
}
