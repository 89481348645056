import React, { useState } from 'react';
import MoneyBackGuarantee from '../common/MoneyBackGuarantee';
import BaseText from '../ui/typography/BaseText';
import { StaticImage } from 'gatsby-plugin-image';

const GuaranteedBatch = () => {
  const [openMoneyBackModal, setOpenMoneyBackModal] = useState(false);
  const closeMoneyBackModal = () => {
    setOpenMoneyBackModal(false);
  };
  return (
    <>
      <div className="bg-yellow-300 rounded-lg relative">
        <div className="px-6 md:py-6 py-4 flex md:flex-col flex-col-reverse md:items-start items-center">
          <div className="md:mt-0 mt-2">
            <BaseText
              fontWeight="text-bold"
              textAlign="md:text-left text-center"
              title="Satisfaction Guaranteed or get your money back"
            />
            <BaseText
              className="md:mt-1 mt-2 md:whitespace-normal whitespace-pre-line"
              textAlign="md:text-left text-center"
              title={
                <div className="refund-line">
                  <p>
                    {`We will refund our fees if you're not satisfied with our service within the first 30 days.\n`}
                    {
                      <span
                        className="hover:text-coral-500 cursor-pointer text-bold ml-1"
                        onClick={() => setOpenMoneyBackModal(true)}
                      >
                        Learn more.
                      </span>
                    }
                  </p>
                </div>
              }
            />
          </div>
          <StaticImage
            src="../../images/graphics/registerCompany/registration-logo.png"
            className="money-back-logo"
            alt="Money back logo"
            loading="lazy"
            width="114"
            height="114"
          />
        </div>
      </div>
      <MoneyBackGuarantee openMoneyBackModal={openMoneyBackModal} closeMoneyBackModal={closeMoneyBackModal} />
    </>
  );
};

export default GuaranteedBatch;
