import React from 'react';
import PropTypes from 'prop-types';
import { MUI_COLORS } from '../../../constants/enum';

export default function Trash({ fill }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.75 3.5H11V2.2475C10.9824 1.76736 10.7752 1.31379 10.4237 0.986215C10.0722 0.658641 9.60518 0.483791 9.125 0.499996H6.875C6.39482 0.483791 5.9278 0.658641 5.57632 0.986215C5.22485 1.31379 5.01759 1.76736 5 2.2475V3.5H1.25C1.05109 3.5 0.860322 3.57901 0.71967 3.71967C0.579018 3.86032 0.5 4.05108 0.5 4.25C0.5 4.44891 0.579018 4.63967 0.71967 4.78033C0.860322 4.92098 1.05109 5 1.25 5H2V13.25C2 13.8467 2.23705 14.419 2.65901 14.841C3.08097 15.2629 3.65326 15.5 4.25 15.5H11.75C12.3467 15.5 12.919 15.2629 13.341 14.841C13.7629 14.419 14 13.8467 14 13.25V5H14.75C14.9489 5 15.1397 4.92098 15.2803 4.78033C15.421 4.63967 15.5 4.44891 15.5 4.25C15.5 4.05108 15.421 3.86032 15.2803 3.71967C15.1397 3.57901 14.9489 3.5 14.75 3.5ZM6.5 2.2475C6.5 2.1275 6.6575 2 6.875 2H9.125C9.3425 2 9.5 2.1275 9.5 2.2475V3.5H6.5V2.2475ZM12.5 13.25C12.5 13.4489 12.421 13.6397 12.2803 13.7803C12.1397 13.921 11.9489 14 11.75 14H4.25C4.05109 14 3.86032 13.921 3.71967 13.7803C3.57902 13.6397 3.5 13.4489 3.5 13.25V5H12.5V13.25ZM5.75 11.75C5.94891 11.75 6.13968 11.671 6.28033 11.5303C6.42098 11.3897 6.5 11.1989 6.5 11V8C6.5 7.80108 6.42098 7.61032 6.28033 7.46967C6.13968 7.32901 5.94891 7.25 5.75 7.25C5.55109 7.25 5.36032 7.32901 5.21967 7.46967C5.07902 7.61032 5 7.80108 5 8V11C5 11.1989 5.07902 11.3897 5.21967 11.5303C5.36032 11.671 5.55109 11.75 5.75 11.75ZM10.7803 11.5303C10.6397 11.671 10.4489 11.75 10.25 11.75C10.0511 11.75 9.86032 11.671 9.71967 11.5303C9.57902 11.3897 9.5 11.1989 9.5 11V8C9.5 7.80108 9.57902 7.61032 9.71967 7.46967C9.86032 7.32901 10.0511 7.25 10.25 7.25C10.4489 7.25 10.6397 7.32901 10.7803 7.46967C10.921 7.61032 11 7.80108 11 8V11C11 11.1989 10.921 11.3897 10.7803 11.5303Z"
        fill={fill}
      />
    </svg>
  );
}
Trash.propTypes = {
  fill: PropTypes.string
};

Trash.defaultProps = {
  fill: MUI_COLORS.MID_NIGHT
};
