import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';

export const getCompanyIncorporationRequestStart = () => ({
  type: actionTypes.GET_COMPANY_INCORPORATION_REQUEST_START
});
export const getCompanyIncorporationRequestFail = (error) => ({
  type: actionTypes.GET_COMPANY_INCORPORATION_REQUEST_FAIL,
  error: error
});
export const getCompanyIncorporationRequestSuccess = (getCompanyIncorporationResponse) => ({
  type: actionTypes.GET_COMPANY_INCORPORATION_REQUEST_SUCCESS,
  getCompanyIncorporationData: getCompanyIncorporationResponse
});
export const getCompanyIncorporation = (companyId) => {
  return async (dispatch) => {
    try {
      dispatch(getCompanyIncorporationRequestStart());
      let getCompanyIncorporationResponse = await Api.get(
        `${ENDPOINTS.GET_COMPANY_SET_UP_DETAILS}?companyId=${companyId}`
      );
      dispatch(getCompanyIncorporationRequestSuccess(getCompanyIncorporationResponse));
      return getCompanyIncorporationResponse;
    } catch (error) {
      dispatch(getCompanyIncorporationRequestFail(error.response));
    }
  };
};

export const companyIncorporationRequestStart = () => ({
  type: actionTypes.COMPANY_INCORPORATION_REQUEST_START
});
export const companyIncorporationRequestFail = (error) => ({
  type: actionTypes.COMPANY_INCORPORATION_REQUEST_FAIL,
  error: error
});
export const companyIncorporationRequestSuccess = (companyIncorporationResponse) => ({
  type: actionTypes.COMPANY_INCORPORATION_REQUEST_SUCCESS,
  companyIncorporationData: companyIncorporationResponse
});
export const updateCompanyIncorporation = (companyIncorporationPayload) => {
  return async (dispatch) => {
    try {
      dispatch(companyIncorporationRequestStart());
      let companyIncorporationResponse = await Api.put(
        ENDPOINTS?.GET_COMPANY_SET_UP_UPDATE,
        companyIncorporationPayload
      );
      dispatch(companyIncorporationRequestSuccess(companyIncorporationResponse));
      return companyIncorporationResponse;
    } catch (error) {
      dispatch(companyIncorporationRequestFail(error.response));
    }
  };
};

export const uploadedPassportRequestStart = () => ({
  type: actionTypes.UPLOAD_PASSPORT_REQUEST_START
});
export const uploadedPassportRequestFail = (error) => ({
  type: actionTypes.UPLOAD_PASSPORT_REQUEST_FAIL,
  error: error
});
export const uploadedPassportRequestSuccess = (uploadedPassportResponse) => ({
  type: actionTypes.UPLOAD_PASSPORT_REQUEST_SUCCESS,
  uploadedPassportData: uploadedPassportResponse
});
export const uploadedPassport = (uploadedPassportPayload) => {
  return async (dispatch) => {
    try {
      dispatch(uploadedPassportRequestStart());
      let uploadedPassportResponse = await Api.post(ENDPOINTS.PASSPORT_UPLOAD, uploadedPassportPayload);
      dispatch(uploadedPassportRequestSuccess(uploadedPassportResponse));
      return uploadedPassportResponse;
    } catch (error) {
      dispatch(uploadedPassportRequestFail(error.response));
    }
  };
};

export const switchCompanyRequestStart = () => ({ type: actionTypes.SWITCH_COMPANY_REQUEST_START });
export const switchCompanyRequestFail = (error) => ({ type: actionTypes.SWITCH_COMPANY_REQUEST_FAILURE, error: error });
export const switchCompanyRequestSuccess = (switchCompanyPayload) => ({
  type: actionTypes.SWITCH_COMPANY_REQUEST_SUCCESS,
  switchCompanyPayload: switchCompanyPayload
});
export const switchCompany = (switchCompanyPayload) => {
  return async (dispatch) => {
    try {
      dispatch(switchCompanyRequestStart());
      let switchCompanyResponse = await Api.post(ENDPOINTS.SWITCH_COMPANY, switchCompanyPayload);
      dispatch(switchCompanyRequestSuccess(switchCompanyResponse?.data));
      return switchCompanyResponse;
    } catch (error) {
      dispatch(switchCompanyRequestFail(error.response));
      return error?.response;
    }
  };
};

export const deleteStakholderRequestStart = () => ({ type: actionTypes.DELETE_STAKEHOLDER_REQUEST_START });
export const deleteStakholderRequestFail = (error) => ({
  type: actionTypes.DELETE_STAKEHOLDER_REQUEST_FAILURE,
  error: error
});
export const deleteStakholderRequestSuccess = (deleteStakholderPayload) => ({
  type: actionTypes.DELETE_STAKEHOLDER_REQUEST_SUCCESS,
  deleteStakholderData: deleteStakholderPayload
});
export const deleteStakholder = (deleteStakholderPayload) => {
  return async (dispatch) => {
    try {
      dispatch(deleteStakholderRequestStart());
      let deleteStakholderResponse = await Api.post(ENDPOINTS.DELETE_STAKEHOLDER, deleteStakholderPayload);
      dispatch(deleteStakholderRequestSuccess(deleteStakholderResponse?.data));
      return deleteStakholderResponse;
    } catch (error) {
      dispatch(deleteStakholderRequestFail(error.response));
      return error?.response;
    }
  };
};
