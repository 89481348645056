import React from 'react';
import BackButton from '../../ui/buttons/BackButton';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import XSText from '../../ui/typography/XSText';
import MultiTextInput from '../../ui/inputs/MultiTextInput';
import MSText from '../../ui/typography/MSText';
import { useForm } from 'react-hook-form';
import ValidationMessage from '../../ui/ValidationMessage';
import { COMPANY_SETUP_STEP, CONSTANT_NUMBER, REGEX, REGISTERED_COUNTRY } from '../../../constants/enum';
import { defaultValidationMessage } from '../../../helper/helpers';
import { useSelector, connect } from 'react-redux';
import * as CompanyAction from '../../../components/companyIncorporation/reduxStore/action';
import { API_CODE } from '../../../constants/enum';
import industries from '../../../constants/industries.json';

const Description = ({
  prevSubStep,
  nextDescriptionStep,
  prevStep,
  updateCompanyIncorporation,
  setSubStep,
  subStep,
  setStep
}) => {
  const { register, handleSubmit, errors } = useForm();
  const companyAllInformation = useSelector(
    (state) => state?.companyIncorporationReducer?.getCompanyIncorporation?.data?.data || {}
  );
  const onSubmit = async (data) => {
    if (data) {
      let payload = {
        businessDescription: {
          purpose: data?.purpose,
          reason: data?.reason
        },
        companyId: companyAllInformation?._id,
        completedStep: COMPANY_SETUP_STEP?.BUSINESS_DESCRIPTION
      };
      const response = await updateCompanyIncorporation(payload);
      if (response?.status === API_CODE?.STATUS_200) {
        nextDescriptionStep();
      }
    }
  };

  const back = () => {
    const getSubIndustries = industries.filter((industry) => industry?.name === companyAllInformation?.industries?.[0]);
    if (getSubIndustries.length === 0) {
      setSubStep(subStep - CONSTANT_NUMBER.TWO);
      setStep(COMPANY_SETUP_STEP?.COMPANY_NAME);
    } else {
      prevSubStep();
      prevStep();
    }
  };

  return (
    <>
      {companyAllInformation && (
        <>
          <div className="flex md:flex-row flex-col md:gap-12 gap-6">
            <form id="support-form" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <XSText title="What is the purpose of the company?" />
                <MultiTextInput
                  defaultValue={companyAllInformation?.businessDescription?.purpose}
                  label="Purpose"
                  name="purpose"
                  inputContainerClass="mt-2"
                  inputRef={register({
                    required: true,
                    pattern: {
                      value: REGEX.TAGS_NOT_ALLOWED
                    }
                  })}
                  error={errors?.purpose ? true : false}
                  helperText={errors?.purpose && <ValidationMessage title={defaultValidationMessage('purpose')} />}
                />
                <XSText
                  title={`Why do you want to conduct business through a ${
                    companyAllInformation?.registrationCountry === REGISTERED_COUNTRY.HONGKONG
                      ? 'Hong Kong'
                      : 'Singapore'
                  } company?`}
                  className="mt-4"
                />
                <MultiTextInput
                  defaultValue={companyAllInformation?.businessDescription?.reason}
                  label="Reason"
                  name="reason"
                  inputContainerClass="mt-2"
                  inputRef={register({
                    required: true,
                    pattern: {
                      value: REGEX.TAGS_NOT_ALLOWED
                    }
                  })}
                  error={errors?.reason ? true : false}
                  helperText={errors?.reason && <ValidationMessage title={defaultValidationMessage('reason')} />}
                />
              </div>
            </form>
            <div className="flex flex-col gap-4 business-details-text-container">
              <MSText title="Things to know" fontWeight="text-bold" />
              <MSText
                title={`Most business activities are allowed in ${
                  companyAllInformation?.registrationCountry === REGISTERED_COUNTRY.HONGKONG ? 'Hong Kong' : 'Singapore'
                }. However, certain restrictions do apply. Answering our two questions about your company's business will help us identify if it falls under these restrictions.`}
              />
              <MSText title="Please provide detailed information for us to accurately understand your business." />
            </div>
          </div>

          <div className="flex md:flex-row flex-col-reverse justify-between">
            <BackButton onClick={back} className="md:w-[114px] w-full" />
            <PrimaryButton
              caption="Continue"
              color="text-white"
              className="w-full"
              isBorderRequired={true}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCompanyIncorporation: (payload) => dispatch(CompanyAction.updateCompanyIncorporation(payload))
  };
};

export default connect(null, mapDispatchToProps)(Description);
