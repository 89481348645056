// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

export const INVOICE_LIST = '/getpaid';
const ROOTS_DASHBOARD = '/dashboard';
export const INVOICE_DASHBOARD = '/dashboard';
export const CONTACT = '/contact';
export const IMPORTING_INVOICE = '/importing-invoice';
export const PAY = '/pay';
export const COMPANY = '/company';

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  dashboard: INVOICE_DASHBOARD,
  invoiceList: INVOICE_LIST,
  createInvoice: path(INVOICE_LIST, '/create'),
  viewInvoice: path(INVOICE_LIST, '/view'),
  editInvoice: path(INVOICE_LIST, '/edit'),
  accountSettings: '/account-settings',
  profile: '/profile',
  contact: path(CONTACT, '/list'),
  viewContact: path(CONTACT, `/view`),
  publicInvoice: '/public',
  login: '/login',
  createImportingInvoice: path(IMPORTING_INVOICE, '/create'),
  editImportingInvoice: path(IMPORTING_INVOICE, '/edit'),
  signUp: '/signup',
  verifyEmail: '/verify-email',
  confirmEmailAccount: '/confirm-email-account',
  tooManyAttempts: '/too-many-attempts',
  resetPassword: '/reset-password',
  viewIncomingInvoices: '/view-incoming-invoices',
  incomingPublicInvoice: '/view-incoming-invoices-public',
  pay: PAY,
  invoiceGenerator: '/invoice-generator',
  company: COMPANY,
  companyProfile: path(COMPANY, '/profile'),
  companyOrganization: path(COMPANY, '/organization'),
  companyMailRoom: path(COMPANY, '/mailroom'),
  companyDocuments: path(COMPANY, '/documents'),
  boSignUp: 'boInvoice/signUp',
  publicCompanyDocs: '/public-company-docs',
  companyIncorporation: '/company-set-up',
  companyIncorporationSuccess: '/company-incorporation-success',
  companySignUp: '/company-signup',
  companyEmailVerify: '/company-verify-email',
  companyProfileSetting: path(COMPANY, '/setting-profile'),
  companiesList: '/company-list',
  hkIncorporation: 'hk-incorporation'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`)
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`)
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt')
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
