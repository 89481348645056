import React, { useState } from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import Description from './Description';
import Countries from './Countries';
import { COMPANY_DESCRIPTION_STEP } from '../../../constants/enum';
import TurnOver from './TurnOver';
import '../../../styles/pages/company-set-up/common.scss';

const BusinessDescription = ({
  prevSubStep,
  prevStep,
  nextStep,
  step,
  setActiveStep,
  setSubStep,
  subStep,
  setStep
}) => {
  const [companyDescriptionStep, setCompanyDescriptionStep] = useState(1);
  let nextDescriptionStep = () => {
    setCompanyDescriptionStep(companyDescriptionStep + 1);
  };

  let prevDescriptionStep = () => {
    setCompanyDescriptionStep(companyDescriptionStep - 1 > 0 ? companyDescriptionStep - 1 : 1);
  };
  let selectedCase = () => {
    switch (companyDescriptionStep) {
      default:
        return <h1>something wrong</h1>;
      case COMPANY_DESCRIPTION_STEP?.DESCRIPTION:
        return (
          <Description
            prevSubStep={prevSubStep}
            nextDescriptionStep={nextDescriptionStep}
            setSubStep={setSubStep}
            prevStep={prevStep}
            subStep={subStep}
            setStep={setStep}
          />
        );
      case COMPANY_DESCRIPTION_STEP?.COUNTRIES:
        return <Countries prevDescriptionStep={prevDescriptionStep} nextDescriptionStep={nextDescriptionStep} />;
      case COMPANY_DESCRIPTION_STEP?.TURNOVER:
        return (
          <TurnOver
            prevDescriptionStep={prevDescriptionStep}
            nextStep={nextStep}
            setActiveStep={setActiveStep}
            setSubStep={setSubStep}
            step={step}
          />
        );
    }
  };

  return (
    <div className="flex flex-col justify-between md:gap-8 gap-6 w-full h-full main-wrapper-container">
      <MDSubtitleText title="Business description" fontWeight="text-bold" />
      {selectedCase()}
    </div>
  );
};

export default BusinessDescription;
